import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// Pages
import AuthenticatedRootPage from '@pages/AuthenticatedRootPage.page';
import UnauthenticatedRootPage from '@pages/UnauthenticatedRootPage.page';
import ErrorPage from '@pages/Error.page';
import ApiConnectionErrorPage from '@pages/ApiConnectionErrorPage.page';
import Welcome from '@pages/Welcome.page';
import CreateNewAccount from '@pages/CreateNewAccount.page';
import AfterLogin from '@pages/AfterLogin.page';
import SourceConfigPage from '@pages/SourceConfig.page';
import AccountSignup from '@pages/AccountSignup.page';
import NoNavWrapperPage from '@pages/Onboarding/OnboardingWrapper.page';
import DatabaseSetupPage from '@pages/Onboarding/DatabaseSetup.page';
import NewSourcePage from '@pages/NewSourcePage.page';

import StartTemplatePage from '@pages/Template/StartTemplate.page';
import ViewTemplateGroupPage from '@pages/Template/ViewTemplateGroup.page';
import FivetranSourceStepReturnPage from '@pages/Template/steps/FivetranSourceStepReturn.page';

import SourceFinishFivetranPage from '@pages/SourceFinishFivetran.page';

import PipelineNodeDataPage from '@pages/PipelineNode/PipelineNodeData';
import PipelineNodeRelationshipPage from '@pages/PipelineNode/PipelineNodeRelationships.page';
import PipelineNodesListPage from '@pages/PipelineNode/PipelineNodesList.page';
import TenantReturn from '@pages/TenantReturn.page';
import TenantBuildSchedulerPage from '@pages/TenantConfig/TenantBuildScheduler.page';
import DataAssistantPage from '@pages/Assistant.page';

import NewPipelineNodePage from '@pages/PipelineNode/NewPipelineNode.page';
import GithubIntegrationPage from '@pages/Onboarding/GithubIntegration.page';
import PipelineNodeConfigurationPage from '@pages/PipelineNode/PipelineNodeConfiguration.page';
import SeedPage from '@pages/Seeds/Seed.page';
import BuildHistoryPage from '@pages/BuildHistory.page';
import DagPage from '@pages/Dag.page';
import PipelineNodeVisualizationsPage from '@pages/PipelineNode/PipelineNodeVisualizations.page';
import PipelineNodeVisualizationPage from '@pages/PipelineNode/PipelineNodeVisualization.page';
import OnboardingWizardPage from '@pages/Onboarding/OnboardingWizard.page';
import TenantAuthorizeSourcesPage from '@pages/TenantConfig/TenantAuthorizeSources.page';
import DashboardEditorPage from '@pages/Dashboard/DashboardEditor.page';
import HomePage from '@pages/Home.page';
import PipelineNodeRecordPage from '@pages/PipelineNode/PipelineNodeRecord.page';
import PipelineNodeFilesPage from '@pages/PipelineNode/PipelineNodeFiles.page';
import TenantListSourcesPage from '@pages/TenantConfig/TenantListSources.page';
import EjectTemplatePage from '@pages/EjectTemplate.page';
import PipelineNodeWrapper from '@pages/PipelineNode/PipelineNodeWrapper.page';
import DataModelBusinessObjectsPage from '@pages/DataModel/DataModelBusinessObjects.page';
import DataModelSourcesPage from '@pages/DataModel/DataModelSources.page';
import DataModelReportingPage from '@pages/DataModel/DataModelReporting.page';
import DataModelSemanticLayer from '@pages/DataModel/DataModelSemanticLayer.page';
import DatabasePage from '@pages/Database/Database.page';


const createRoutes = () => {
    return (
    <Route path="" errorElement={<ErrorPage/>}>
        <Route element={<AuthenticatedRootPage/>}>
            <Route index element={<HomePage/>}/>
            <Route path="dag" element={<DagPage/>}/>
            <Route path="welcome" element={<Welcome/>}/>
            <Route path="create-account" element={<CreateNewAccount />}/>   
            <Route path="unable-to-connect" element={<ApiConnectionErrorPage />}/>
            <Route path="afterlogin" element={<AfterLogin />}/>  
            <Route path="tenant-return/:tenantUuid" element={<TenantReturn />}/> 
            <Route path="account" element={<TenantBuildSchedulerPage />}/> 
            <Route path="account/build-schedule" element={<TenantBuildSchedulerPage />}/> 
            <Route path="account/authorize-sources" element={<TenantAuthorizeSourcesPage />}/> 
            <Route path="account/sources" element={<TenantListSourcesPage />}/> 
            <Route path="model/business-objects" element={<DataModelBusinessObjectsPage/>}/>
            <Route path="model/sources" element={<DataModelSourcesPage/>}/>
            <Route path="model/reporting" element={<DataModelReportingPage/>}/>
            <Route path="model/dag" element={<DagPage/>}/>
            <Route path="model/semantic-layer" element={<DataModelSemanticLayer/>}/>
            <Route path="db" element={<DatabasePage/>}/>
            <Route path="source/new" element={<NewSourcePage/>}></Route>
            <Route path="source/:sourceId" element={<SourceConfigPage/>}/>
            <Route path="source/:sourceId/finish-fivetran" element={<SourceFinishFivetranPage/>}/>

            <Route path="dashboard/:dashboardId" element={<DashboardEditorPage/>}/>

            <Route path="onboarding" element={<NoNavWrapperPage/>}>
                <Route index element={<OnboardingWizardPage/>}/>
                <Route path="step/:step" element={<OnboardingWizardPage/>}/>
                <Route path="database" element={<DatabaseSetupPage/>}/>
                <Route path="github" element={<GithubIntegrationPage/>}/>
            </Route>

            {/* <Route path="database" element={<DataExplorerPage/>}/> */}
            <Route path="nodes" element={<PipelineNodesListPage/>}/>
            <Route path="node/new" element={<NewPipelineNodePage/>}/>
            <Route path="node/:pipelineNodeId" element={<PipelineNodeDataPage/>}/>

            <Route path="node/:pipelineNodeId/config" element={<PipelineNodeConfigurationPage/>}/>
            <Route path="node/:pipelineNodeId/relationships" element={<PipelineNodeRelationshipPage/>}/>
            <Route path="node/:pipelineNodeId/relationships/:relationshipId" element={<PipelineNodeRelationshipPage/>}/>
            <Route path="node/:pipelineNodeId/data/:recordUuid" element={<PipelineNodeRecordPage/>}/>
            <Route path="node/:pipelineNodeId/visualizations/:visualizationId" element={<PipelineNodeVisualizationPage/>}/>
            <Route path="node/:pipelineNodeId/visualizations" element={<PipelineNodeVisualizationsPage/>}/>
            <Route path="node/:pipelineNodeId/files" element={<PipelineNodeFilesPage/>}/>

            
            <Route path="advanced">
                <Route path="new" element={<NewSourcePage/>} />
            </Route>
            <Route path="seeds/:seedId" element={<SeedPage/>}/>
            <Route path="eject" element={<EjectTemplatePage/>} />

            <Route path="template/:templateId" element={<StartTemplatePage />}/>
            <Route path="template-group/:templateGroupId" element={<ViewTemplateGroupPage />}/>
            <Route path="template-group/:templateGroupId/source/:sourceId/finish-fivetran" element={<FivetranSourceStepReturnPage />}/>
            <Route path="data-assistant" element={<DataAssistantPage/>}/>
            <Route path="builds" element={<BuildHistoryPage/>}/>
        </Route>
        <Route element={<UnauthenticatedRootPage/>}>
            <Route path="signup" element={<AccountSignup/>}/>    
            <Route path="sign-up-thank-you" element={<AccountSignup/>}/>           
        </Route>
        
    </Route>
    );
}

export default createRoutes;