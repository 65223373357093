import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import { DashboardFilterOption } from "@models/dashboard";
import { Filter, PipelineNodeField } from "@models/pipelineNode";
import { comparatorOptions } from "@pages/SourceRecordType/FilterConfigForm.component";
import { useAvailableSnowflakeTables, useColumnValueDistribution, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Updater } from "use-immer";

interface Props {
    option: DashboardFilterOption;
    onChange: (newData: DashboardFilterOption) => any;
    columnOptions: Option[];
}

const DashboardFilterOptionForm = (props: Props) => {
    const changeValue = useCallback((key: keyof DashboardFilterOption, val: any) => {
        props.onChange({
            ...props.option,
            [key]: val, 
        })
    }, [props.onChange, props.option]);

    return <>
        <Form.Group className="mb-2">
            <Form.Label>
                Filter Columns
            </Form.Label>
            <MultiDropdown
                options={props.columnOptions}
                selected={props.option.column_references.map(cr => {
                    return `${cr.pipeline_node_id}.${cr.field_id}`
                })}
                onChange={(newVals: string[]) => {
                    changeValue('column_references', newVals.map(nv => {
                        const spl = nv.split('.');
                        return {
                            pipeline_node_id: spl[0],
                            field_id: spl[1],
                        }
                    }));
                }}
            />
        </Form.Group>
        <Form.Group className="mb-2">
            <Form.Label>
                Filter Type
            </Form.Label>
            <Dropdown
                options={
                    [
                        {
                            value: 'FREE_TEXT',
                            label: 'Basic Filter'
                        }, {
                            value: 'QUERY_LIST',
                            label: 'Dynamic list of options',
                        }
                    ]
                }
                selected={props.option.filter_type}
                onChange={(newVal: string) => {
                    changeValue('filter_type', newVal);
                }}
            />
        </Form.Group>
        {props.option.filter_type === 'FREE_TEXT' && <>
            <Form.Group className="mb-2">
                <Form.Label>
                    Comparator
                </Form.Label>
                <Dropdown options={comparatorOptions}
                    selected={props.option.comparator}
                    onChange={(newVal: string) => {
                        changeValue('comparator', newVal);
                    }}
                />

            </Form.Group>
        </>}
        {['STATIC_LIST', 'QUERY_LIST'].includes(props.option.filter_type) &&
             <Form.Group className="mb-2">
                <Form.Check
                    checked={props.option.allow_multiple}
                    onChange={() => {
                        changeValue('allow_multiple', !props.option.allow_multiple)
                    }}
                    label="Allow user to select multiple options from the list"
                />
            </Form.Group>
        }

        {props.option.filter_type === 'QUERY_LIST' && <>
            <Form.Group className="mb-2">
                <Form.Label>Select column for dynamic list</Form.Label>
                <Dropdown
                    options={props.columnOptions}
                    selected={`${props.option.query_list_node_id}.${props.option.query_list_field_id}`}
                    onChange={(newVals: string) => {
                        const spl = newVals.split('.');
                        props.onChange({
                            ...props.option,
                            query_list_node_id: spl[0],
                            query_list_field_id: spl[1],
                        })
                        
                    }}
                />
            </Form.Group>
            <Form.Group className="mb-2">
            <Form.Label>
                Default Value
            </Form.Label>
            <Dropdown
                options={
                    [
                        {
                            value: 'TODAYS_DATE',
                            label: 'Today\'s Date'
                        }, {
                            value: 'YESTERDAYS_DATE',
                            label: 'Yesterday\'s Date'
                        }
                    ]
                }
                selected={props.option.default_value}
                onChange={(newVal: string) => {
                    changeValue('default_value', newVal);
                }}
            />
        </Form.Group>
        </>}        
    </>
}

interface NodeFieldsById {
    [nodeId: string]: {
        [fieldId: string]: PipelineNodeField;
    }
}

export default DashboardFilterOptionForm;

export interface FiltersByNodeId {
    [nodeId: string]: Filter[]
}

interface FilterFormProps {
    filter_option: DashboardFilterOption;
    onChange: (filtersByNodeId: FiltersByNodeId) => any;
}

const QueryListFilterForm = (props: FilterFormProps) => {
    const pipelineNodes = usePipelineNodes();

    const [value, setValue] = useState<string[]>([]);
    const availableValues = useColumnValueDistribution(props.filter_option.query_list_node_id || '', props.filter_option.query_list_field_id || '');
    
    useEffect(() => {
        if (!pipelineNodes.data) {
            return;
        }
        
        if (props.filter_option.default_value) {
            switch (props.filter_option.default_value) {
                case 'TODAYS_DATE':
                    const todaysDate = moment().format('YYYY-MM-DD');
                    setValue([todaysDate]);
                    handleChange([todaysDate]);
                    break;
                case 'YESTERDAYS_DATE':
                    const yesterdaysDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
                    setValue([yesterdaysDate]);
                    handleChange([yesterdaysDate]);
                    break;
            }
        }
    },[props.filter_option.default_value, pipelineNodes.dataUpdatedAt])

    const filterOptions = useMemo(() => {
        if (!availableValues.data) {
            return [];
        }

        return availableValues.data
            .filter(v => !!v.value)
            .map(v => ({
                value: v.value,
                label: v.value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [availableValues.dataUpdatedAt]);

    const nodeFieldsById: NodeFieldsById = useMemo(() => {
        if (!pipelineNodes.data) {
            return {};
        }
        const nodeFieldsById: NodeFieldsById = {};

        pipelineNodes.data.forEach(pn => {
            nodeFieldsById[pn.id as string] = {};
            pn.fields.forEach(f => {
                nodeFieldsById[pn.id as string][f.id] = f;
            });
        });
        return nodeFieldsById
    }, [pipelineNodes.dataUpdatedAt]);


    const handleChange = useCallback((newVals: string[]) =>  {
        
        setValue(newVals);
        if (!pipelineNodes.data) {
            return;
        }
        const filtersByNodeId: FiltersByNodeId = {};
        props.filter_option.column_references.forEach(cr => {
            if (!(cr.pipeline_node_id in filtersByNodeId)) {
                filtersByNodeId[cr.pipeline_node_id] = [];
            }

            try {
                const field = nodeFieldsById[cr.pipeline_node_id][cr.field_id];

                filtersByNodeId[cr.pipeline_node_id].push({
                    comparator: 'IN',
                    value: newVals,
                    column_ref: {
                        column_path: field.name,
                    }
                });
            } catch (err) {
                console.error(err);
            }
            
        });

        props.onChange(filtersByNodeId);

    }, [props.onChange, pipelineNodes.dataUpdatedAt]);

    if (props.filter_option.allow_multiple) {
        return <>
            <MultiDropdown
                selected={value}
                onChange={handleChange}
                options={filterOptions}
            />
        </>
    }
    return <>
        <Dropdown
            selected={value.length > 0 ? value[0] : ''}
            options={filterOptions}
            onChange={(newVal: string) => {
                handleChange([newVal])
            }}
        />
    </>
}

export const FilterForm = (props: FilterFormProps) => {
    if (props.filter_option.filter_type === 'QUERY_LIST'){
        return <QueryListFilterForm {...props} />
    }
    return <></>;
}