import LoadingCard from "@components/card/LoadingCard.component";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent } from "@pages/PageStructure.component";
import { useAvailableSnowflakeTables } from "@stores/data.store";
import { PropsWithChildren, ReactNode, useCallback, useMemo, useState, KeyboardEvent, useRef } from "react";
import { Allotment } from "allotment";
import { Badge, Collapse } from "react-bootstrap";
import styled from 'styled-components';
import { useQuery } from "@services/url.service";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import { useNavigate } from "react-router-dom";
import { summarizeNumber } from "@services/formatting.service";
import { PipelineNodeORM } from "@models/pipelineNode";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import AsyncButton from "@components/button/AsyncButton.component";

interface Table {
    type: 'TABLE' | 'VIEW';
    name: string;
    totalRecords: number;
}

interface Schema {
    name: string;
    tables: Table[];
}
interface Database {
    name: string;
    schemas: Schema[];
}

interface TreeNodeProps {
    name: string;
    active?: boolean;
    onClick?: () => void;
}

const TreeNodeStyles = styled.div`
div.inner {
    padding-left: 2rem;
}

.toggle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
    &:hover {
        background-color: var(--ct-light);
        cursor:pointer;
    }

    &.active {
        font-weight: bold;
    }
}
`
const TreeNode = ({children, name, onClick, active}: PropsWithChildren<TreeNodeProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    

    const clicked = useCallback(() => {
        if (!children) {
            return onClick && onClick();
        }
        setIsOpen(!isOpen);
    }, [isOpen, children]);

    const icon = useMemo(() => {
        if (!children) {
            return '';
        }
        if (isOpen) {
            return 'mdi mdi-menu-down';
        }
        return 'mdi mdi-menu-down mdi-rotate-270';
    }, [isOpen, children])
    return <TreeNodeStyles>
        <div className={`toggle ${active ? 'active': ''}`} onClick={clicked} title={name}>
            {icon && <i className={icon}/>} {name}
        </div>
        <Collapse in={isOpen}>
            <div className="inner">{children}</div>
        </Collapse>
        
        
    </TreeNodeStyles>
}

const DatabasePage = () => {
    const tables = useAvailableSnowflakeTables();
    const query = useQuery();

    const searchQuery = query.get('q') || '';
    

    const searchInput = useRef<HTMLInputElement>(null);
    const tableName = query.get('table');
    const selectedDb = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[0];
        }
        return '';
    }, [tableName]);

    const selectedSchema = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[1];
        }
        return '';
    }, [tableName]);

    const selectedTable = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[2];
        }
        return '';
    }, [tableName]);


    const hierarchicallyOrganizedTables: Database[] = useMemo(() => {
        if (!tables.data) {
            return [];
        }

        const databasesByName: { [key: string]: Database } = {};
        const schemasByName: { [key: string]: Schema } = {};

        tables.data.forEach((table) => {
            if (!databasesByName[table.database_name]) {
                databasesByName[table.database_name] = {
                    name: table.database_name,
                    schemas: []
                };
            }
            const db = databasesByName[table.database_name];
            const fullSchemaName = `${table.database_name}.${table.schema_name}`;
            if (!schemasByName[fullSchemaName]) {
                schemasByName[fullSchemaName] = {
                    name: table.schema_name,
                    tables: []
                };
                db.schemas.push(schemasByName[fullSchemaName]);
            }
            const schema = schemasByName[fullSchemaName];
            schema.tables.push({
                type: table.rows == undefined ? 'VIEW' : 'TABLE',
                name: table.name,
                totalRecords: table.rows || 0,
            });
        });
        
        // Now sort everything
        Object.values(databasesByName).forEach((db) => {
            db.schemas.sort((a, b) => a.name.localeCompare(b.name));
            db.schemas.forEach((schema) => {
                schema.tables.sort((a, b) => a.name.localeCompare(b.name));
            });
        });
        return Object.values(databasesByName).sort((a, b) => a.name.localeCompare(b.name));

    }, [tables.dataUpdatedAt]);

    const navigate = useNavigate();
    const showTable = useCallback((database: string, schema: string, table: string) => {
        navigate(`?table=${database}.${schema}.${table}`);
    }, []);

    const selectedTableInfo = useMemo(() => {
        if (!tableName) {
            return null;
        }
        const db = hierarchicallyOrganizedTables.find((db) => db.name == selectedDb);
        if (!db) {
            return null;
        }
        const schema = db.schemas.find((schema) => schema.name == selectedSchema);
        if (!schema) {
            return null;
        }
        return schema.tables.find((table) => table.name == selectedTable);
    }, [selectedDb, selectedSchema, selectedTable, hierarchicallyOrganizedTables]);

    const checkForSearchEnterKey = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode == 13) {
            const searchQuery = searchInput.current?.value || '';
            navigate(`?q=${searchQuery}&table=${tableName}`);
        }
    }, [searchInput, tableName]);

    const [loading, setLoading] = useState(false);

    const useTable = useCallback(async () => {
        if (!tableName) {
            return;
        }
        setLoading(true);
        try {
            const result = await PipelineNodeORM.save({
                id: null,
                node_type: 'SOURCE',
                table_name: tableName,
                name: selectedTable,
                fields: [],
                description: '',
                upstream_node_ids: [],
            });
            navigate(`/node/${result.id}/config`);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
        }
    }, [selectedTable, tableName]);


    return <PageStructure>
        <Allotment defaultSizes={[100, 500]}>
            <Allotment.Pane maxSize={500} >
            <Pane>
                <PaneContent className="bg-white">
                    <div className="p-3">
                        <h4>Database Structure</h4>
                        {tables.isLoading && <LoadingCard/>}
                        {!tables.isLoading && <div>
                            {hierarchicallyOrganizedTables.map((db) => {
                                return <TreeNode name={db.name} active={selectedDb == db.name}>
                                    {db.schemas.map((schema) => {
                                        return <TreeNode name={schema.name} active={selectedDb == db.name && selectedSchema == schema.name}>
                                            {schema.tables.map((table) => {
                                                return <TreeNode active={selectedDb == db.name && selectedSchema == schema.name && selectedTable == table.name} name={table.name} onClick={() => {
                                                    showTable(db.name, schema.name, table.name);
                                                }}/>
                                            })}
                                        </TreeNode>
                                    })}
                                </TreeNode>

                            })}
                        </div>
                        }
                    </div>
                </PaneContent>
            </Pane>
            
            </Allotment.Pane>
            <Allotment.Pane>
                <Pane>
                    <PaneContent>
                        <PageContentHeader>
                            {tableName && <>
                            <div className="d-flex center-vertically mb-2">
                                <div className="flex-1">
                                    <>
                                        <h1 className="mb-0">{tableName}</h1>
                                        <div className="mb-1 font-poppins text-muted font-13">
                                            <span>
                                                <Badge>{selectedTableInfo?.type}</Badge>
                                            </span>
                                            <span className="me-3">&nbsp;</span>
                                            <span>
                                                <i className="mdi mdi-file-multiple"></i> Total Records: {selectedTableInfo ? summarizeNumber(selectedTableInfo.totalRecords) : '0'}
                                            </span>
                                        </div>
                                    </>
                                </div>
                                <div>
                                    <AsyncButton
                                        onClick={useTable}
                                        loading={loading}
                                        text="Use Table"
                                        className="btn-lg btn-primary"
                                    />
                                </div>
                                
                            </div>
                            <div>
                                     <input type="text" className=" form-control d-inline-block input-rounded mb-2" placeholder="Search (free-text or lucene)" defaultValue={searchQuery} ref={searchInput} onKeyDown={checkForSearchEnterKey} style={{width: '500px'}}/>

                                </div>
                            <nav className="subnav"></nav>
                        </>}
                        {!tableName && <h1>Please select a table</h1>}
                        </PageContentHeader>
                        
                        <PageContentInner hasHeader noScroll className="bg-white">
                        {tableName && <TableExplorer
                                    tablePath={`/db/data?table=${tableName}`}
                                    searchQuery={searchQuery}
                                />}
                            
                        </PageContentInner>
                    </PaneContent>
                </Pane>
            </Allotment.Pane>
    </Allotment>
    </PageStructure>
}

export default DatabasePage; 