import AsyncButton from "@components/button/AsyncButton.component";
import FeatureFlagWrapper from "@components/featureflags/FeatureFlagWrapper.component";
import Dropdown from "@components/form/Dropdown.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeMultiSelector from "@components/pipelineNodes/PipelineNodeMultiSelector.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { DraftModeRequired } from "@components/project/DraftModeRequired.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import { useEntitlements } from "@frontegg/react";
import { PipelineNode, PipelineNodeField, PipelineNodeORM } from "@models/pipelineNode";
import { useSources } from "@stores/data.store";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component"
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import DataSourceSelectModal from "@pages/Sources/DataSourceSelectModal.component";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { shortid } from "@services/id.service";
import { NODE_TYPE_GROUPS, isValidUpstreamNode } from "@services/modeling.service";
import { useRouteBlocker } from "@services/routing.service";
import { useTenantRegistration } from "@services/tenant/tenant.service";
import toast from "@services/toast.service";
import { useQuery } from "@services/url.service";
import { invalidateMissionControlDataFlowData, invalidatePipelineNodes, useAvailableSnowflakeTables, useIsInDraftMode } from "@stores/data.store";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { useImmer } from "use-immer";


interface NodeFieldListProps {
    nodeFields: PipelineNodeField[];
    onChange: (newFields: PipelineNodeField[]) => any;
}

const NodeFieldList = (props: NodeFieldListProps) => {
    const addField = useCallback(() => {
        const newFields = produce(props.nodeFields, draft => {
            draft.unshift({
                id: shortid(),
                name: '',
                description: '',
                part_of_composite_key: false,
                label: '',
                type: 'STRING',
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            })
        });
        props.onChange(newFields);
    }, [props.nodeFields, props.onChange]);

    const changeFieldName = useCallback((fieldId: string, newName: string) => {
        const newFields = produce(props.nodeFields, draft => {
            const theField = draft.find(f => f.id === fieldId);
            if (theField) {
                theField.name = newName;
            }
        });

        props.onChange(newFields);
    }, [props.nodeFields, props.onChange]);

    const removeField = useCallback((fieldId: string) => {
        const newFields = produce(props.nodeFields, draft => {
            const idx = draft.findIndex(f => f.id === fieldId);
            if (idx >= 0) {
                draft.splice(idx, 1);
            }
        });

        props.onChange(newFields);
    }, [props.nodeFields, props.onChange]);

    return <div className="list-group">
        <button className="list-group-item list-group-item-action" onClick={addField}>
            <i className="mdi mdi-plus-thick"></i> Add Column
        </button>
        {props.nodeFields.map((f, idx) => 
            <div key={idx} className="list-group-item">
                <div className="d-flex center-vertically">
                    <div className="flex-1">
                        <input type="text" className="form-control w-100" placeholder="new_column" value={f.name} onChange={(e) => changeFieldName(f.id, e.target.value)}/>
                    </div>
                    <button className="icon-button ms-2" onClick={() => removeField(f.id)}>
                        <i className="mdi mdi-close-thick"></i>
                    </button>
                </div>
            </div>
        )}

    </div>
}

const Grid = styled.div`
display: grid;
gap: 20px;
grid-template-columns: repeat(2, 1fr);
`

interface SnowflakeTableSelectionProps {
    currentSelection: string;
    onSelect: (newSelection: string) => any;

}
const SnowflakeTableSelection = (props: SnowflakeTableSelectionProps) => {
    const availableSnowflakeTables = useAvailableSnowflakeTables();
    const availableTableOptions = useMemo(() => {
        if (!availableSnowflakeTables.data) {
            return [];
        }

        return availableSnowflakeTables.data.map(r => {
            return {
                value: `"${r.database_name}"."${r.schema_name}"."${r.name}"`,
                label: r.name,
                description: `Schema: ${r.database_name}.${r.schema_name}`,
                badgeText: r.rows == undefined ? 'VIEW' : (summarizeNumber(r.rows) + ' rows'),
            }
        });
    }, [availableSnowflakeTables.dataUpdatedAt]);

    const loadingAvailableTables = useMemo(() => {
        return availableSnowflakeTables.isFetching || availableSnowflakeTables.isRefetching || availableSnowflakeTables.isLoading;
    }, [availableSnowflakeTables.isFetching, availableSnowflakeTables.isRefetching, availableSnowflakeTables.isLoading])
    
    return <div className="d-flex center-vertically">
        <Dropdown
            className="flex-1"
            options={availableTableOptions}
            selected={props.currentSelection}
            onChange={props.onSelect}
        />
        <button className="icon-button ms-1" disabled={loadingAvailableTables} onClick={() => availableSnowflakeTables.refetch()}>
            {loadingAvailableTables && (
                <i className="mdi mdi-loading mdi-spin"></i>
            )}
            {!loadingAvailableTables && (
            <i className="mdi mdi-refresh"></i>
            )}
        </button>
    </div>
}

interface NodeTypeComponentProps {
    title: string;
    description: string;
    icon?: string;
    selected?: boolean;
    onClick: () => any;
}

const NodeTypeComponentStyles = styled.div`
.shadow-box:hover {
    outline: solid 2px var(--ct-info-bg);
    cursor: pointer;
}

.shadow-box {

    height: 100%;

    display: flex;
    align-items: center;
    div.text {
        flex: 1;

        p {
            margin-bottom: 0px;
        }
    }


    div.icon {
        font-size: 72px;
        color: #ccc;
        padding-right: 20px;
    }

    &.selected .icon {
        color: var(--pliable-yellow);
    }
}

.shadow-box.selected {
    outline: solid 2px var(--pliable-blue);
}
`

const NodeTypeComponent = (props: NodeTypeComponentProps) => {
    return <NodeTypeComponentStyles onClick={props.onClick}>
        
        <div className={`shadow-box p-3 ${props.selected ? 'selected' : ''}`}>
            <div className="icon">
                <i className={props.icon}/>
            </div>
            <div className="text">
                <h4>{props.title}</h4>
                <p>{props.description}</p>
                {props.selected}
            </div>
            
            
        </div>
    </NodeTypeComponentStyles>
}

const nodeTypeOptions = [
    {
        value: 'SOURCE',
        label: 'Source Table',
        description: 'Pull in raw data from any Snowflake table'
    },
    {
        value: 'FILE',
        label: 'Source File',
        description: 'Upload one or more Excel-compatible files'
    },
    {
        value: 'SUMMARY',
        label: 'Summary',
        description: 'Summarize (aggregate) data from a single upstream node',
    }, {
        value: 'MERGE',
        label: 'Merge',
        description: 'Merge data from multiple upstream nodes using a shared key'
    }, {
        value: 'STACK',
        label: 'Stack',
        description: 'Combine data from multiple upstream nodes without merging',
    }, 
    
    {
        value: 'DATAMART',
        label: 'Data Mart',
        description: 'Slice, dice, and summarize data from many related nodes'
    }, {
        value: 'METRIC',
        label: 'Metric',
        description: 'Calculate a metric from values in a single upstream node'
    }, {
        value: 'CUSTOM',
        label: 'Custom SQL',
        description: 'Write your own DBT-compatible SQL'
    }
]
const NewPipelineNodePage = () => {
    const [selectedNodeType, setSelectedNodeType] = useState('');
    const [combineBehavior, setCombineBehavior] = useState<string>('MERGE');

    const searchParams = useQuery();
    const { isEntitled: pivotTablesEnabled } = useEntitlements({
        featureKey: 'pivot_tables'
    })
    const [sourcesOnly, setSourcesOnly] = useState(false);

    useEffect(() => {
        const nodeType = searchParams.get('selectedNodeType');
        if (nodeType) {
            setSelectedNodeType(nodeType);
        }
    }, [searchParams]);


    const [fields, setFields] = useState<PipelineNodeField[]>([]);

    const selectNodeType = useCallback((nodeType: string) => {
        setSelectedNodeType(nodeType);

        if (['MERGE', 'STACK'].includes(nodeType)) {
            setCombineBehavior('MERGE');
        } else if (['SUMMARIZE', 'DATAMART'].includes(nodeType)) {
            setCombineBehavior('AGGREGATE');
        }

        if (nodeType == 'DATE_DIMENSION') {
            setSpecialNodeArgs(draft => {
                draft.fiscal_year_month = '01';
                draft.fiscal_year_day = '1';
            })
        }
        setFields([]);
    }, [setSelectedNodeType, setCombineBehavior]);
    
    const [selectedTable, setSelectedTable] = useState('');
    const [sourceId, setSourceId] = useState('');


    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [saving, setSaving] = useState(false);

    const [upstreamIds, setUpstreamIds] = useState<string[]>([]);


    const [specialNodeArgs, setSpecialNodeArgs] = useImmer<{
        [key: string]: any
    }>({});

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.selectedNodeType) {
            setSelectedNodeType(location.state.selectedNodeType);
        }
    }, [location.state]);

    const onSave = useCallback(async () => {
        setSaving(true);

        try {
            const node = await PipelineNodeORM.save({
                id: null,
                name: name,
                description: description,
                table_name: selectedTable,
                node_type: selectedNodeType,
                fields: fields,
                upstream_node_ids: upstreamIds,
                combine_behavior: combineBehavior,
                special_node_args: specialNodeArgs,
                flat_file: false,
                source_id: sourceId,
            });

            invalidatePipelineNodes();
            invalidateMissionControlDataFlowData();
            navigate(`/node/${node.id as string}/config`);
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [
        saving, 
        selectedTable, 
        name, 
        description, 
        selectedNodeType, 
        upstreamIds,
        combineBehavior,
        specialNodeArgs,
        fields,
        sourceId,
    ]);

    const isInDraft = useIsInDraftMode();

    const [showAddFlatFileModal, setShowAddFlatFileModal] = useState(false);

    const onSourceAdded = useCallback((pipelineNodeId: string) => {
        navigate(`/node/${pipelineNodeId}`);
    }, []);

    const tenantRegistration = useTenantRegistration();

    const [showDbTableHelp, setShowDbTableHelp] = useState(false);

    const sources = useSources();

    const sourceOptions = useMemo(() => {
        if (!sources.data) {
            return [];
        }

        return sources.data.map(s => {
            return {
                value: s.id as string,
                label: s.name,
            }
        });
    }, [sources.dataUpdatedAt])

    if (!isInDraft) {
        return <PageStructure pageTitle="New Node">
            <PageContent>
                <div className="p-5">
                    You need to be in draft mode to create new nodes.
                </div>
            </PageContent>
        </PageStructure>
    }

    return <PageStructure
        pageTitle="New Node"
    >
        <Offcanvas placement="end" show={showDbTableHelp} onHide={() => setShowDbTableHelp(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Database Table Access</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            <p>If you can't find the table you're looking for, it's likely because Pliable's snowflake role doesn't have access. Try running these commands, replacing <code>DATABASE_NAME</code> and <code>SCHEMA_NAME</code> with the information for the specific table you want to connect.</p>
                            <hr />
                            {tenantRegistration.data && <>
                                <p><code>set grant_db = 'DATABASE_NAME';</code></p>
                                <p><code>set grant_schema = 'DATABASE_NAME.SCHEMA_NAME';</code></p>
                                <p><code>GRANT USAGE ON DATABASE identifier($grant_db) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                                <p><code>GRANT USAGE ON SCHEMA identifier($grant_schema) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                                <p><code>GRANT SELECT ON ALL TABLES IN SCHEMA identifier($grant_schema) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                            </>}

                        </div>
                    </PaneContent>
                
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        
                        
                        
        <PageContent>
            <Pane>
                <PaneContent>
                <DraftModeRequired>
                <div className="p-5">
                    <h1 className="page-title">Create New Node</h1>
                        <h2>What type of node?</h2>
                        {NODE_TYPE_GROUPS.map(ntg => {
                            return <>
                                <h3>{ntg.label}</h3>
                                <p>{ntg.description}</p>
                                <Grid>
                                    {ntg.nodeTypes.filter(nt => !nt.disableSelection && (nt.value != 'REPORT' || pivotTablesEnabled)).map(nt => <NodeTypeComponent
                                        title={nt.label}
                                        description={nt.description}
                                        selected={selectedNodeType == nt.value}
                                        onClick={() => selectNodeType(nt.value)}
                                        icon={nt.icon}

                                    />)}
                                </Grid>
                                <hr />
                            </>
                        })}
                </div>
                </DraftModeRequired>
                </PaneContent>
                
            </Pane>
        </PageContent>
        <PageSidebar right big>
            <Pane>
                <PaneContent>
                    <DraftModeRequired>
                    <div className="p-3">
                        
                        {selectedNodeType === 'SOURCE' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Source Table</h2>
                                    <small>Data Source</small>
                                </div>
                               
                                <Form.Group className="mb-3">
                                    <Form.Label>Select database table</Form.Label>
                                    <SnowflakeTableSelection
                                        onSelect={setSelectedTable}
                                        currentSelection={selectedTable}
                                    />
                                    <Form.Text><a className="force-link" onClick={() => setShowDbTableHelp(true)}>Don't see the table you're looking for?</a></Form.Text>
                                </Form.Group>
                                
                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. salesforce_contacts</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Node Source</Form.Label>
                                    <Dropdown
                                        options={sourceOptions}
                                        selected={sourceId}
                                        onChange={setSourceId}
                                    />
                                    <Form.Text>This helps Pliable group your data sources together into related groups that share configuration.</Form.Text>
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !selectedTable}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                            
                        )}
                        {selectedNodeType === 'SOURCE_FILE' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Source File</h2>
                                    <small>Data Source</small>
                                </div>
                               
                                <DataSourceImporter
                                onSourceAdded={onSourceAdded}
                                // allowSelectExisting={props.allowSelectExisting}
                                // selectExistingBlacklist={props.selectExistingBlacklist}
                                // allowSystemConnectors={props.allowSystemConnectors}
                                // pipelineNodeId={props.existingPipelineNodeId}
                            />

                            </>
                            
                        )}
                        {selectedNodeType === 'STACK' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Stack</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "combined_events</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source models</Form.Label>
                                    <PipelineNodeMultiSelector
                                        selectedIds={upstreamIds}
                                        onSelect={setUpstreamIds}
                                    />
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'CUSTOM' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Custom SQL</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "combined_events</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'DATE_DIMENSION' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Date Dimension</h2>
                                    <small>Data Modeling</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "dim_date"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                </Form.Group>

                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Fiscal Year Start</Form.Label>
                                    <div className="row">
                                        <div className="col-6 pe-1">
                                            <Form.Group>
                                                <Form.Label className="small">Month</Form.Label>
                                                <select className="form-control" value={specialNodeArgs.fiscal_year_month} onChange={(e) => {
                                                    setSpecialNodeArgs(draft => {
                                                        draft.fiscal_year_month = e.target.value;
                                                    })
                                                }}>
                                                    <option value="01">January</option>
                                                    <option value="02">February</option>
                                                    <option value="03">March</option>
                                                    <option value="04">April</option>
                                                    <option value="05">May</option>
                                                    <option value="06">June</option>
                                                    <option value="07">July</option>
                                                    <option value="08">August</option>
                                                    <option value="09">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </select>
                                            </Form.Group>
                                        </div>
                                        <div className="col-6 ps-1">
                                            <Form.Group>
                                                <Form.Label className="small">Day</Form.Label>
                                                <input type="number" className="form-control" min="1" max="31" value={specialNodeArgs.fiscal_year_day} onChange={(e) => {
                                                    setSpecialNodeArgs(draft => {
                                                        draft.fiscal_year_day = e.target.value.toString();
                                                    })
                                                }}/>
                                                
                                            </Form.Group>
                                        </div>
                                    </div>
                                    
                                    
                                   
                                </Form.Group> */}

                                <Form.Group className="mb-3">
                                    <Form.Label>Date Range</Form.Label>
                                    <div className="row">
                                        <div className="col-6 pe-1">
                                            <Form.Group>
                                                <Form.Label className="small">Start</Form.Label>
                                                <Form.Control type="date" value={specialNodeArgs.start_date} onChange={(e) => {
                                                    setSpecialNodeArgs(draft => {
                                                        draft.start_date = e.target.value;
                                                    })
                                                }} />
                                            </Form.Group>
                                            
                                        </div>
                                        <div className="col-6 ps-1">
                                            <Form.Group>
                                                <Form.Label className="small">End</Form.Label>
                                                <Form.Control type="date" value={specialNodeArgs.end_date} onChange={(e) => {
                                                    setSpecialNodeArgs(draft => {
                                                        draft.end_date = e.target.value;
                                                    })
                                                }} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <Form.Text>Defaults to <code>1/1/2020</code> - <code>TODAY</code> if empty</Form.Text>

                                    
                                </Form.Group>
                                

                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'MERGE' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Merge</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "merged_customers"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source models</Form.Label>
                                    <PipelineNodeMultiSelector
                                        selectedIds={upstreamIds}
                                        onSelect={setUpstreamIds}
                                    />
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'REPORT' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Pivot Table</h2>
                                    <small>Reporting</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your table</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "customer_report"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your table</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                
                                
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'DATAMART' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Datamart</h2>
                                    <small>Reporting</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your mart</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "mart_customers"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your mart</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select root model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'DATAMART',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                    <Form.Text>Using this model, Pliable will determine all available columns for your datamart using your facts, dimensions, and relationships.</Form.Text>
                                </Form.Group>
                                
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'BASIC_CHART' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Basic Chart</h2>
                                    <small>Visualization</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your chart</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your chart</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select data source</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'BASIC_CHART',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                    <Form.Text>You will be able to display data from this node.</Form.Text>
                                </Form.Group>
                                
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'SUMMARIZE' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Summarize</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "summarized_events"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'SUMMARIZE',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'IDENTIFY' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Identify</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "summarized_events"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'IDENTIFY',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'SPLIT' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Split</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "split_logs""</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'SPLIT',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model column to split on</Form.Label>
                                    <PipelineNodeColumnSelector
                                        pipelineNodeId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        selectedId={specialNodeArgs.column_id}
                                        onSelect={(selected: string) => {
                                            setSpecialNodeArgs(draft => {
                                                draft.column_id = selected;
                                            })
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Column Format</Form.Label>
                                    <Dropdown
                                        selected={specialNodeArgs.column_format}
                                        onChange={(newVal: string) => {
                                            setSpecialNodeArgs(draft => {
                                                draft.column_format = newVal;
                                            });
                                        }}
                                        options={[
                                            {
                                                label: 'Delimited String',
                                                value: 'DELIMITED_STRING',
                                            }, {
                                                label: 'JSON Array',
                                                value: 'ARRAY',
                                            }
                                        ]}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Delimiter</Form.Label>
                                    <Form.Control disabled={specialNodeArgs.column_format != 'DELIMITED_STRING'} value={specialNodeArgs.delimiter} onChange={(e) => {
                                        setSpecialNodeArgs(draft => {
                                            draft.delimiter = e.target.value;
                                        });
                                    }}
                                    />
                                </Form.Group>
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds || !specialNodeArgs.column_format || !specialNodeArgs.column_id}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'VIEW' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">View</h2>
                                    <small>Staging</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "my_view""</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'VIEW',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                </Form.Group>
                                
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'DESTINATION' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Destination</h2>
                                    <small>Reporting</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "my_view""</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Describe your model</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Select source model</Form.Label>
                                    <PipelineNodeSelector
                                        selectedId={upstreamIds.length > 0 ? upstreamIds[0] : ''}
                                        onSelect={(selected: PipelineNode | undefined) => {
                                            if (selected) {
                                                setUpstreamIds([selected.id as string]);
                                            } else {
                                                setUpstreamIds([]);
                                            }
                                        }}
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'DESTINATION',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                    />
                                </Form.Group>
                                
                                <AsyncButton
                                    className="w-100"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        {selectedNodeType === 'FACT' && (
                            <>
                                <div className="mb-2">
                                    <h2 className="mb-0">Fact</h2>
                                    <small>Data Modeling</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name your model</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />

                                    <Form.Text>E.g. "fact_meeting"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select source models</Form.Label>
                                    <PipelineNodeMultiSelector
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'FACT',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                        selectedIds={upstreamIds}
                                        onSelect={setUpstreamIds}
                                    />
                                </Form.Group>

                                <hr />
                                <Form.Label>Columns</Form.Label>
                                <NodeFieldList
                                    nodeFields={fields}
                                    onChange={setFields}
                                />
                                <AsyncButton
                                    className="w-100 mt-3"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}

                        {selectedNodeType === 'DIMENSION' && (
                            <>
                                 <div className="mb-2">
                                    <h2 className="mb-0">Business Object</h2>
                                    <small>Data Modeling</small>
                                </div>

                                <Form.Group className="mb-3">
                                    <Form.Label>Object Name</Form.Label>
                                    <Form.Control onChange={(e) => setName(e.target.value)} value={name} />
                                    <Form.Text>E.g. "dim_customers", "dim_products", or "dim_locations"</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} value={description} />
                                    
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select source models</Form.Label>
                                    <PipelineNodeMultiSelector
                                        optionFilter={(opt) => {
                                            return isValidUpstreamNode({
                                                id: 'NEW',
                                                node_type: 'DIMENSION',
                                                upstream_node_ids: [],
                                            }, opt)
                                        }}
                                        selectedIds={upstreamIds}
                                        onSelect={setUpstreamIds}
                                    />
                                </Form.Group>
                                <hr />
                                <Form.Label>Columns</Form.Label>
                                <NodeFieldList
                                    nodeFields={fields}
                                    onChange={setFields}
                                />
                                <AsyncButton
                                    className="w-100 mt-3"
                                    loading={saving}
                                    disabled={!name || !upstreamIds}
                                    variant="pliable"
                                    onClick={onSave}
                                    text="Create Node"
                                />

                            </>
                        )}
                        
                    </div>
                    </DraftModeRequired>
                    
                </PaneContent>
            </Pane>
        </PageSidebar>
    </PageStructure>
}

export default NewPipelineNodePage;
