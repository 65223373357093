import { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useLocation, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import { ToastContainer } from 'react-toastify';


import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { AlertsContainer } from '@services/alert/alert.service';


import {
  QueryClientProvider
} from 'react-query';
import { queryClient } from '@stores/data.store';

import "allotment/dist/style.css";

import useGlobalState from '@stores/global.state';

import ConfigService from '@services/config/config.service';


declare global {
  interface Window { 
    analytics: any; 
    LogRocket: any;
    thenaWidget: any;
  }
}

function usePageViews(){
  const location = useLocation();
  useEffect(() => {
    ConfigService.getInstance().checkRouting();
    window.analytics.page(location.pathname);
  }, [location]);
}



function App() {
  usePageViews();

  const globalState = useGlobalState();

  const onHideRecordTypeDetails = useCallback(() => {
    globalState.setActiveSourceRecordType('');

}, []);

const onHideBusinessObjectDetails = useCallback(() => {
    globalState.setActiveBusinessObject('');
}, []);


  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <AlertsContainer/>
      <Outlet/>
    </QueryClientProvider>
  );
}

export default App;
