import BuildOrchestrationORM, { BuildException, BuildOrchestration } from '@models/buildOrchestration';
import toast from '@services/toast.service';
import { MissionControlDataFlowEdge, MissionControlDataFlowNode, enterDraftMode, exitDraftMode, invalidateDataMarts, invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, invalidateSourceRecordTypes, queryClient, saveDataMart, useDraftVersionId, useMissionControlDataFlowData, useTemplates } from '@stores/data.store';
import { MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Modal, Offcanvas, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPromptAnswer, requireConfirmation } from '@services/alert/alert.service';
import DataSourceSelectModal from './Sources/DataSourceSelectModal.component';
import Dropdown from '@components/form/Dropdown.component';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@services/url.service';
import Danger from '@components/statusIndicators/Danger.component';
import ConfettiExplosion from 'react-confetti-explosion';
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from './PageStructure.component';
import { getErrorMessage } from '@services/errors.service';
import DataMartORM from '@models/dataMart';
import { MissionControlDataFlowDiagramManager } from '@components/missionControl/dataflow/MissionControlDataFlow';
import PipelineNodeInfo from '@components/pipelineNodes/PipelineNodeInfo.component';
import PipelineNodeSelector from '@components/pipelineNodes/PipelineNodeSelector.component';
import { PipelineNode, PipelineNodeORM, PipelineNodeRelationshipORM } from '@models/pipelineNode';
import produce from 'immer';
import AsyncButton from '@components/button/AsyncButton.component';
import DataLibrary from '@components/nav/DataLibrary.component';
import { requireDraftMode } from '@components/branch/help';
import TrackingService, { Events } from '@services/tracking.service';
import PipelineNodeName from '@components/pipelineNodes/PipelineNodeName.component';
import PipelineNodeConnector from '@components/pipelineNodes/PipelineNodeConnector.component';
import { DraftModeRequired, DraftOnly, ProdModeRequired } from '@components/project/DraftModeRequired.component';
import LoadingCard from '@components/card/LoadingCard.component';
import PipelineOrchestration from '@components/orchestration/PipelineOrchestration.component';
import DataModelSubnav from '@components/datamodel/DataModelSubnav.component';
import { getGroupValueForNodeType } from '@services/modeling.service';

const VisualTools = styled.div`
height: 40px;
box-shadow: 0px 2px 3px 0px #0000001A;
display: flex;

position: absolute;
top: 70px;
right: 20px;
z-index: 1001;
border: solid 1px var(--ct-border-color);
border-radius: 5px;
background-color: white;

div.spacer {
    display: inline-block;
    margin-left: 2rem;
    margin-right: 2rem;
    height: 38px;
    background-color: white;
    line-height: 38px;
    text-align: center;
    color: var(--ct-border-color);
}

button {
    height: 38px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    background-color: white;
    color: #bbb;
    border: none;
    font-size: 20px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    &:disabled {
        color: var(--ct-border-color);
        cursor: default;
    }
    

    &.active {
        color: #00A1E0;

        &:hover {
            color: #00A1E0;
        }
    }

    &:hover {
        color: black;

        &:disabled {
            color: var(--ct-border-color);
        }

    }
    &:first-child {
        border-right: solid 1px var(--ct-border-color);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
`

const EdgeActions = styled.div`

position: absolute;
width: 250px;
margin-left: calc(-490px);
z-index: 500;
display: flex;
flex-direction: row;
justify-content: center;


.button-wrapper {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
`;

const EdgeButton = styled.button`
height: 40px;
font-family: 'POPPINS';
background: rgba(15, 14, 49, 1);
color: rgba(255, 255, 255, 1);
padding: 0px;
margin: 0px;
text-align: center;
font-size: 18px;
position: relative;
line-height: 32px;
padding: 4px 12px;
border: none;

&.drag-handle {
    padding: 4px 2px;
}

&:hover {
    cursor: pointer;
    background: rgba(15, 14, 49, .85);
}

&:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

&:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

&.confirm {
    background: #EA4335;
}
`;

const NodeImage = styled.div`

`

interface RelationshipError {
    relationshipId: string;
    parentObjectName: string;
    error: 'AMBIGUOUS' | 'ORPHAN';
}

interface BuildErrorProps {
    buildException?: BuildException;
    objectType: string;
    objectId: string;
}

const BuildError = (props: BuildErrorProps) => {

    if (props.buildException && props.buildException.error_type == 'MissingMappingException') {
        return <Danger>
            <div>
                <strong>Mapping Error</strong><br />
                <p>{props.buildException.message}</p>
                <Link className="btn btn-light" to={`/business-object/${props.objectId}/fields`}>Fix</Link>
            </div>
        </Danger>
    }

    return <Danger>
        <div>
            <strong>Unknown build error.</strong><br />
            <p>Sorry, something went wrong and we haven't done a good job telling you what it is! Please contact support if this error continues.</p>
        </div>
    </Danger>
}

interface HandleProps {
    color: string;
    x: number;
    y: number;
    zIndex: string;
}

const Handle = (props: HandleProps) => {
    return <>
        <circle  cx={props.x} cy={props.y} r="8" fill={props.color}/>
        <circle  cx={props.x} cy={props.y} r="7" fill="white"/>
        <circle  cx={props.x} cy={props.y} r="6" fill={props.color}/>
    </>;
}

// @ts-ignore
const MenuToggle = forwardRef(({ children, onClick }, ref) => (
    <button className="icon-button"
        style={{fontSize: '24px'}}
    //   @ts-ignore
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));


// @ts-ignore
const ConnectToggle = forwardRef(({ children, onClick, disabled }, ref) => (
    <button
    //   @ts-ignore
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));


type edgeColor = 'default' | 'muted' | 'green' | 'purple' | 'red' | 'yellow';

interface EdgeProps {
    from: HTMLElement;
    to: HTMLElement;
    xOffset: number;
    yOffset: number;
    active: boolean;
    color: edgeColor;
    dashed?: boolean;
    onClick?: (evt: MouseEvent) => any;
    onHover?: (isHovering: boolean) => any;
    building?: boolean;
}

function calculateControlOffset(distance: number, curvature: number): number {
    if (distance >= 0) {
      return 0.5 * distance;
    }
  
    return curvature * 25 * Math.sqrt(-distance);
  }
  
  function getControlWithCurvature(pos: string, x1: number, y1: number, x2: number, y2: number, c: number): [number, number] {
    switch (pos) {
      case 'LEFT':
        return [x1 - calculateControlOffset(x1 - x2, c), y1];
      case 'RIGHT':
        return [x1 + calculateControlOffset(x2 - x1, c), y1];
    }
    throw new Error('invalid position');
  }



interface Position {
    x: number;
    y: number;
}

const Loader = styled.div`
position: fixed;
top: 50%;
left: 50%;
z-index: 2;
width: 100px;
height: 85px;
background: rgba(0, 0, 0, 0.5);
color: white;
border-radius: 10px;
line-height: 100px;
text-align: center;
`

// 


const CARD_MIN_WIDTH = 350;

export const recursiveGetNodesAndEdges = (
    thisNode: MissionControlDataFlowNode, 
    allNodes: MissionControlDataFlowNode[], 
    allEdges: MissionControlDataFlowEdge[], 
    nodeIdsWeSaw: string[], 
    edgeIdsWeSaw: string[],
    limitDirection?: string,
    excludeDescendentBusinessObjects?: boolean,
): [MissionControlDataFlowNode[], MissionControlDataFlowEdge[]] => {
    let nodes: MissionControlDataFlowNode[] = [];
    let edges: MissionControlDataFlowEdge[] = [];


    // Get edges pointing at this node
    allEdges.forEach(e => {
        if (e.source == thisNode.id && !nodeIdsWeSaw.includes(e.target) && (!limitDirection || limitDirection == 'source')) {
            const relatedNode = allNodes.find(n => n.id === e.target)!;
            if (!relatedNode) {
                return;
            }
            if (excludeDescendentBusinessObjects && getGroupValueForNodeType(thisNode.data.nodeType) == 'DATA_MODELING' && getGroupValueForNodeType(relatedNode.data.nodeType) == 'DATA_MODELING') {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'source', excludeDescendentBusinessObjects);
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if (e.target == thisNode.id && !nodeIdsWeSaw.includes(e.source) && (!limitDirection || limitDirection == 'target')) {
            const relatedNode = allNodes.find(n => n.id === e.source)!;
            if (!relatedNode) {
                return;
            }

            if (excludeDescendentBusinessObjects && getGroupValueForNodeType(thisNode.data.nodeType) == 'DATA_MODELING' && getGroupValueForNodeType(relatedNode.data.nodeType) == 'DATA_MODELING') {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'target', excludeDescendentBusinessObjects);
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if ((e.target == thisNode.id || e.source == thisNode.id) && !edgeIdsWeSaw.includes(e.id)) {
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
        }
    });

    return [nodes, edges];


}

const DagPage = () => {
    const { mode } = useParams();
    const missionControl = useMissionControlDataFlowData();
    // const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    // setDataLibraryEnabled(false);

    const query = useQuery();

    const [activeEdgeId, setActiveEdgeId] = useState('');

    const [loading, setLoading] = useState(false);

    const {data: draftVersionId} = useDraftVersionId();

    const parsedMode = useMemo(() => {
        if (!!mode) {
            return mode;
        }
        return 'flow';
    }, [mode]); 
    const navigate = useNavigate();

    const setMode = useCallback((newMode: string) => {
        navigate('/mission-control/' + newMode)
    }, [navigate]);
    // const [mode, setMode] = useState('DATA_FLOW');

    const canBuild = useMemo(() => {
        if(missionControl.data && missionControl.data.is_stale) {
            return true;
        }
        return false;
    }, [missionControl])

    const onBuildAll = useCallback(async () => {
        try{
            setBuilding(true);
            const orchestration = await BuildOrchestrationORM.buildAll();
            navigate(`/dag?watchOrchestrationId=${orchestration.id}`);
        }catch(ex) {
            toast('danger', 'Error', `${getErrorMessage(ex)}`);
        }
        
    }, [navigate]);

    const onBuildNode = useCallback(async (nodeId: string) => {
        if (!missionControl.data) {
            return;
        }
        try {
            setBuilding(true);
            const theNode = missionControl.data.nodes.find(n => n.id === nodeId);
            if (!theNode) {
                throw new Error('Node not found');
            }
            const orchestration = await BuildOrchestrationORM.buildWithSelector('+' + theNode.data.title);
            navigate(`/dag?watchOrchestrationId=${orchestration.id}&focusNodeId=${nodeId}`);
        }catch(ex) {
            toast('danger', 'Error', `${getErrorMessage(ex)}`);
        }
        
    }, [navigate, missionControl.dataUpdatedAt]);

    const [searchInput, setSearchInput] = useState('');

    const data = useMissionControlDataFlowData();

    const containerRef = useRef<HTMLDivElement>(null);

    const [leftEdges, setLeftEdges] = useState<EdgeProps[]>([]);
    const [rightEdges, setRightEdges] = useState<EdgeProps[]>([]);

    const [scrollTop, setScrollTop] = useState(0);

    const onScroll = useCallback((e: any) => {
        setScrollTop(containerRef.current!.scrollTop);
    }, [containerRef]);

    const [activeNodeId, setActiveNodeId] = useState('');

    const [nodeIdsForActiveEdge, setNodeIdsForActiveEdge] = useState<string[]>([]);


    const [debouncedSearchInput] = useDebounce(searchInput, 500);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [edges, setEdges] = useState<MissionControlDataFlowEdge[]>([]);

    const [focusNodeId, setFocusNodeId] = useState('');
    useEffect(() => {
        const focusNodeId = query.get('focusNodeId');
        setFocusNodeId(focusNodeId ? focusNodeId: '');

    }, [query]);

    const [filteredNodes, setFilteredNodes] = useState<MissionControlDataFlowNode[]>([]);
    useEffect(() => {
        if (!data.data) {
            setFilteredNodes([]);
            setEdges([]);
        } else {
            const theNode = focusNodeId ? data.data.nodes.find(n => n.id === focusNodeId) : undefined;
            if (theNode) {
                const [nodesToUse, edgesToUse] = recursiveGetNodesAndEdges(theNode, data.data.nodes, data.data.edges, [focusNodeId], []);
                nodesToUse.push(theNode);
                setFilteredNodes(nodesToUse);
                setEdges(edgesToUse);
            } else {
                setFilteredNodes(data.data.nodes);
                setEdges(data.data.edges);
            }
        }
    }, [data.dataUpdatedAt, debouncedSearchInput, activeEdgeId, focusNodeId])

    

    

    const showEdgesForNode = useCallback((nodeId: string) => {
       
    }, [scrollTop, data.dataUpdatedAt, containerRef]);

    const addNewBusinessObject = useCallback(async () => {
        const name = await getPromptAnswer('Enter Name', 'New Business Object');
        if (name) {
            const node = await PipelineNodeORM.save({
                id: null,
                name: name,
                description: '',
                fields: [],
                upstream_node_ids: [],
                node_type: 'BUSINESS_OBJECT',
                table_name: '',
                flat_file: false
            });
            
            const node_id = `PipelineNode:${node.id as string}`;
            invalidatePipelineNodes();
            await data.refetch();
            
            navigate(`/dag?focusNodeId=${node_id}`);
            
        }

    }, []);

    const addNewStagingTable = useCallback(async () => {
        const name = await getPromptAnswer('Enter Name', 'New Staging Table');
        if (name) {
            const node = await PipelineNodeORM.save({
                id: null,
                name: name,
                description: '',
                fields: [],
                upstream_node_ids: [],
                node_type: 'STAGING',
                table_name: '',
                flat_file: false
            });
            
            const node_id = `PipelineNode:${node.id as string}`;
            invalidatePipelineNodes();

            await data.refetch();
            navigate(`/dag?focusNodeId=${node_id}`);
            
        }

    }, []);

    const addNewReport = useCallback(async () => {
        const reportName = await getPromptAnswer('Enter Report Name', 'New Report');
        if (reportName) {
            const report = await saveDataMart({
                id: null,
                name: reportName,
                description: '',
                sql: '',
                include_in_snapshots: false
            });
            const node_id = `DataMart:${report.id as string}`;
            invalidateDataMarts();

            await data.refetch();
            navigate(`/dag?focusNodeId=${node_id}`);
            
        }

    }, []);

    const [showAddSourceModal, setShowAddSourceModal] = useState(false);

    const addNewReportClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            addNewReport
        )
    }, [draftVersionId]);

    const addNewBusinessObjectClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            addNewBusinessObject
        )
    }, [draftVersionId]);


    const addNewStagingTableClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            addNewStagingTable
        )
    }, [draftVersionId]);

    const addSourceClick = useCallback(() => {
        requireDraftMode(
            draftVersionId as string,
            () => setShowAddSourceModal(true)
        )
    }, [draftVersionId]);

    const onSourceAdded = useCallback((pipelineNodeId: string) => {
        const node_id = `PipelineNode:${pipelineNodeId}`;
        invalidatePipelineNodes();
        navigate(`/dag?focusNodeId=${node_id}`);
    }, [navigate]);

    const scrollWindowRef = useRef<HTMLDivElement>(null);


    const activeEdge = useMemo(() => {
        if (data.data && activeEdgeId) {
            return data.data.edges.find(e => e.id === activeEdgeId);
        }
        return undefined;
    }, [activeEdgeId, data.dataUpdatedAt]);
    useEffect(() => {
        if (!activeEdgeId || !data.data) {
            return;
        }

        

    }, [activeEdgeId, data.dataUpdatedAt]);

    

    const onEditEdgeClick = useCallback((edgeId: string) => {
        if (!data.data) {
            return;
        }

        const theEdge = data.data.edges.find(e => e.id === edgeId);


        if (!theEdge) {
            return;
        }

        if (theEdge.data.type == 'MAPPING') {
            const srtId = theEdge.source.split(':')[1];
            const boId = theEdge.target.split(':')[1];
            navigate(`/node/${boId}/fields?sourceNodeId=${srtId}`);
        } else if (theEdge.data.type == 'NODE_RELATIONSHIP') {
            const relationshipId = theEdge.data.object_id;
            navigate(`/node/${theEdge.source.split(':')[1]}/relationships/${relationshipId}`);
        }
    }, [data.dataUpdatedAt, navigate]);

    const onDeleteEdgeClick = useCallback(() => {
        setConfirmDelete(true);
    }, []);

    const doNodeDeletion = useCallback(async (nodeId: string) => {
        console.log('Doing node deletion');
        if (!data.data) {
            console.log('No data');
            return;
        }

        const theNode = data.data.nodes.find(n => n.id === nodeId);

        if (!theNode) {
            console.log('Node not found');
            return;
        }

        setLoading(true);

        try {
            const apiCall = PipelineNodeORM.deleteById(theNode.data.objectId);

            await apiCall;
            invalidatePipelineNodes();
            invalidateDataMarts();

            data.refetch();

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }, [data.dataUpdatedAt]);

    const onDeleteNodeClick = useCallback(async (nodeId: string) => {
        let message: string | JSX.Element = 'Are you sure you want to delete this?';
        
        if (!!data.data) {
            const theNode = nodeId ? data.data.nodes.find(n => n.id === nodeId) : undefined;
            if (theNode) {
                const [nodesToUse, edgesToUse] = recursiveGetNodesAndEdges(theNode, data.data.nodes, data.data.edges, [focusNodeId], []);
                const requiredIn = nodesToUse.filter((node) => node.data.nodeType == "REPORT");

                if (requiredIn) {
                    message = <>
                        <p>This node is used in:</p>
                        <ul>
                            {requiredIn.map((n) => {
                                return <li>{n.data.title}</li>
                            })}
                        </ul>
                        <p>Deleting will have an effect on these reports. Are you sure?</p>
                    </>
                }
            }
        }

        const confirmed = await requireConfirmation(message, 'Confirm Deletion', 'Delete', 'Cancel');
        if (confirmed) {
            doNodeDeletion(nodeId);
        }
    }, [data, doNodeDeletion]);



    const onDeleteEdge = useCallback(async (edgeId: string) => {
        if (!data.data) {
            return;
        }

        const edge = data.data.edges.find(e => e.id === edgeId);
        if (!edge) {
            return;
        }

        const targetId = edge.target.split(':')[1];
        const sourceId = edge.source.split(':')[1];
        if (edge.data.type === 'MAPPING') {
            // delete the relationship then refetch
            const pn = await PipelineNodeORM.findById(targetId);
            if(!!pn){
    
                await PipelineNodeORM.patch(targetId, {
                    upstream_node_ids: pn.upstream_node_ids.filter((nodeId) => nodeId != sourceId)
                });
                data.refetch();
            }
        } else if (edge.data.type === 'NODE_RELATIONSHIP') {
            if (!edge.data.object_id) {
                return;
            }
            await PipelineNodeRelationshipORM.deleteById(edge.data.object_id!);
            data.refetch();
            invalidatePipelineNodes();
        }
    }, [data.dataUpdatedAt]);

    const onConfirmEdgeDelete = useCallback(() => {
        setConfirmDelete(false);
        onDeleteEdge(activeEdgeId);
        setActiveEdgeId('');
    }, [activeEdgeId]);

    const toggleFocusOnNode = useCallback((nodeId: string) => {
        if (nodeId == focusNodeId) {
            navigate('/dag')
        } else {
            navigate('/dag?focusNodeId=' + nodeId)

        }
    }, [navigate, focusNodeId])

    const [building, setBuilding] = useState(false);
    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);

    const toggleNodeLineage = useCallback((nodeId: string) => {

    }, [navigate]);

    const [shouldShowConfetti, setShouldShowConfetti] = useState(false);

    const watchOrchestrationId = useQuery().get('watchOrchestrationId');

    const keepBuildOpen = useQuery().get('keepBuildOpen');
    const loadOrchestration = useCallback(async (id: string) => {
        const updatedOrch = await BuildOrchestrationORM.findById(id);
        setActiveOrchestration(updatedOrch);
    }, []);

    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(activeOrchestration.status)) {
            
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                try {
                    const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                    setActiveOrchestration(updatedOrch);
                } catch (err) {
                    toast('danger', 'Error', getErrorMessage(err));
                }
                
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setShouldShowConfetti(true);
            setTimeout(() => {
                setShouldShowConfetti(false);
                
            }, 3000);
            toast('success', 'Success', 'Models have been successfully updated.');
            if (!keepBuildOpen) {
                setActiveOrchestration(undefined);
            }
            invalidateEverything();
            setBuilding(false);
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', activeOrchestration.error ? activeOrchestration.error : 'Unknown build error');
            invalidateEverything();
            setBuilding(false);
        }

    }, [activeOrchestration, focusNodeId, keepBuildOpen]);

    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);

    const somethingNeedsBuilding = useMemo(() => {
        if (!data.data) {
            return false;
        }

        const needsBuilding = data.data.nodes.find(n => !!n.data.needsBuilding);

        if (needsBuilding) {
            return true;
        }
        return false;
    }, [data.dataUpdatedAt]);

    useEffect(() => {
        if (watchOrchestrationId) {
            loadOrchestration(watchOrchestrationId);
        }
    }, [watchOrchestrationId, loadOrchestration]);


    const onEdgeConfigureReportClick = useCallback(() => {
        if (!activeEdge) {
            return;
        }

        navigate(`/reporting/${activeEdge.data.object_id}`);
    }, [activeEdge]);

    const columnStyles = {
        minWidth: '350px',
        width: '28%'
    };

    const edgeColumnStyles = {
        minWidth: '100px',
        width: '8%',
    };

    const onClickNode = useCallback(() => {

    }, []);

    const onEditEdge = useCallback(() => {

    }, []);


    const [connectingNode, setConnectingNode] = useState<MissionControlDataFlowNode|undefined>(undefined);

    const onConnectNode = useCallback((nodeId: string) => {
        if (!data.data) {
            return;
        }

        setConnectingNode(data.data.nodes.find(n => n.id === nodeId));
    }, [data.dataUpdatedAt]);


    const [selectedDownstreamNodeId, setSelectedDownstreamNodeId] = useState('');

    const onSelectDownstreamNode = useCallback((node: PipelineNode|undefined) => {
        if (node) {
            setSelectedDownstreamNodeId(node.id as string);

        } else {
            setSelectedDownstreamNodeId('');
        }
    }, []);

    const [savingConnection, setSavingConnection] = useState(false);
    const [connectionError, setConnectionError] = useState('');

    const [newNodeType, setNewNodeType] = useState('STAGING');
    const [newNodeName, setNewNodeName] = useState('');
    const [newNodeDescription, setNewNodeDescription] = useState('');

    const [connectionType, setConnectionType] = useState('EXISTING');

    const onToggleDraftMode = useCallback(async () => {
        if(draftVersionId) {
            await exitDraftMode('mission_control_checkbox');
        }else{
            await enterDraftMode('mission_control_checkbox');
        }
    }, [draftVersionId]);

    const saveConnection = useCallback(async () => {
        setConnectionError('');
        if (!connectingNode) {
            return;
        }

        if (connectionType == 'EXISTING') {
            const targetNode = await PipelineNodeORM.findById(selectedDownstreamNodeId);
            const upstreamIds = targetNode.upstream_node_ids;
            if (upstreamIds.includes(connectingNode.data.objectId)) {
                setConnectionError('Nodes are already connected!');
                return;
            }

            setSavingConnection(true);
            const newUpstreamIds = produce(upstreamIds, draft => {
                draft.push(connectingNode.data.objectId);
            });
            try {
                await PipelineNodeORM.patch(targetNode.id as string, {
                    'upstream_node_ids': newUpstreamIds,
                });
                toast('success', 'Success', 'Nodes connected');
                invalidateMissionControlDataFlowData();
                invalidatePipelineNodes();
                setConnectingNode(undefined);

            } catch (err) {
                setConnectionError(getErrorMessage(err));
            } finally {
                setSavingConnection(false);

            }
        } else {
            try {
                const targetNode = await PipelineNodeORM.save({
                    id: null,
                    name: newNodeName,
                    description: newNodeDescription,
                    node_type: newNodeType,
                    fields: [],
                    upstream_node_ids: [connectingNode.data.objectId],
                    table_name: '',
                    flat_file: false
                });
                toast('success', 'Success', 'Nodes connected');
                invalidateMissionControlDataFlowData();
                invalidatePipelineNodes();
                setConnectingNode(undefined);
            } catch (err) {
                setConnectionError(getErrorMessage(err));
            } finally {
                setSavingConnection(false);

            }
        }
        
        

        
    }, [selectedDownstreamNodeId, connectingNode, newNodeName, newNodeDescription, newNodeType]);

    

    const connectionOptionsFilter = useCallback((pn: PipelineNode) => {
        // Exclude nodes this is already connected to
        return !pn.upstream_node_ids.includes(connectingNode?.data.objectId as string);
    }, [connectingNode]);

    const enterDraft = useCallback(async () => {
        await enterDraftMode('cold_start');
        // navigate('/node/new');
    }, [navigate]);

    const changeFocusNode = useCallback((id: string) => {
        navigate(`?focusNodeId=${id}`);
    }, [navigate]);

    const allNodes = useMemo(() => {
        return (data.data?.nodes || []).sort((a, b) => {
            return a.data.title.localeCompare(b.data.title);
        });
    }, [data.dataUpdatedAt])

    return <PageStructure>
        <PageContent>
            <DataModelSubnav/>
            <PageContentInner hasHeader noScroll>
                <div className="row" style={{height: '100%'}}>
                    <div className="col-3 pe-3">
                        <Pane>
                            <PaneContent>
                                <h2>Full Pipeline</h2>
                                <ul className="list-group">
                                    {allNodes.map((node) => {
                                        return <li className="list-group-item list-group-item-action" onClick={() => {
                                            changeFocusNode(node.id)
                                        }}>
                                            <strong>{node.data.title}</strong>
                                            {node.data.description && <div className="small text-muted">{node.data.description}</div>}
                                        </li>
                                    })}
                                </ul>
                                <div className="mb-3"></div>

                            </PaneContent>
                        </Pane>
                    </div>
                    <div className="col-9">
                        {shouldShowConfetti &&  <div style={{marginLeft: '50%'}}> <ConfettiExplosion colors={['#ff9f00', '#ffffff', '#000000', '#313A46', "#666666", "#343434"]} /></div>}
                        {data.isLoading && (
                            <LoadingCard></LoadingCard>
                        )}
                        {!data.isLoading && filteredNodes.length > 0 && (
                            <div className="card" style={{height: 'calc(100% - 5rem)'}}>
                                 <MissionControlDataFlowDiagramManager
                                        activeOrchestration={activeOrchestration}
                                        nodeData={filteredNodes}
                                        edgeData={edges}
                                        onDeleteEdge={onDeleteEdge}
                                        onClickNode={onClickNode}
                                        onEditEdge={onEditEdgeClick}
                                        onConnectNode={onConnectNode}
                                        focusNodeId={focusNodeId}
                                        onDeleteNode={onDeleteNodeClick}
                                        onClickEdgeStatusIndicator={() => {
                                            
                                        }}
                                        onToggleNodeLineage={toggleFocusOnNode}
                                        onBuildNode={onBuildNode}
                                    />
                                <div className="border-top bg-white" style={{'height': !!activeOrchestration ? '50%' : '0%'}}>
                                    <Pane>
                                        <PaneContent>
                                            <div className="p-3">
                                                {!!activeOrchestration && <PipelineOrchestration orchestration={activeOrchestration}/>}
                                            </div>
                                        </PaneContent>
                                    </Pane>
                                    
                                    
                                </div>
                            </div>
                            
                        )}
                        {!data.isLoading && filteredNodes.length == 0 && (
                            <div>
                                <h3>Nothing here yet.</h3>
                                <DraftModeRequired justHideChildren>
                                    <Link to="/node/new" className="btn btn-pliable">Create your first node</Link>
                                </DraftModeRequired>
                                <ProdModeRequired>
                                    
                                    <button className="btn btn-pliable" onClick={enterDraft}>Start building</button>
                                </ProdModeRequired>
                                
                            </div>
                        )}               
                    </div>

                    
                    {!!connectingNode && (
                        <PipelineNodeConnector 
                            connectingNodeId={connectingNode.data.objectId}
                            onHide={() => setConnectingNode(undefined)}
                            show
                        />
                    )}
                </div>
            </PageContentInner>
        </PageContent>
    </PageStructure>
        
}

export default DagPage;

