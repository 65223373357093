import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FieldTitle, HeaderContainer, SubHeaderContainer } from "./shared";
import { NodeFieldJoinPath, PipelineNode, PipelineNodeField, PipelineNodeJoinPath } from "@models/pipelineNode";
import { Collapse, Form } from "react-bootstrap";
import { DragSourceMonitor, useDrag } from "react-dnd";
import styled from 'styled-components';
import { useDebounce } from "use-debounce";
import { useIsInDraftMode, useJoinPaths, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import PipelineNodeSelector from "../PipelineNodeSelector.component";
import PipelineNodeJoinPathLabel from "./PipelineNodeJoinPathLabel.component";
import { confirmation } from "@services/alert/alert.service";
import { Link } from "react-router-dom";
import OverflowTooltip from "@components/tooltip/OverflowTooltip.component";

const FieldContainer = styled.div`
padding: 16px 20px;
margin-bottom: 1rem;



&.selectable {
    &:hover {
        cursor: pointer;
        outline: solid 1px var(--pliable-blue);
    }
}

&.active {
    outline: solid 2px var(--pliable-blue);

    &:hover {
        outline: solid 2px var(--pliable-blue);
    }
}

`

const DraggableFieldStyles = styled.div`
padding: 4px 8px;

margin-bottom: .5rem;
display: flex;
flex-direction: row;
line-height: 24px;
align-items: center;

.column-label {
    flex: 1;
    font-size: 14px;
    min-width: 0;

    div.label {
        font-weight: bold;
        font-family: "Poppins";
    }
    div.description {
        font-size: 13px;
    }
}

.icons {
    font-size: 18px;
}

&:hover {
    background-color: #eee;
    cursor: move;
}



&.disabled {
    &:hover {
        background-color: white !important;
        cursor: default;
    }
}
user-select: none;
`
;

const FieldListSubHeader = styled.h5`
margin-top: 2rem;
color: #aaa;
font-weight: 400;

button {
    font-size: 13px;
    background: none;
    padding: none;
    border: none;
    color: var(--ct-body-color);


    &:hover {
        color: black;
        cursor: pointer;
    }

    &.highlight {
        color: var(--pliable-yellow);
    }
}
`

interface DraggableFieldProps {
    column: PipelineNodeField;
    showCheckMark?: boolean;
    onDrop: (sourceField: PipelineNodeField, droppedFieldId: string) => void;
    disableActions?: boolean;
    onShowColumnStats?: () => any;
    disableColumnStats?: boolean;

}

const DraggableField = (props: DraggableFieldProps) => {
    const [{ isDragging, opacity }, drag] = useDrag(() => ({
        type: 'FIELD',
        item: {
            column: props.column,
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult() as any;
            if (item && dropResult) {
                let alertMessage = ''
                const isDropAllowed = true;
            }

            if (dropResult) {
                props.onDrop(props.column, dropResult.fieldId);

            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging(),
            column: props.column,
        }),
    }));

    return <DraggableFieldStyles ref={props.disableActions ? null : drag} className={`shadow-box ${props.disableActions ? 'disabled' : ''}`}>
        <div className="column-label">
            <OverflowTooltip
             className="label overflow-ellipsis"
             text={props.column.label}
             />
            <div className="description overflow-ellipsis">{props.column.description || 'No description'}</div>
        </div>
        <div className="icons">

            {props.showCheckMark && <span>
                <i className="mdi mdi-check-bold text-success"></i>
            </span>}
            {!props.disableColumnStats && <>
                <button className="icon-button" onClick={props.onShowColumnStats}>
                    <i className="mdi mdi-eye"></i>
                </button>
            </>}
            
            {!props.disableActions && (
                <span>
                    <i className="mdi mdi-drag"></i>
                </span>
            )}
            

        </div>
    </DraggableFieldStyles>
}

export function getUsedFieldKey(
    fieldId: string, 
    nodeId: string,
    joinPath?: PipelineNodeJoinPath[]) {
    let components: string[] = [nodeId];

    if (joinPath) {
        components = components.concat(joinPath.map(j => {
            return `${j.object_id}|${j.object_type}`;
        }));
    }

    components.push(fieldId);
    return components.join(':');
}


interface DataSourceProps {
    sourceNodeId: string;
    allowRemove?: boolean;
    alwaysExpanded?: boolean;
    onRemoveSource?: () => any;
    onDropSourceColumn: (sourceColumn: PipelineNodeField, targetFieldId: string) => any;
    joinPathBase?: PipelineNodeJoinPath[]; 
    onShowColumnStats?: (sourceColumn: PipelineNodeField) => any;
    onAddAllColumns?: (sourceColumns: PipelineNodeField[]) => any;

    // List of `node_path[...]:field_id`
    usedColumns?: string[];
    searchFilter?: string;
    title?: string;
}



export const DataSourceDraggableColumnPicker = (props: DataSourceProps) => {
    const includeUuid = true;
    const pipelineNode = usePipelineNode(props.sourceNodeId);
    const [isVisible, setIsVisible] = useState(!!props.alwaysExpanded);

    const [usedColumns, setUsedColumns] = useState<PipelineNodeField[]>([]);
    const [unusedColumns, setUnusedColumns] = useState<PipelineNodeField[]>([]);

    useEffect(() => {
        const newUsedColumns: PipelineNodeField[] = [];
        const newUnusedColumns: PipelineNodeField[] = [];
        const searchFilter = props.searchFilter ? props.searchFilter.toLowerCase() : '';
        if (pipelineNode.data) {
            const availableFields = [...pipelineNode.data.fields];
            
            if (includeUuid) {
                availableFields.push({
                    id: '_PLB_UUID',
                    name: '_PLB_UUID',
                    label: 'Pliable Record ID',
                    type: 'STRING',
                    description: '',
                    part_of_composite_key: false,
                    map_options: [],
                    taxonomic_id: '',
                    cell_actions: []
                });
            }
            availableFields.forEach(f => {
                if (props.searchFilter && f.label.toLowerCase().indexOf(searchFilter) < 0) {
                    return;
                }
                const key = getUsedFieldKey(f.id, props.sourceNodeId, props.joinPathBase);
    
                if (props.usedColumns && props.usedColumns.indexOf(key) >= 0) {
                    newUsedColumns.push(f);
                } else {
                    newUnusedColumns.push(f);
                }
            });
        }
        
        setUsedColumns(newUsedColumns.sort((a, b) => {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
        }));
        setUnusedColumns(newUnusedColumns.sort((a, b) => {
            return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
        }));
    }, [props.sourceNodeId, pipelineNode.dataUpdatedAt, props.joinPathBase, props.usedColumns, props.searchFilter]);

    const inDraftMode = useIsInDraftMode();
    

    if (pipelineNode.isLoading || !pipelineNode.data) {
        return <FieldContainer className="shadow-box">
            <div className="p-3">
                <i className="mdi mdi-loading mdi-spin"></i> Loading
            </div>
        </FieldContainer>
    }

    
    return <FieldContainer className="shadow-box">
        <FieldTitle onClick={() => {
            !props.alwaysExpanded && setIsVisible(!isVisible);
        }} className="mb-1">
            <h4 className="overflow-ellipsis">{pipelineNode.data.name}</h4>
            {props.allowRemove && inDraftMode && (
                <button onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (props.onRemoveSource) {
                        props.onRemoveSource();
                    }
                    
                }}>
                    <i className="mdi mdi-delete"></i>
                </button>
            )}
            {!props.alwaysExpanded && <>
                {isVisible && (
                    <span><i className="mdi mdi-unfold-less-horizontal"></i></span>
                )}
                {!isVisible && (
                    <span><i className="mdi mdi-unfold-more-horizontal"></i></span>
                )}
            </>}
            
        </FieldTitle>
        {props.joinPathBase && (
            <div className="font-13 mb-1">
                <PipelineNodeJoinPathLabel linkOut joinPath={props.joinPathBase}/>
            </div>
        )}
        <Collapse in={isVisible}>
            <div>
                {usedColumns.map((c, idx) => {
                    return <DraggableField
                        key={c.id}
                        column={c}
                        showCheckMark
                        disableActions={!inDraftMode}
                        onDrop={props.onDropSourceColumn}
                        onShowColumnStats={() => {
                            props.onShowColumnStats && props.onShowColumnStats(c);
                        }}
                        disableColumnStats={!props.onShowColumnStats}

                    />
                })}
                <FieldListSubHeader className="d-flex center-vertically">
                    <div className="flex-1">Unused Columns</div>
                    {unusedColumns.length > 0 && !!props.onAddAllColumns && inDraftMode && (
                        <button className="ms-3" onClick={() => {
                            const filteredColumns = unusedColumns.filter(c => c.name !== '_PLB_UUID');
                            props.onAddAllColumns && props.onAddAllColumns(filteredColumns);
                        }}>
                            Add All <i className="mdi mdi-play"></i>
                        </button>
                        )}

                    
                </FieldListSubHeader>
                {unusedColumns.map((c, idx) => {
                return <DraggableField
                        key={c.id}
                        disableActions={!inDraftMode}
                        column={c}
                        onDrop={props.onDropSourceColumn}
                        onShowColumnStats={() => {
                            props.onShowColumnStats && props.onShowColumnStats(c);
                        }}
                        disableColumnStats={!props.onShowColumnStats}
                        />              
                    })}
                {!!props.searchFilter && usedColumns.length == 0 && unusedColumns.length == 0 && (
                    <div className="p-3">No columns match the search text.</div>
                )}
                {!props.searchFilter && usedColumns.length == 0 && unusedColumns.length == 0 && (
                    <div className="p-3">No columns? Make sure you've built this <Link to={`/node/${props.sourceNodeId}/config`}>upstream node</Link>.</div>
                )}
            </div>
            
        </Collapse>

    </FieldContainer>
}

interface DataSourceJoinPathsWrapperProps {
    rootSourceNodeId: string;
    usedColumns: {
        [nodeId: string]: string[];
    };
    searchFilter?: string;
    onDropSourceColumn: (sourceNodeId: string, sourceColumn: PipelineNodeField, targetFieldId: string, joinPath?: NodeFieldJoinPath[]) => any;
    onAddAllColumns: (sourceNodeId: string, sourceColumns: PipelineNodeField[], joinPath: NodeFieldJoinPath[]) => any;
    onShowColumnStats: (nodeId: string, column: PipelineNodeField) => any;


}

const DataSourceJoinPathsWrapper = (props: DataSourceJoinPathsWrapperProps) => {
    const joinPaths = useJoinPaths(props.rootSourceNodeId);

    const getUsedColumnsForNodeId = (nodeId: string) => {
        if (props.usedColumns.hasOwnProperty(nodeId)) {
            return props.usedColumns[nodeId];
        }
        return [];
    }
    if (!joinPaths.data) {
        return <></>
    }
    return <>
        {joinPaths.data.map(jp => {
            if (jp[jp.length - 1].object_type !== 'NODE') {
                return <>
                    Error: last element in join path is not a node!
                </>
            }
            return <DataSourceDraggableColumnPicker
                sourceNodeId={jp[jp.length - 1].object_id}
                onDropSourceColumn={(sourceColumn: PipelineNodeField, targetFieldId: string) => {
                    props.onDropSourceColumn(jp[jp.length - 1].object_id, sourceColumn, targetFieldId, jp)
                }}
                searchFilter={props.searchFilter}
                
                onAddAllColumns={(columnsToAdd: PipelineNodeField[],) => {
                    props.onAddAllColumns(jp[jp.length - 1].object_id, columnsToAdd, jp)
                }}
                onShowColumnStats={(column: PipelineNodeField) => {
                    props.onShowColumnStats(jp[jp.length - 1].object_id, column);
                }}
                joinPathBase={jp}
                usedColumns={getUsedColumnsForNodeId(jp[jp.length - 1].object_id)}
            />
        })}
    </>
}


interface Props {
    targetNode: PipelineNode;
    dataSourceSelectionMode: 'ONLY_UPSTREAM_NODES' | 'ALL_RELATED';
    onChangeUpstreamNodeIds: (newUpstreamNodeIds: string[]) => any;
    onAddNewSource: () => any;
    disableAddingMultipleSources?: boolean;
    onDrop: (sourceNodeId: string, sourceColumn: PipelineNodeField, targetFieldId: string, joinPath?: NodeFieldJoinPath[]) => any;
    onUpsert: (sourceNodeId: string, sourceColumns: PipelineNodeField[], joinPath?: NodeFieldJoinPath[]) => any;
    onShowColumnStats: (nodeId: string, column: PipelineNodeField) => any;
}

const PipelineNodeFieldMappingDataSourceList = (props: Props) => {
    
    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const usedColumnsByNodeId = useMemo(() => {
        const used: {
            [nodeId: string]: string[]
        } = {};


        props.targetNode.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.source_node_id) {
                    if (!used.hasOwnProperty(mo.source_node_id)) {
                        used[mo.source_node_id] = [];
                    }
                    
                    used[mo.source_node_id].push(getUsedFieldKey(mo.attribute_id as string, mo.source_node_id, mo.join_path));
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (!used.hasOwnProperty(so.source_node_id as string)) {
                            used[so.source_node_id!] = [];
                        }
                        used[so.source_node_id!].push(getUsedFieldKey(so.attribute_id as string, so.source_node_id as string, so.join_path));
                    });
                }
            });
        });
        return used;
    }, [props.targetNode.fields]);

    const onRemoveSource = useCallback((sourceNodeId: string) => {
        confirmation({
            header: 'Disconnect Data Source?',
            message: 'Are you sure you want to disconnect this data source from this node?',
            confirmationButtonVariant: 'danger',
            confirmationButtonText: 'Disconnect',
            onClose: () => {

            },
            onConfirm: async () => {
                const newUpstream = [...props.targetNode.upstream_node_ids].filter(id => id != sourceNodeId);
                props.onChangeUpstreamNodeIds(newUpstream);
            }
        })
        
    }, [props.onChangeUpstreamNodeIds, props.targetNode.upstream_node_ids]);

    const inDraftMode = useIsInDraftMode();

    const getUsedColumnsForNodeId = (nodeId: string) => {
        if (usedColumnsByNodeId.hasOwnProperty(nodeId)) {
            return usedColumnsByNodeId[nodeId];
        }
        return [];
    }

    return <>
        <div className="d-flex center-vertically mb-1">
            <Form.Label className="flex-1 mb-0">Source Nodes</Form.Label>
            <button className="icon-button" disabled={(props.disableAddingMultipleSources && props.targetNode.upstream_node_ids.length >= 1)} onClick={props.onAddNewSource}>
                    <i className="mdi mdi-plus-circle"></i> Connect New
                </button>
        </div>
        
        {props.dataSourceSelectionMode == 'ALL_RELATED' && (
            <Form.Group className="mb-2 pb-2 border-bottom">
                <Form.Label>Select root node</Form.Label>
                <PipelineNodeSelector
                    disabled={!inDraftMode}
                    selectedId={props.targetNode.upstream_node_ids.length > 0 ? props.targetNode.upstream_node_ids[0] : ''}
                    onSelect={(selected: PipelineNode | undefined) => {
                        if (selected) {
                            props.onChangeUpstreamNodeIds([selected.id as string]);
                        } else {
                            props.onChangeUpstreamNodeIds([]);
                        }
                    }}
                />
                <Form.Text>Using this node, Pliable will determine all available sources for your datamart using your facts, dimensions, and relationships.</Form.Text>
            </Form.Group>
        )}
        <SubHeaderContainer>
            <input type="text" className="form-control round-input" placeholder="Search source columns" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
        </SubHeaderContainer>
        {props.dataSourceSelectionMode == 'ALL_RELATED' && (
            <>
                
                {props.targetNode.upstream_node_ids.length >= 0 && (
                    <DataSourceJoinPathsWrapper
                        onAddAllColumns={props.onUpsert}
                        rootSourceNodeId={props.targetNode.upstream_node_ids[0]}
                        usedColumns={usedColumnsByNodeId}
                        searchFilter={searchTerm}
                        onDropSourceColumn={props.onDrop}
                        onShowColumnStats={props.onShowColumnStats}

                    />
                )}
            </>
        )}
        {props.dataSourceSelectionMode == 'ONLY_UPSTREAM_NODES' && (
            <>
                {props.targetNode.upstream_node_ids.map(uid => (
                    <DataSourceDraggableColumnPicker
                        sourceNodeId={uid}
                        onDropSourceColumn={(sourceColumn: PipelineNodeField, targetFieldId: string) => {
                            props.onDrop(uid, sourceColumn, targetFieldId, undefined)
                        }}
                        searchFilter={searchTerm}
                        onAddAllColumns={(columnsToAdd: PipelineNodeField[]) => {
                            props.onUpsert(uid, columnsToAdd)
                        }}
                        onShowColumnStats={(column: PipelineNodeField) => {
                            props.onShowColumnStats(uid, column);
                        }}
                        usedColumns={getUsedColumnsForNodeId(uid)}
                        allowRemove
                        onRemoveSource={() => {
                            onRemoveSource(uid);
                        }}
                    />
                ))}
            </>
        )}

    </>
}

export default PipelineNodeFieldMappingDataSourceList;

