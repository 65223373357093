import AsyncButton from "@components/button/AsyncButton.component";
import Dropdown from "@components/form/Dropdown.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import VisualizationChart from "@components/pipelineNodes/VisualizationChart.component";
import { ChartType, Visualization, VisualizationsORM, saveVisualization, useVisualizationsForPipelineNode } from "@models/visualization";
import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentInnerWithSidebar, PaneContentSidebar, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { queryClient, usePipelineNode } from "@stores/data.store";
import { Allotment } from "allotment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import styled from 'styled-components';
import { DraftOnly } from "@components/project/DraftModeRequired.component";

const ChartTypeOptions = [
    {
        label: 'Bar',
        value: 'bar'
    }, {
        label: 'Line',
        value: 'line',
    },
];

const Grid = styled.div`
display: grid;
grid-template-columns: 30% 30% 30%;
grid-column-gap: 5%;
grid-row-gap: 2rem;
`

const PipelineNodeVisualizationsPage = () => {
    const { pipelineNodeId, vizId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const vizes = useVisualizationsForPipelineNode(pipelineNodeId as string);


    
    
    return <PageStructure
        pageTitle={pipelineNode.data ? pipelineNode.data.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            }
            
        ]}
    >
        <PageContent>
            
                    <Pane>
                        <PipelineNodeSubnav
                            pipelineNodeId={pipelineNodeId as string}
                        >
                            <DraftOnly>
                                <Link className="btn btn-primary" to={`/node/${pipelineNodeId}/visualizations/new`}>New Visualization</Link>

                            </DraftOnly>
                        </PipelineNodeSubnav>
                        <PageContentInner hasHeader>
                            <div className="">
                                {vizes.data && vizes.data.length == 0 && <p>
                                    No visualizations yet.    
                                </p>}
                                <Grid>
                                    
                                    {vizes.data && vizes.data.map(v => {
                                        return <div className="shadow-box p-2">
                                            <h3><Link to={`/node/${pipelineNodeId}/visualizations/${v.id}`}>{v.name || '<NO NAME>'}</Link></h3>
                                            <VisualizationChart compact visualizationId={v.id as string}/>
                                        </div>
                                    })}
                                </Grid>
                            </div>
                            
                            
                            
                        </PageContentInner>
                
                    </Pane>
                
            
        </PageContent>
    </PageStructure>

}

export default PipelineNodeVisualizationsPage;