import PipelineNodeIcon, { DashboardIcon } from '@components/pipelineNodes/PipelineNodeIcon.component';
import { Dashboard } from '@models/dashboard';
import { PublishPlan } from '@models/draftVersion';
import { PipelineNode, PipelineNodeRelationship } from '@models/pipelineNode';
import { ReportBuilderDimension, ReportBuilderMeasure } from '@models/reportBuilder';
import { SourceRecordType } from '@models/source';
import { Visualization } from '@models/visualization';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { Component, useCallback, useState } from 'react';
import { Badge, Form } from 'react-bootstrap';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


interface Props {
    loading?: boolean;
    disabled?: boolean;
    plan?: PublishPlan;
    message: string;
    onMessageChange: (m: string) => void;
}

const Container = styled.div`

.model-changes {
    padding: 5px 5px;
}

h3 {
    text-align: center;
}

.diff-table{
    height: 50vh;
}

.record-item {
    margin-bottom: 5px;
    padding: 3px;
    border-radius: 5px;
}
`

const PublishPlanChanges = (props: Props) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const [status, setStatus] = useState('');

    const [focusRecord, setFocusRecord] = useState<any>(undefined);
    const [focusDiff, setFocusDiff] = useState<any>(undefined);

    const hideDiff = useCallback(() => {
        setFocusRecord(undefined);
        setFocusDiff(undefined);
    }, []);

    const showDiff = useCallback((node: any, diff: string) => {
        setFocusRecord(node);
        setFocusDiff(diff);
    }, []);



    if (!props.plan?.has_any_changes) {
        return <p>It looks like there are no changes to publish</p>
    }

    const renderDimension = (n: ReportBuilderDimension, bg_style: string, showDiff?: any) => {
        return  <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                <div className="col-3">

                </div>
                <div className="col-9">
                    <small>Dimension</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                    <h4 className="col-12 text-truncate">
                        {n.name}
                    </h4>
                </div>
            </div>
        </div>
    }

    const renderMeasure = (n: ReportBuilderMeasure, bg_style: string, showDiff?: any) => {
        return  <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                <div className="col-3">

                </div>
                <div className="col-9">
                    <small>Measure</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                    <h4 className="col-12 text-truncate">
                        {n.name}
                    </h4>
                </div>
            </div>
        </div>
    }
    
    const renderPipelineNode =  (n: PipelineNode, bg_style: string, showDiff?: any) => {
        return  <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                    <div className="col-3 ">
                        <PipelineNodeIcon node={n}/>
                    </div>
                    <div className="col-9">
                        <small>{n.node_type}</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                        <h4 className="col-12 text-truncate">
                            <Link to={`/node/${n.id}/config`} target="_blank">{n.name}</Link>
                        </h4>
                    </div>
            </div>
        </div>
    } 

    const renderPipelineNodeRelationship =  (n: PipelineNodeRelationship, bg_style: string, showDiff?: any) => {
        return <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                <div className="col-3">
                    <DashboardIcon bgColor={'purple'} icon={"mdi mdi-transit-connection-variant"}/>
                </div>
                <div className="col-9">
                    <small>Relationship</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                    <h4 className="col-12 text-truncate">
                        <Link to={`/node/${n.parent_node_id}/relationships/${n.id}`} target="_blank">{n.name || 'Unnamed Relationship'}</Link>
                    </h4>
                </div>
                
                
            </div>
        </div>
    } 

    const renderVisualization =  (n: Visualization, bg_style: string, showDiff?: any) => {
        return <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                <div className="col-3">
                    <DashboardIcon bgColor={'purple'} icon={"mdi mdi-chart-bar"}/>
                </div>
                <div className="col-9">
                    <small>Visualization</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                    <h4 className="col-9 text-truncate">
                        <Link to={`/node/${n.pipeline_node_id}/visualizations/${n.id}`} target="_blank">{n.name || 'Unnamed Visualization'}</Link>
                    </h4>
                </div>
            </div>
        </div>
    } 

    const renderDashboard =  (n: Dashboard, bg_style: string, showDiff?: any) => {
        return <div className={`col-12 record-item ${bg_style}`}>
            <div className="row">
                <div className="col-3">
                    <DashboardIcon bgColor={'purple'} icon={"mdi mdi-view-dashboard"}/>
                </div>
                <div className="col-9">
                    <small>Dashboard</small> {showDiff && <a role="button" title="View Difference" onClick={showDiff}><i className="mdi mdi-vector-difference"></i></a> }
                    <h4 className="col-9 text-truncate">
                        <Link to={`/dashboard/${n.id}`} target="_blank">{n.name || 'Unnamed Dashboard'}</Link>
                    </h4>
                </div>
            </div>
        </div>
    } 

    

    return (
        <Container>
        <p>This will publish everything you have in develop mode into production, which may affect reports or other nodes your stakeholders depend on.</p>
        <div className='row'>
            <Form.Group className="mb-3">
                <Form.Label>Message to include with publish:</Form.Label>
                <Form.Control as="textarea" onChange={(e) => props.onMessageChange(e.target.value)} autoFocus value={props.message} placeholder='add your message here' />
            </Form.Group>
        </div>
        <div className='diff-table'>
            <Pane><PaneContent>
                {focusDiff && <div className='row'>
                    <div className='col-12'>
                            <h2>
                                {focusRecord?.name || 'Unnamed Record'} - Diff <a role="button" className="float-right" onClick={() => hideDiff()}>X</a>
                            </h2>
                            
                    </div>
                    <div className='col-12'>
                            <CopyBlock
                                text={JSON.stringify(focusDiff, null, 2) as string}
                                language="json"
                                showLineNumbers={false}
                                theme={atomOneLight}
                                codeBlock={true}
                            />
                    </div>
                </div>}

                {!focusDiff && <>
                    <div className="row justify-content-center">
                    
                        {props.plan.total_created > 0 && <div className='col-4 model-changes'>
                            <h3>Create <Badge pill bg="pliable">{props.plan.total_created}</Badge></h3>

                            {props.plan.diff.PipelineNode.created.map((r, idx) => renderPipelineNode(r, 'alert-warning'))}
                            {props.plan.diff.PipelineNodeRelationship.created.map((r) => renderPipelineNodeRelationship(r, 'alert-warning'))}
                            {props.plan.diff.Visualization.created.map((r) => renderVisualization(r, 'alert-warning'))}
                            {props.plan.diff.Dashboard.created.map((r) => renderDashboard(r, 'alert-warning'))}
                            {props.plan.diff.Dimension.created.map((r) => renderDimension(r, 'alert-warning'))}
                            {props.plan.diff.Measure.created.map((r) => renderMeasure(r, 'alert-warning'))}
                        </div>}
                        {props.plan.total_updated > 0 && <div className='col-4 model-changes'>
                            <h3>Update <Badge pill bg="info">{props.plan.total_updated}</Badge></h3>
                            
                            {props.plan.diff.PipelineNode.updated.map((r, idx) => renderPipelineNode(r, 'alert-info', () => showDiff(r, props.plan!.diff.PipelineNode.updated_diff[idx])))}
                            {props.plan.diff.PipelineNodeRelationship.updated.map((r, idx) => renderPipelineNodeRelationship(r, 'alert-info', () => showDiff(r, props.plan!.diff.PipelineNodeRelationship.updated_diff[idx])))}
                            {props.plan.diff.Visualization.updated.map((r, idx) => renderVisualization(r, 'alert-info', () => showDiff(r, props.plan!.diff.Visualization.updated_diff[idx])))}
                            {props.plan.diff.Dashboard.updated.map((r, idx) => renderDashboard(r, 'alert-info', () => showDiff(r, props.plan!.diff.Dashboard.updated_diff[idx])))}
                            {props.plan.diff.Dimension.updated.map((r, idx) => renderDimension(r, 'alert-info', () => showDiff(r, props.plan!.diff.Dimension.updated_diff[idx])))}
                            {props.plan.diff.Measure.updated.map((r, idx) => renderMeasure(r, 'alert-info', () => showDiff(r, props.plan!.diff.Measure.updated_diff[idx])))}


                        </div>}
                        {props.plan.total_deleted > 0 && <div className='col-4 model-changes alert-danger'>
                            <h3>Delete <Badge pill bg="danger">{props.plan.total_deleted}</Badge></h3>
                            
                            {props.plan.diff.PipelineNode.deleted.map((r) => renderPipelineNode(r, 'alert-danger'))}
                            {props.plan.diff.PipelineNodeRelationship.deleted.map((r) => renderPipelineNodeRelationship(r, 'alert-danger'))}
                            {props.plan.diff.Visualization.deleted.map((r) => renderVisualization(r, 'alert-danger'))}
                            {props.plan.diff.Dashboard.deleted.map((r) => renderDashboard(r, 'alert-danger'))}
                            {props.plan.diff.Dimension.deleted.map((r) => renderDimension(r, 'alert-danger'))}
                            {props.plan.diff.Measure.deleted.map((r) => renderMeasure(r, 'alert-danger'))}
                        </div>}
                    </div>
                </>
                }
            </PaneContent></Pane>
        </div>
        </Container>
    )
}

export default PublishPlanChanges;

