import Toast from 'react-bootstrap/Toast';
import { Component, ReactNode } from 'react';
import { toast as toastify } from 'react-toastify';



export default function toast(color: string, title: string, message: string, autoClose?: number) {
    const toastComponent = <>
        <Toast.Header className={'text-white bg-' + color}>
        <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
        />
        <strong className="me-auto">{title}</strong>
        {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body className="text-muted font-13">
        {message}
        </Toast.Body>
    </>;

    toastify(toastComponent, {
        closeButton: false,
        autoClose: autoClose ? autoClose : (color == 'danger' ? 10000 : 5000),
        hideProgressBar: true,
    });
}

