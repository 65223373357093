import { Card, Form, Spinner, Navbar, Row, Col } from "react-bootstrap";
import { useCallback, useState, useEffect, useMemo } from "react";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import ApiService, { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@frontegg/react";
import TrackingService, { Events } from "@services/tracking.service";


const TERMS_LINK = "https://www.pliable.co/terms-trial";
const PP_LINK = "https://www.pliable.co/privacy-policy";

const Styles  = styled.div`
    h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    a {
        color: rgb(60, 74, 90);
    }

    .card {
        color: rgb(60, 74, 90);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: auto;
        box-sizing: border-box;
        padding: 32px;
        border: none;
        border-radius: 24px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(157, 168, 189, 0.17) 0px 4px 24px;
        padding: 30px;
    }

    .existing-account {
        text-align: center;
        margin: 5px;
        margin-bottom: 15px;
    }

    .signup-info{
        font-size: 1.1em;
        padding-left: 15px;
        padding-right: 25px;
    }
`

const EmbeddedStyles  = styled.div`
    background-color: transparent;
`

const AccountSignup = () => {
    const host = window.location.protocol + "//" + window.location.host;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const authState = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();

    const isEmbedded = searchParams.get('embedded') == 'true';
    const useTemplate = searchParams.get('useTemplate') || '';
    const inviteCodeRequired = searchParams.get('inviteCodeRequired') == 'true';

    const [saving, setSaving] = useState(false);

    const [accountName, setAccountName] = useState('');
    const [adminFullName, setAdminFullName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [inviteCode, setInviteCode] = useState('');

    const [creationResult, setCreationResult] = useState(null);
    const [acceptedTsandCs, setAcceptedTsandCs] = useState(false);

    useEffect(() => {
        if(authState && authState.isAuthenticated) {
            // user is logged in, redirect to dashboard
            if(isEmbedded){
                window.top!.location.href = host;
            }else{
                navigate("/");
            }
            
        }

    }, [authState]);

    useEffect(() => {
        const registredUser = searchParams.get('registredUser');
        if(registredUser) {
            // user is logged in, redirect to dashboard
            setCreationResult({
                'admin_email':registredUser
            } as any);
        }
    }, [searchParams]);

    const createAccount = useCallback(async () => {
        setSaving(true);
        try {
            const queryStr = new URLSearchParams({
                "utm_source": searchParams.get('utm_source') || '',
                "utm_medium": searchParams.get('utm_medium') || '',
                "utm_campaign": searchParams.get('utm_campaign') || '',
                "utm_content": searchParams.get('utm_content') || '',
                "utm_term": searchParams.get('utm_term') || '',
            }).toString();
            const signupFields = {
                name: accountName,
                admin_fullname: adminFullName,
                admin_email: adminEmail,
                use_template: useTemplate,
                invite_code: inviteCode
            };
            const createResult = await ApiService.getInstance().request('POST', `/tenant/create?${queryStr}`, signupFields) as any;
            
            if (!!createResult.error) {
                toast('danger', 'Error', createResult.error);
            }else{
                TrackingService.track_event(Events.USER_SIGNUP, signupFields);
                setTimeout(() => {
                    // redirect to thank you page
                    const redirectUrl = "https://www.pliable.co/signup-thank-you";
                    if(isEmbedded){
                        window.top!.location.href = redirectUrl;
                    }else{
                        window.location.href = redirectUrl;
                    }
                }, 400);
                
            }
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [accountName, adminFullName, adminEmail, inviteCode]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('signup');
        createAccount();
    }, [executeRecaptcha, accountName, adminFullName, adminEmail, createAccount]);

    

    const toggleTsAndCs = useCallback(() => {
        setAcceptedTsandCs(!acceptedTsandCs);
    }, [acceptedTsandCs])

    const inviteCodeValid = useMemo(() => {
        if (inviteCodeRequired == false) {
            return true;
        }

        return ["gk2024", "2024beta"].includes(inviteCode);
    }, [inviteCode, inviteCodeRequired])


    if(creationResult) {
        return (
            <div className="container">
            <Styles>
            <div className="row flex flex-column justify-content-center align-items-center">
                <div className="col-sm-6 col-md-2 mt-5">
                    <img src="https://fronteggprodeustorage.blob.core.windows.net/public-vendor-assets/692d0655-fa36-4d1a-8dde-8d45c64bf1ef/assets/logo-1f556061-f339-4c22-96ec-61f841731dfe.png" alt="company-logo" data-test-id="company-logo" style={{height: '50px'}} />
                </div>
                <div className="col-sm-12 col-md-4 mt-5">
                    <Card style={{textAlign: 'center'}}>
                        Account activation sent to {creationResult['admin_email']}
                    </Card>
                </div>
            </div>
            </Styles>
            </div>
        )
    }

    const form = <EmbeddedStyles>
        <Form.Group className="mb-2">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={accountName} onChange={(e) => setAccountName(e.target.value)}>

                            </Form.Control>
                            <Form.Text>
                                (ex. Acme Co.)
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>
                                Admin Full Name
                            </Form.Label>
                            <Form.Control value={adminFullName} onChange={(e) => setAdminFullName(e.target.value)}>

                            </Form.Control>
                            <Form.Text>
                                (ex. John Smith)
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>
                                Admin Email
                            </Form.Label>
                            <Form.Control type="email" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)}>

                            </Form.Control>
                            <Form.Text>
                                (ex. j.smith@test.com)
                            </Form.Text>
                        </Form.Group>

                        { inviteCodeRequired && <Form.Group className="mb-2">
                                <Form.Label>
                                    Invite Code
                                </Form.Label>
                                <Form.Control type="text" value={inviteCode} onChange={(e) => setInviteCode(e.target.value)}></Form.Control>
                            </Form.Group>
                        }
                        

                        <Form.Group className="mb-3 mt-3">
                            <Form.Check>
                                <Form.Check.Input onClick={toggleTsAndCs} type="checkbox" checked={acceptedTsandCs} />
                                <Form.Check.Label><span onClick={toggleTsAndCs}>Accept Terms & Conditions</span></Form.Check.Label>
                                <br />
                                <Form.Text>
                                    <a href={TERMS_LINK} target="_blank" title="View Terms & Conditions">View Terms</a> - <a href={PP_LINK} target="_blank" title="View Privacy Policy">View Privacy</a>
                                </Form.Text>
                            </Form.Check>
                        </Form.Group>
                        <div>
                            <button onClick={() => handleReCaptchaVerify()} disabled={!accountName || !adminEmail || !acceptedTsandCs || !inviteCodeValid} className="btn btn-pliable">
                                Signup {saving && <Spinner size="sm"/>}
                            </button>
                        </div>
    </EmbeddedStyles>;

    if (isEmbedded) {
        return form;
    }

    return (
        <div className="container">
        <Styles>
                
                <div className="row d-flex justify-content-center">
                    <div className="col-sm-12 col-md-4 mt-5">
                        <h1 className="page-title">
                            Start your Free Trial
                        </h1>

                        <div className="shadow-box p-3">
                            
                        {form}
                        </div>
                        
                    </div>
                    
                </div>
        </Styles>
        </div>
    );
    
}

export default AccountSignup;