import DataModelSubnav from "@components/datamodel/DataModelSubnav.component";
import { MissionControlDataFlowDiagramManager } from "@components/missionControl/dataflow/MissionControlDataFlow";
import { recursiveGetNodesAndEdges } from "@pages/Dag.page";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent } from "@pages/PageStructure.component";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { useQuery } from "@services/url.service";
import { useMissionControlDataFlowData } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DataModelSourcesPage = () => {
    
    const data = useMissionControlDataFlowData();
    const [focusNodeId, setFocusNodeId] = useState('');

    const filteredNodes = useMemo(() => {
        if (!data.data) {
            return [];
        }

        const nodesToShow = data.data.nodes.filter((node) => {
            return ['STAGING', 'SOURCE', 'DATA_MODELING'].includes(getGroupValueForNodeType(node.data.nodeType));
        }).sort((a, b) => {
            return a.data.title.localeCompare(b.data.title);
        });

        const theNode = focusNodeId ? data.data.nodes.find(n => n.id === focusNodeId) : undefined;
        if (theNode) {
            const [nodesToUse, edgesToUse] = recursiveGetNodesAndEdges(theNode, nodesToShow, data.data.edges, [focusNodeId], [], '', true);
            nodesToUse.push(theNode);
            return nodesToUse;
        }
        return nodesToShow;

      
    }, [data.dataUpdatedAt, focusNodeId]);

    const sidebarNodes = useMemo(() => {

        if (!data.data) {
            return [];
        }

        return data.data.nodes.filter((node) => {
            return ['SOURCE'].includes(getGroupValueForNodeType(node.data.nodeType));
        }).sort((a, b) => {
            return a.data.title.localeCompare(b.data.title);
        });
    }, [data.dataUpdatedAt]);

    const query = useQuery();

    useEffect(() => {
        const focusNodeId = query.get('focusNodeId');
        setFocusNodeId(focusNodeId ? focusNodeId: '');

    }, [query]);

    const navigate = useNavigate();;
    const changeFocusNode = useCallback((id: string) => {
        navigate(`?focusNodeId=${id}`);
    }, [navigate]);

    return <PageStructure>
        <PageContent>
            <DataModelSubnav />
            <PageContentInner hasHeader noScroll>
                <div className="row" style={{height: '100%'}}>
                    <div className="col-3 pe-3">
                        <Pane>
                            <PaneContent>
                                <h2>Sources</h2>
                                <Link to="/node/new?selectedNodeType=SOURCE" className="btn btn-sm btn-outline-primary mb-3">New Source Table</Link> <Link to="/node/new?selectedNodeType=SOURCE_FILE" className="btn btn-sm btn-outline-primary mb-3">New Source File</Link>
                                <ul className="list-group">
                                    {sidebarNodes.map((node) => {
                                        return <li className={`list-group-item list-group-item-action ${focusNodeId == node.id ? 'active': ''}`} onClick={() => {
                                            changeFocusNode(node.id)
                                        }}>
                                            <strong>{node.data.title}</strong>
                                            {node.data.description && <div className="small text-muted">{node.data.description}</div>}
                                        </li>
                                    })}
                                </ul>
                                <div className="mb-3"></div>

                            </PaneContent>
                        </Pane>
                    </div>
                    <div className="col-9">
                        <div className="card" style={{height: 'calc(100% - 5rem)'}}>
                            <MissionControlDataFlowDiagramManager
                                nodeData={filteredNodes}
                                edgeData={data.data?.edges || []}
                                focusNodeId={focusNodeId}
                                onDeleteEdge={() => {

                                }}
                                onClickNode={() => {

                                }}
                                onEditEdge={() => {

                                }}
                                onClickEdgeStatusIndicator={() => {
                                    
                                }}
                                onToggleNodeLineage={() => {

                                }}
                            />
                        </div>
                        
                    </div>
                </div>
            
            </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default DataModelSourcesPage;