import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useAuthActions } from "@frontegg/react";
import Select from 'react-select';
import AuthService from "../../services/auth/auth.service";
import AsyncButton from '@components/button/AsyncButton.component';
import { queryClient } from '@stores/data.store';
import { useNavigate } from 'react-router-dom';
import TrackingService, {Events} from '@services/tracking.service';


const TenantPicker = () => {
    const navigate = useNavigate();
    const { switchTenant } = useAuthActions();
    const [showPicker, setShowPicker] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [switching, setSwitching] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOptions] = useState<any>(null);
    const tenantName = AuthService.getInstance().getTenantName();


    const beginSwitch = async () => {

        setShowPicker(true);
        setLoadingOptions(true)


        const tenants = await AuthService.getInstance().getUserTenants();

        setOptions(tenants.map((t:any) => {return {value: t.tenantId, label: t.name}}));
        setLoadingOptions(false);
        TrackingService.track_event(Events.USER_TENANT_SWITCH_CLK);
    }


    const cancelSwitch = () => {
        setShowPicker(false);
    }


    const doSwitch = () => {
        setSwitching(true);
        switchTenant({ tenantId: selectedOption.value });
        queryClient.removeQueries();
        navigate("/");
        TrackingService.track_event(Events.USER_TENANT_SWITCHED, {
            new_tenant_uuid: selectedOption.value
        });
    }


    const tenantIds = AuthService.getInstance().authState?.user?.tenantIds;

    if (!tenantIds || tenantIds?.length < 2) {
        return <></>;
    }

    return (
        <>
            <Modal show={showPicker == true} onHide={() => cancelSwitch()}>
                <Modal.Header closeButton>
                    <Modal.Title>Switch Tenant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select 
                        isLoading={loadingOptions}
                        placeholder="Choose a Tenant" 
                        options={options}
                        onChange={(op) => setSelectedOptions(op)}
                        value={selectedOption}
                        />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => cancelSwitch()}>
                        Cancel
                    </Button>
                    <AsyncButton
                        icon="mdi mdi-content-save"
                        variant="primary"
                        text={`Switch`}
                        loading={switching}
                        onClick={()=> doSwitch()}
                    />
                </Modal.Footer>
            </Modal>
            <a role="button"  onClick={beginSwitch}>{tenantName}</a>
        </>
    )
    
}


export default TenantPicker;