import DangerStatusIndicator from '@components/statusIndicators/DangerStatusIndicator.component';
import Warning from '@components/statusIndicators/Warning.component';
import WarningStatusIndicator from '@components/statusIndicators/WarningStatusIndicator.component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore, getBezierPath, EdgeProps, Position, BaseEdge, EdgeLabelRenderer, Node, XYPosition, internalsSymbol } from 'reactflow';
import styled from 'styled-components';

export interface MissionControlEdgeButton {
    label: string;
    icon: string;
    onClick: () => void;
}

export interface MissionControlEdgeProps {
    buttons?: MissionControlEdgeButton[];
    onClick?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
    fade?:  boolean;
    type?: 'NODE_RELATIONSHIP' | 'MAPPING' | 'REPORT';
    showActions?: boolean;
    executionStatus?: string;
    onClickStatusIndicator?: () => void;
}

const EdgeActions = styled.div`
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
border-radius: 5px
z-index: 2000;
position: relative;
`

const EdgeButton = styled.button`
height: 40px;
font-family: 'POPPINS';
background: rgba(15, 14, 49, 1);
color: rgba(255, 255, 255, 1);
padding: 0px;
margin: 0px;
text-align: center;
font-size: 18px;
position: relative;
line-height: 32px;
padding: 4px 12px;
border: none;

&.drag-handle {
    padding: 4px 2px;
}

&:hover {
    cursor: pointer;
    background: rgba(15, 14, 49, .85);
}

&:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

&:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

&.confirm {
    background: #EA4335;
}
`


const EdgePathContainer = styled.g`
    &.faded {
        opacity: 20%;
    }



    .react-flow__edge-path {
        stroke-width: 5;
        stroke: #FF9F00;
    }

    .react-flow__edge-path.active {
        stroke-dasharray: 50;
        animation: 5s linear 0s infinite dash;
      }
      
      @keyframes dash {
          to {
            stroke-dashoffset: -1250;
          }
      }

   // Select the second path aka the invisible interactive 
   
    path.react-flow__edge-interaction {
        z-index: 5000;
        pointer-events: all; 

        // disabling to stop flicker
        // &:hover + path{
        //     stroke-width: 10;
        // }

        .buttons-container {
            display: none;
        }

        &:hover {
            .buttons-container: display: block;
        }


    }
`

const AddNodeButton = styled.g`
    visibility: visible;
`

const AddNodeText = styled.g``

const Rect = styled.rect``


/**
 * Calulates the x/y coordinates of the exact center of the given node.
 * 
 * @param node
 */
function getNodeCenter(node: Node): XYPosition {
    return {
        x: node.positionAbsolute!.x + node.width! / 2,
        y: node.positionAbsolute!.y + node.height! / 2,
    };
}


/**
 * Calculates the x/y coordinates to connect the edge to on the sourceNode,
 * assuming there are 4 handles on the node (north, south, east, west) all centered
 * verticaly or horizontally.
 * 
 * @param sourceNode The node the connection is coming from
 * @param targetNode The node the connection is going to
 */
function getCardinalPositionForNodeConnection(sourceNode: Node, targetNode: Node) {
    const sourceCenter = getNodeCenter(sourceNode);
    const targetCenter = getNodeCenter(targetNode);

    const horizontalDiff = Math.abs(sourceCenter.x - targetCenter.x);
    const verticalDiff = Math.abs(sourceCenter.y - targetCenter.y);

    let position;

    // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
    if (horizontalDiff > verticalDiff) {
        position = sourceCenter.x > targetCenter.x ? Position.Left : Position.Right;
    } else {
        // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
        position = sourceCenter.y > targetCenter.y ? Position.Top : Position.Bottom;
    }

    // Now that we know which handle to use, get the position of the handle to use on
    // the source node
    const handlePosition = getHandleCoordsByPosition(sourceNode, position);
    return [handlePosition.x, handlePosition.y, position];
}

/**
 * Returns the X/Y position of the handle at position handlePosition for the given node.
 * This assumes there is only one handle in each position (top, right, bottom, left)
 * @param node 
 * @param handlePosition 
 * @returns 
 */
export function getHandleCoordsByPosition(node: Node, handlePosition: Position): XYPosition {
    // all handles are from type source, that's why we use handleBounds.source here

    //   @ts-ignore
    const handle = node[internalsSymbol].handleBounds.source.find(
        //   @ts-ignore
        (h) => h.position === handlePosition
    );

    if (!handle) {
        console.log('handle not found:', node, handlePosition);
        return {x: 0, y: 0};
    }
  
    let offsetX = handle!.width / 2;
    let offsetY = handle!.height / 2;

  
    const x = node.positionAbsolute!.x + handle!.x + offsetX;
    const y = node.positionAbsolute!.y + handle!.y + offsetY;
  
    return {x, y};
}

function getHandleCoordsById(node: Node, handleId: string): XYPosition {
    // @ts-ignore
    const handle = node[internalsSymbol]?.handleBounds.source.find((h) => h.id === handleId);


    
    return {
        x: node.positionAbsolute!.x + handle!.x + (handle!.width / 2),
        y: node.positionAbsolute!.y + handle!.y + (handle!.height / 2),
    };
}



export const MissionControlCardinalEdge = ({ id, source, target, markerEnd, style, data, interactionWidth }: EdgeProps<MissionControlEdgeProps>) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    const [sx, sy, sourcePos] = getCardinalPositionForNodeConnection(sourceNode!, targetNode!);
    const [tx, ty, targetPos] = getCardinalPositionForNodeConnection(targetNode!, sourceNode!);

    useEffect(() => {
        if (data?.showActions == false) {
            setConfirmDelete(false);
        }
    }, [data?.showActions])

    const onClickEdge = useCallback(() => {
        if (data?.onClick) {
            data.onClick();
        }
    }, [data?.onClick])

    const onEditEdgeClick = useCallback((e: any) => {
        if (data?.onEdit) {
            data.onEdit();
        }
    }, [data?.onEdit])

    const onDeleteEdgeClick = useCallback((e: any) => {
        if (data?.onDelete) {
            e.preventDefault();
            e.stopPropagation();
            setConfirmDelete(true);
        }
    }, [data?.onDelete])

    const doDelete = useCallback((e: any) => {
        if (data?.onDelete) {
            data.onDelete()
        }
    }, [data?.onDelete]);



    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX: sx as number,
        sourceY: sy as number,
        sourcePosition: sourcePos as Position,
        targetPosition: targetPos as Position,
        targetX: tx as number,
        targetY: ty as number,
    });

    const className = useMemo(() => {
        let classes = [];
    
        if(data?.executionStatus === 'EXCLUDED') {
            classes.push('faded')
        }
    

        return classes.join(' ');
      }, [data]);

    const strokeColor = useMemo(() => {
        console.log('Stroke color:', data?.type);
        if (data?.type == 'NODE_RELATIONSHIP') {
            return '#68357A';
        } else if (data?.type == 'REPORT') {
            return '#1BD77D';
        }
        return '#FF9F00';
    }, [data?.type])

    return (
        <EdgePathContainer onClick={onClickEdge} className={className}>
        
        {interactionWidth && (
            <path
            d={edgePath}
            fill="none"
            strokeOpacity={0}
            strokeWidth={interactionWidth}
            className="react-flow__edge-interaction"
            />
        )}
        <path
          id={id}
          className={`react-flow__edge-path ${data?.executionStatus == 'ACTIVE' ? 'active': ''}`}
          d={edgePath}
          style={{'stroke': strokeColor}}
        />

        <circle cx={sx} cy={sy} r={7} fill={strokeColor}/>
        <circle cx={tx} cy={ty} r={7} fill={strokeColor}/>
            
            <EdgeLabelRenderer>

                
                {data && data.showActions && 
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY+55}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                            zIndex: 2000,
                        }}
                        className="nodrag nopan"
                    >
                        <EdgeActions>
                            {!confirmDelete && <>
                                <EdgeButton className="drag-handle">
                                    <i className="mdi mdi-drag-vertical"></i>
                                </EdgeButton>
                                <EdgeButton onClick={onEditEdgeClick}>
                                    <i className="mdi mdi-eye"></i> Edit
                                </EdgeButton>
                                <EdgeButton onClick={onDeleteEdgeClick}>
                                    <i className="mdi mdi-delete"></i> Delete
                                </EdgeButton>
                            </>}

                            {confirmDelete && <>
                                <EdgeButton className="drag-handle confirm">
                                    <i className="mdi mdi-drag-vertical"></i>
                                </EdgeButton>
                                <EdgeButton className="confirm">
                                    <i className="mdi mdi-delete"></i> Delete
                                </EdgeButton>
                                <EdgeButton className="confirm" onClick={() => setConfirmDelete(false)}>
                                    No
                                </EdgeButton>
                                <EdgeButton className="confirm" onClick={doDelete}>
                                    Yes
                                </EdgeButton>
                            </>}
                        </EdgeActions>
                    
                    </div>
                }
            </EdgeLabelRenderer>

        </EdgePathContainer>
    ); 


}


/**
 * Calculates the x/y coordinates to connect the edge to on the given node,
 * using the handleId as the specific target. This allows us to display a
 * connection to one of many "sub-nodes" (e.g. columns on a table)
 * 
 * @param node
 * @param handleId 
 */
function getSubHandlePositionForNodeConnection(sourceNode: Node, targetNode: Node, sourceHandleId: string) {
    // Figure out which side we start/end

}

export const MissionControlSubHandleEdge = ({ id, source, target, markerEnd, style, data, sourceHandleId, targetHandleId, interactionWidth }: EdgeProps<MissionControlEdgeProps>) => {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    const sourceCenter = getNodeCenter(sourceNode!);
    const targetCenter = getNodeCenter(targetNode!);

    let sourcePosition: Position = Position.Left;
    let targetPosition: Position = Position.Left;

    const xOverlap = Math.abs(sourceCenter.x - targetCenter.x);

    // Case 1: they overlap at least 50% on the X axis, so pick a side
    if (xOverlap < 75) {
        targetPosition = Position.Left;
        sourcePosition = Position.Left;
    } else if (sourceCenter.x < targetCenter.x) {
        sourcePosition = Position.Right;
        targetPosition = Position.Left;
    // Case #2: source node is to the right of target node
    } else if (sourceCenter.x > targetCenter.x) {
        sourcePosition = Position.Left;
        targetPosition = Position.Right;
    }
    // } else if (sourceCenter.x === targetCenter.x) {
    //     sourcePosition = Position.Left;
    //     targetPosition = Position.Left;
    //     // const radiusX = (sourceX - targetX) * 0.6;
    //     // const radiusY = 50;
    //     // const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
    //     //     targetX + 2
    //     // } ${targetY}`;
    //     //     }
    // }

    let sourceHandleToUse: string;
    let targetHandleToUse: string;

    const sourceHandlePrefix = sourceHandleId!.replace('|right', '').replace('|left', '');
    const targetHandlePrefix = targetHandleId!.replace('|right', '').replace('|left', '');

    if (sourcePosition === Position.Left) {
        sourceHandleToUse = sourceHandlePrefix + '|left';
    } else {
        sourceHandleToUse = sourceHandlePrefix + '|right';
    }

    if (targetPosition === Position.Left) {
        targetHandleToUse = targetHandlePrefix + '|left';
    } else {
        targetHandleToUse = targetHandlePrefix + '|right';
    }

    const sourceHandlePosition = getHandleCoordsById(sourceNode!, sourceHandleToUse!);
    const targetHandlePosition = getHandleCoordsById(targetNode!, targetHandleToUse!);

    let edgePath: string;
    let labelX: number;
    let labelY: number;
    // if (sourcePosition != targetPosition) {
        // Use a bezier path
        [edgePath, labelX, labelY] = getBezierPath({
            sourceX: sourceHandlePosition.x as number,
            sourceY: sourceHandlePosition.y as number,
            sourcePosition: sourcePosition,
            targetPosition: targetPosition,
            targetX: targetHandlePosition.x as number,
            targetY: targetHandlePosition.y as number,
        });
    // } else {
    //     // Use a circular path
    //     let sp: XYPosition;
    //     let tp: XYPosition;

    //     if (sourceHandlePosition.y < targetHandlePosition.y) {
    //         sp = sourceHandlePosition;
    //         tp = targetHandlePosition;
    //     } else {
    //         sp = targetHandlePosition;
    //         tp = sourceHandlePosition;
    //     }
    //     let radiusX = 50;
    //     if (xOverlap > 10) {
    //         radiusX = xOverlap * 2;
    //     }
    //     const radiusY = (sp.y - tp.y) * 0.5;
    //     edgePath = `M ${sp.x} ${sp.y} A ${radiusX} ${radiusY} 0 1 0 ${
    //         tp.x + 2
    //       } ${tp.y}`;
    // }
    
    const onClickEdge = useCallback(() => {
        if (data?.onClick) {
            data.onClick();
        }
    }, [source, target, sourceHandleId, targetHandleId]);

    let strokeColor = '#FF9F00';
    if (!!data?.fade) {
        strokeColor = '#555';
    }
    return (
        <EdgePathContainer style={{opacity: !!data?.fade ? '0.1' : '1.0'}}>
            {interactionWidth && (
            <path
                d={edgePath}
                fill="none"
                onClick={() => onClickEdge()}
                strokeOpacity={0}
                strokeWidth={interactionWidth}
                className="react-flow__edge-interaction"
                />
            )}
            <path
                
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                style={{'stroke': strokeColor, 'zIndex': '1000!important'}}
            />
            <circle cx={sourceHandlePosition.x} cy={sourceHandlePosition.y} r={7} fill={strokeColor}/>
            <circle cx={targetHandlePosition.x} cy={targetHandlePosition.y} r={7} fill={strokeColor}/>
            {data?.buttons && !data.fade && (
                <EdgeLabelRenderer>
                    <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan buttons-container"
                    >
                        <div>
                            {data.buttons.map((b, idx) => <EdgeButton key={idx} onClick={() => b.onClick()}>
                                <i className={b.icon}/>
                            </EdgeButton>)}
                            
                        </div>
                    
                    </div>
                </EdgeLabelRenderer>
            )}
            
        </EdgePathContainer>
        
    ); 
}