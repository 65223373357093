import { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";
import BackgroundService from "@services/bg.service";
import { ColumnFormatter, ColumnFormat, TableShape } from "./shared";
import { FilterConfig } from "./pipelineNode";


export interface ReportColumnPath {
    label: string;
    object_type: string;
    object_id: string;
}


export interface ReportColumn {
    id: string;
    label: string;
    path: ReportColumnPath[];
    column_name: string;
    column_selector: string;
    column_id?: string;
    datatype?: string;
    function?: string;
}

export interface ReportMetric {
    id: string;
    name: string
    aggregator?: string;
    column: ReportColumn;
    custom_sql?: string;
    window_arguments?: any[];
    window_function?: string;
    format?: ColumnFormat;

}

export interface ReportSort {
    metric_id: string;
    direction: 'DESC' | 'ASC';
}

export interface DataMart extends BaseModel {
    name: string;
    sql: string;
    description: string;
    table_name?: string;
    shape?: TableShape; 
    report_type?: string;
    one_row_per?: ReportColumn[];
    breakdown?: ReportMetric[];
    metrics?: ReportMetric[];
    total_records?: number;
    last_build_completed?: Date;
    default_sort?: ReportSort[];
    include_in_snapshots: boolean;
    filters?: FilterConfig;
}


export class _DataMartORM extends ORM<DataMart>{
    public async getData(datamartId: string) {
        const result = await this.apiService.request('GET', `/${this.endpoint}/${datamartId}/data`) as ListRecordsResponse<any>;
        return result.records;
    }

    public async getShape(datamartId: string) {
        const result = await this.apiService.request('GET', `/${this.endpoint}/${datamartId}/shape`) as SingleRecordResponse<any>;
        return result.record;
    }

    public async getDownloadUrl(datamartId: string) {
        const result = await this.apiService.request('GET', `/${this.endpoint}/${datamartId}/download`, {

        }) as SingleRecordResponse<any>;
        return result.record;
    }

    public async queueDownloadJob(datamartId: string, override_query?: string) {
        const result = await this.apiService.request('POST', `/${this.endpoint}/${datamartId}/download`, {
            override_query: override_query
        }) as JobEnqueueResponse;
        return await BackgroundService.getInstance().waitForJob(result.job_id);
    }

    public async runAdhocQuery(datamartId: string, query: string) {
        const result = await this.apiService.request('POST', `/${this.endpoint}/${datamartId}/sql/execute`, {
            sql: query
        }) as ListRecordsResponse<any>;
        return result;
    }
};


const DataMartORM = new _DataMartORM('datamarts', ['last_build_completed']);
export default DataMartORM;
  