import FeatureFlagWrapper from "@components/featureflags/FeatureFlagWrapper.component";
import { PipelineNodeORM } from "@models/pipelineNode";
import { PageContentHeader } from "@pages/PageStructure.component";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import { usePipelineNode, useSource, useSourceTypes } from "@stores/data.store";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

interface Props {
    pipelineNodeId: string;
    children?: ReactNode;
}

const PipelineNodeSubnav = (props: Props) => {
    const location = useLocation();
    const pipelineNode = usePipelineNode(props.pipelineNodeId);

    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/config')) {
            return 'config';
        } else if (path.endsWith('/files')) {
            return 'files';
        } else if (path.indexOf('/relationships') >= 0) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        } else if (path.endsWith('/filters')) {
            return 'filters';
        } else if (path.indexOf('/visualizations') >= 0) {
            return 'visualizations';
        }

        return 'data';
    }, [location]);



    const nodeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }
        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const nodeTypeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }

        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const badge = useMemo(() => {
        if (!pipelineNode.data || !nodeTypeConfig) {
            return <></>
        }

        const bg = nodeTypeConfig.group.color;
        const icon = nodeTypeConfig.nodeType.icon;
        const label = nodeTypeConfig.nodeType.label;

        
        return <Badge pill bg={bg}>
            <i className={icon}></i> {label}
        </Badge>
    }, [pipelineNode.dataUpdatedAt, nodeTypeConfig]);
    

    if (!pipelineNode.data || !nodeConfig) {
        return <></>;   
    }

    return <PageContentHeader>
        <div className="top">
            <div className="d-flex center-vertically mb-2">
                <div className="flex-1">
                    
                    <h1 className="mb-0">{pipelineNode.data?.name}</h1>
                    <div className="mb-1 font-poppins text-muted font-13">
                        <span>{badge}</span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-file-multiple"></i> Total Records: {pipelineNode.data.total_records ? summarizeNumber(pipelineNode.data.total_records) : '0'}
                        </span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-clock"></i> Last Updated: {pipelineNode.data.last_build_completed ? timeAgo(pipelineNode.data.last_build_completed, 'mini-minute-now') : 'never'}
                        </span>
                    </div>
                    <p className="mb-0">{pipelineNode.data?.description}</p>
                </div>
                <div>
                    {props.children}
                    
                </div>
            </div>
            
        </div>       
        
        <nav className="subnav">
            <Link to={`/node/${props.pipelineNodeId}`} className={`nav-link ${activePage == 'data' ? 'active' : ''}`}>
                <i className="mdi mdi-table"></i> Output
            </Link>
            <Link to={`/node/${props.pipelineNodeId}/config`} className={`nav-link ${activePage == 'config' ? 'active' : ''}`}>
                <i className="mdi mdi-cog"></i> Configure
            </Link>
            {pipelineNode.data && pipelineNode.data.flat_file && (
                <Link to={`/node/${props.pipelineNodeId}/files`} className={`nav-link ${activePage === 'files' ? 'active' : ''}`}>
                    <i className="mdi mdi-file-multiple"></i> Files
                </Link>
            )}
            {['STAGING', 'DATA_MODELING'].includes(nodeConfig?.group.value || '') &&  (
                <>
                    
                    <Link to={`/node/${props.pipelineNodeId}/relationships`} className={`nav-link ${activePage == 'relationships' ? 'active' : ''}`}>
                        <i className="mdi mdi-transit-connection-variant"></i> Relationships
                    </Link>
                </>
                
            )}
            <Link to={`/node/${props.pipelineNodeId}/visualizations`} className={`nav-link ${activePage == 'visualizations' ? 'active' : ''}`}>
                <i className="mdi mdi-chart-bar"></i> Visualizations
            </Link>
            {/* <Link to={`/node/${props.pipelineNodeId}/insights`} className={`nav-link ${activePage === 'insights' ? 'active' : ''}`}>
                <i className="mdi mdi-lightbulb-on"></i> Insights
            </Link> */}
        </nav>

    </PageContentHeader>;
}

export default PipelineNodeSubnav;