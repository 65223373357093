import { subscribe, unsubscribe, emit } from '@nextcloud/event-bus'
import { useCallback, useEffect, useState } from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal.component'
import PromptModal, { PromptModalProps } from './PromptModal.component';
import VideoModal, {VideoModalProps} from './VideoModal.component';
import EmojiPickerModal, { EmojiPickerModalProps } from './EmojiPickerModal.component';
import TemplatePickerModal, { TemplatePickerProps } from './TemplatePickerModal.component';
import WaitingModal, { WaitingModalProps } from './WaitingModal.component';
import { scryRenderedDOMComponentsWithClass } from 'react-dom/test-utils';
import { set } from 'immer/dist/internal';

export const AlertsContainer = () => {
    const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps|undefined>(undefined);
    const [promptModalProps, setPromptModalProps] = useState<PromptModalProps|undefined>(undefined);
    const [emojiPromptModalProps, setEmojiPromptModalProps] = useState<EmojiPickerModalProps|undefined>(undefined);
    const [videoModalProps, setVideoModalProps] = useState<VideoModalProps|undefined>(undefined);
    const [templatePickerModalProps, setTemplatePickerModalProps] = useState<TemplatePickerProps|undefined>(undefined);
    const [waitingModalProps, setWaitingModalProps] = useState<WaitingModalProps|undefined>(undefined);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [showEmojiPrompt, setShowEmojiPrompt] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showTemplatePickerModal, setShowTemplatePickerModal] = useState(false);
    const [showWaitingModal, setShowWaitingModal] = useState(false);


    const confirmationModalTriggered = (props: ConfirmationModalProps) => {
        setConfirmationModalProps(props);
        setShowConfirmation(true);
    }

    const promptModalTriggered = (props: PromptModalProps) => {
        setPromptModalProps(props);
        setShowPrompt(true);
    }

    const emojiPromptModalTriggered = (props: PromptModalProps) => {
        setEmojiPromptModalProps(props);
        setShowEmojiPrompt(true);
    }

    const videoModalTriggered = (props: VideoModalProps) => {
        setVideoModalProps(props);
        setShowVideoModal(true);
    }

    const templatePickerModalTriggered = (props: TemplatePickerProps) => {
        setTemplatePickerModalProps(props);
        setShowTemplatePickerModal(true);
    }

    const waitingModalTriggered = (props: WaitingModalProps) => {
        setWaitingModalProps(props);
        setShowWaitingModal(true);
    }

    const waitingModalClosed = (input: any) => {
        setShowWaitingModal(false);
    }

    const onConfirmationClose = () => {
        if (!!confirmationModalProps?.onClose) {
            confirmationModalProps.onClose();
        }
        setShowConfirmation(false);
    }

    const onConfirmationConfirm = () => {
        setShowConfirmation(false);
        if (!!confirmationModalProps?.onConfirm) {
            confirmationModalProps.onConfirm();
        }
    }

    const onPromptClose = useCallback(() => {
        if (!!promptModalProps?.onClose) {
            promptModalProps.onClose();
        }
        setShowPrompt(false);
    }, [promptModalProps]);

    const onPromptConfirm = useCallback((val: string) => {
        setShowPrompt(false);
        if (!!promptModalProps?.onConfirm) {
            promptModalProps.onConfirm(val);
        }
        setPromptModalProps(undefined);
    }, [promptModalProps]);

    const onEmojiPromptClose = useCallback(() => {
        if (!!emojiPromptModalProps?.onClose) {
            emojiPromptModalProps.onClose();
        }
        setShowEmojiPrompt(false);
    }, [emojiPromptModalProps]);

    const onEmojiPromptConfirm = useCallback((val: string) => {
        setShowEmojiPrompt(false);
        if (!!emojiPromptModalProps?.onConfirm) {
            emojiPromptModalProps.onConfirm(val);
        }
    }, [emojiPromptModalProps]);

    const onVideoClose = useCallback(() => {
        if (!!videoModalProps?.onClose) {
            videoModalProps.onClose();
        }
        setShowVideoModal(false);
    }, [videoModalProps]);

    const onTemplatePickerClose = useCallback(() => {
        if (!!templatePickerModalProps?.onClose) {
            templatePickerModalProps.onClose();
        }
        setShowTemplatePickerModal(false);
    }, [templatePickerModalProps]);


    useEffect(() => {
        subscribe('pliable:alerts:confirmation', confirmationModalTriggered);
        subscribe('pliable:alerts:prompt', promptModalTriggered);
        subscribe('pliable:alerts:emojiPrompt', emojiPromptModalTriggered);
        subscribe('pliable:alerts:video', videoModalTriggered);
        subscribe('pliable:alerts:templatePicker', templatePickerModalTriggered);
        subscribe('pliable:alerts:waiting', waitingModalTriggered);
        subscribe('pliable:alerts:waitingClose', waitingModalClosed);

        return () => {
            unsubscribe('pliable:alerts:confirmation', confirmationModalTriggered);
            unsubscribe('pliable:alerts:prompt', promptModalTriggered);
            unsubscribe('pliable:alerts:emojiPrompt', emojiPromptModalTriggered);
            unsubscribe('pliable:alerts:video', videoModalTriggered);
            unsubscribe('pliable:alerts:templatePicker', templatePickerModalTriggered);
            unsubscribe('pliable:alerts:waiting', waitingModalTriggered);
            unsubscribe('pliable:alerts:waitingClose', waitingModalClosed);
        };
    }, []);

    return <>
        {!!confirmationModalProps && (<ConfirmationModal {...confirmationModalProps} show={showConfirmation} onClose={onConfirmationClose} onConfirm={onConfirmationConfirm}/>)}

        {!!promptModalProps && (<PromptModal {...promptModalProps} show={showPrompt} onClose={onPromptClose} onConfirm={onPromptConfirm}/>)}

        {!!emojiPromptModalProps && (<EmojiPickerModal {...emojiPromptModalProps} show={showEmojiPrompt} onClose={onEmojiPromptClose} onConfirm={onEmojiPromptConfirm}/>)}

        {!!videoModalProps && (<VideoModal {...videoModalProps} show={showVideoModal} onClose={onVideoClose} />)}

        {!!templatePickerModalProps && (<TemplatePickerModal {...templatePickerModalProps} show={showTemplatePickerModal} onClose={onTemplatePickerClose} />)}

        {!!waitingModalProps && (<WaitingModal {...waitingModalProps} show={showWaitingModal} />)}
    </>
}


export const confirmation = (props: ConfirmationModalProps) => {
    emit('pliable:alerts:confirmation', props);
}

export const prompt = (props: PromptModalProps) => {
    emit('pliable:alerts:prompt', props);
}

export const showWaiting = (props: WaitingModalProps) => {
    emit('pliable:alerts:waiting', props);
}

export const closeWaiting = () => {
    emit('pliable:alerts:waitingClose', {});
}


export const getPromptAnswer = async (question: string, header: string = 'Wait', textarea: boolean = false, defaultValue: string = '', dropdownOptions?: { value: string, label: string }[]) => {
    const p = new Promise<string>((resolve, reject) => {
        prompt({
            header: header,
            promptText: question,
            useTextArea: textarea,
            promptDefaultValue: defaultValue,
            dropdownOptions: dropdownOptions,
            onClose: () => {
                resolve('');
            },
            onConfirm: (value: string) => {
                resolve(value);
            }
        });
    });

    return p;
}

export const alert = async (header: string, message: string, headerBg: string = '') => {
    confirmation({
        header: header,
        message: message,
        onClose: () => {

        },
        onConfirm: () => {

        },
        confirmationButtonText: 'OK',
        hideCloseButton: true,
        confirmationButtonVariant: 'light',
    });
}

export const requireConfirmation = async (message: string | JSX.Element, header: string = 'Confirmation', confirmationButtonText: string = 'OK', closeButtonText: string = 'No', typedValueExpectation?: string) => {
    const p = new Promise<boolean>((resolve, reject) => {
        confirmation({
            header: header,
            message: message,
            onConfirm: () => {
                resolve(true);
            },
            onClose: () => {
                resolve(false);
            },
            typedValueExpectation: typedValueExpectation,
            confirmationButtonText: confirmationButtonText,
            closeButtonText: closeButtonText,
        });
    });

    return p;
}

export const promptEmoji = (props: EmojiPickerModalProps) => {
    emit('pliable:alerts:emojiPrompt', props);
}

export const showVideo = (props: VideoModalProps) => {
    emit('pliable:alerts:video', props);
}

export const pickTemplate = (props: TemplatePickerProps) => {
    emit('pliable:alerts:templatePicker', props);
}


