import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { PipelineNode } from "@models/pipelineNode";
import { ReportBuilderDimension } from "@models/reportBuilder";
import { useIsInDraftMode } from "@stores/data.store";
import { Form } from "react-bootstrap";
import { useImmer } from "use-immer";

interface DimensionFormProps {
    dimension: ReportBuilderDimension | null;
    onSubmit: (updatedDim: ReportBuilderDimension) => void;
    onCancel: () => void;
}

const DimensionForm = (props: DimensionFormProps) => {
    const inDraft = useIsInDraftMode();
    const [dimension, setDimension] = useImmer<ReportBuilderDimension | null>(props.dimension);
    return <>
        <h2>
            <i className="mdi mdi-information-outline"></i> Basic Information
        </h2>
        <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control disabled={!inDraft} value={dimension?.name || ''} type="text" onChange={(e) => {
                setDimension(draft => {
                    if (!draft) return;
                    draft.name = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Column Label</Form.Label>
            <Form.Control disabled={!inDraft} value={dimension?.column_name || ''} type="text" onChange={(e) => {
                setDimension(draft => {
                    if (!draft) return;
                    draft.column_name = e.target.value;
                });
            }}/>
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control disabled={!inDraft} value={dimension?.description || ''} as="textarea" onChange={(e) => {
                setDimension(draft => {
                    if (!draft) return;
                    draft.description = e.target.value;
                });
            }}/>
        </Form.Group>
        <hr />
        <h2>
            <i className="mdi mdi-database-import"></i> Data Source
        </h2>
        <Form.Group className="mb-3">
            <Form.Label>Select Node</Form.Label>
            <PipelineNodeSelector 
                disabled={!inDraft}
                selectedId={dimension?.pipeline_node_id || ''}
                optionFilter={(pn: PipelineNode) => ['DIMENSION', 'DATE_DIMENSION', 'BUSINESS_OBJECT'].includes(pn.node_type)}
                onSelect={(node: PipelineNode|undefined) => {
                    setDimension(draft => {
                        if (!draft) return;

                        draft.pipeline_node_id = node ? node.id as string : '';
                    });
                }} 
            />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Select Column</Form.Label>
            <PipelineNodeColumnSelector 
                disabled={!inDraft}
                selectedId={dimension?.field_id || ''}
                onSelect={(fieldId: string) => {
                    setDimension(draft => {
                        if (!draft) return;

                        draft.field_id = fieldId;
                    });
                }}
                pipelineNodeId={dimension?.pipeline_node_id || ''}
                excludeForeignKeys
                includeRecordId
            />
        </Form.Group>
        <button className="btn btn-success btn-lg me-1" 
            disabled={!dimension || !dimension.pipeline_node_id || !dimension.field_id || !dimension.name || !dimension.column_name}
            onClick={() => {
            if (dimension) {
                props.onSubmit(dimension);
            }
        }}>Save</button>
        <button className="btn btn-outline-secondary btn-lg" onClick={() => {
            props.onCancel();
        }}>Cancel</button>
    </>
}

export default DimensionForm;