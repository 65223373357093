import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent,PaneContentWithSubnav } from "@pages/PageStructure.component";
import { usePipelineNode } from "@stores/data.store";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import FilesList from "@components/files/FilesList.component";
import { File } from '@models/file';
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { PipelineNodeORM } from "@models/pipelineNode";

const PipelineNodeFilesPage = () => {
    const { pipelineNodeId } = useParams();
    const { isLoading, data: pipelineNode, refetch: refetchPipelineNode } = usePipelineNode(pipelineNodeId as string);
    const navigate = useNavigate();

    const onSourceAdded = useCallback((pipelineNodeId: string) => {
        
        navigate(`/node/${pipelineNodeId}`);
    }, [pipelineNodeId]);

    const onFileDelete = useCallback(async (file: File) => {
        await PipelineNodeORM.deleteFile(pipelineNodeId as string, file.id as string);
        
        refetchPipelineNode();
    }, [pipelineNodeId]);

    return <PageStructure
        pageTitle={pipelineNode ? pipelineNode.name : ''}
        breadcrumbs={[
            {
                title: 'Pipeline Node',
            } 
        ]}
    >
        
        <PageContent>

                <PipelineNodeSubnav
                    pipelineNodeId={pipelineNodeId as string}
                >
                </PipelineNodeSubnav>
                <PageContentInner hasHeader>
                    {isLoading && <PliableLoader />}
                    {!isLoading && <>
                        <DataSourceImporter 
                            onSourceAdded={onSourceAdded}
                            sourceRecordTypeId={pipelineNode?.source_record_type_id}
                            />
                        <hr />
                            <FilesList 
                                source_record_type_id={pipelineNode?.source_record_type_id}
                                pipelineNode={pipelineNode}
                                onDelete={onFileDelete}
                            />
                    </>}
                </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default PipelineNodeFilesPage;