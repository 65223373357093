import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import VisualizationChart from "@components/pipelineNodes/VisualizationChart.component";
import PipelineNodeFieldMappingDataSourceList, { DataSourceDraggableColumnPicker } from "@components/pipelineNodes/mapping/PipelineNodeFieldMappingDataSourceList.component";
import { FilterConfig, PipelineNode, PipelineNodeField } from "@models/pipelineNode";
import { ChartType, Visualization, deleteVisualization, saveVisualization, useChartData, useVisualization } from "@models/visualization";
import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { useIsInDraftMode, usePipelineNode } from "@stores/data.store";
import { Allotment } from "allotment";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import styled from 'styled-components';
import { Form, Offcanvas } from "react-bootstrap";
import Dropdown from "@components/form/Dropdown.component";
import SaveButton from "@components/button/SaveButton.component";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { requireConfirmation } from "@services/alert/alert.service";
import AsyncButton from "@components/button/AsyncButton.component";
import { DraftModeRequired, DraftOnly } from "@components/project/DraftModeRequired.component";
import FilterConfigForm, { FilterFormDropdown } from "@pages/SourceRecordType/FilterConfigForm.component";

const DroppedColumnStyles = styled.div`
border-radius: 5px;
padding: 12px 12px 12px 37px;
display: block;
user-select: none;
background: var(--ct-dark);
color: white;


display: flex;
align-items: center;
.text-container {
    flex: 1;
    font-family: "Fira Code", monospace;
    font-weight: bold;
    color: white;

    .dropdown-container {
        font-family: "Poppins";
        label {
            font-weight: normal !important;
            font-size: 13px;
        }
    }
    
}



.buttons {
    button {
        font-size: 18px;
        padding: 0px;
        margin: 0px;
        border-radius: 100%;
        border: none;
        background-color: transparent;
        color: var(--ct-body-color);
        line-height: 24px;
        text-align: center;
    
        &:hover {
            color: white;
            cursor: pointer;
        }
    }
}
`

interface DroppedColumnProps {
    fieldId: string;

    node: PipelineNode;
    onRemove: () => any;
    aggregateBy?: string;
    sortDescending?: boolean;
    onChangeAggregateBy?: (newVal: string) => any;
    onChangeSortDirection?: (newDir: boolean) => any;
}
const DroppedColumn = (props: DroppedColumnProps) => {
    const inDraftMode = useIsInDraftMode();
    const fieldName = useMemo(() => {
        if (props.fieldId === '_PLB_UUID') {
            return 'Pliable Record ID';
        }
        return props.node.fields.find(f => f.id === props.fieldId)?.label;
    }, [props.fieldId, props.node]);
    const aggOptions = useMemo(() => {
        const options = [{
            label: 'Count Distinct',
            value: 'COUNT_DISTINCT',
        }]
        if (props.fieldId === '_PLB_UUID') {
            return options;
        }
        const theField = props.node.fields.find(f => f.id === props.fieldId);
        if (!theField) {
            return [];
        }
        
        if (['INT', 'DECIMAL'].includes(theField?.type)) {
            options.push({
                label: 'Sum',
                value: 'SUM'
            });
            options.push({
                label: 'Average',
                value: 'AVG',
            });
        }

        if (['INT', 'DECIMAL', 'DATE', 'DATETIME', 'DATETIME_TZ'].includes(theField?.type)) {
            options.push({
                label: 'Maximum',
                value: 'MAX',
            });

            options.push({
                label: 'Minimum',
                value: 'MIN',
            });
        }
        return options;
    }, [props.node, props.fieldId]);

    const handleRemove = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.onRemove();
    }, [props.onRemove]);

    return <DroppedColumnStyles>
        <div className="text-container">
            {fieldName}
            {props.onChangeSortDirection && <>
                <div className="dropdown-container">
                    <Form.Check type="switch" checked={!!props.sortDescending} onClick={(e) => {
                        e.stopPropagation();
                    }} onChange={(e) => {
                        
                        props.onChangeSortDirection!(!props.sortDescending);
                    }} label="Sort descending"/>
                </div>
            </>}
            {props.onChangeAggregateBy && <>
                <div className="dropdown-container">
                
                    <select
                        disabled={!inDraftMode}
                        
                        onChange={(e) => {
                            props.onChangeAggregateBy && props.onChangeAggregateBy(e.target.value);
                        }}   
                        value={props.aggregateBy}
                    >
                        {aggOptions.map(o => {
                            return <option value={o.value}>{o.label}</option>
                        })}
                        
                    </select>
                </div>
            </>}
            
        </div>
        <DraftOnly>

            <div className="buttons">
                <button onClick={handleRemove}>
                    <i className="mdi mdi-close-thick"></i>
                </button>
            </div>
        </DraftOnly>
    </DroppedColumnStyles>
}

const DropTargetContainer = styled.div`
padding: 16px 20px;
margin-bottom: 1rem;
user-select: none;
&.selectable {
    &:hover {
        cursor: pointer;
        outline: solid 1px var(--pliable-blue);
    }
}

&.active {
    outline: solid 2px var(--pliable-blue);

    &:hover {
        outline: solid 2px var(--pliable-blue);
    }
}

&.is-over  {
    background-color: #eee;
}

&.can-drop  {
    outline: dashed 3px var(--pliable-yellow);
}
`

const CHART_TYPES = [
    {
        label: 'Bar',
        value: 'bar',
    }, {
        label: 'Line',
        value: 'line',
    }, {
    //     label: 'Pie',
    //     value: 'pie',
    // }, {
        label: 'Area',
        value: 'area',
    }, {
        label: 'Single Metric',
        value: 'single_metric',
    }
]

interface DropTargetProps {
    targetId: string;
    children: ReactNode;
    className?: string;
    onClick?: () => any;
}

const DropTarget = (props: DropTargetProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
          accept: 'FIELD',
          drop: () => ({
            fieldId: props.targetId,
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
        }),
        [props.targetId],
    );

    const classes = ['selectable'];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }

    if (props.className) {
        classes.push(props.className);
    }

    const onClick = useCallback((e: any) => {

        if (!!props.onClick) {
            if (e.target.tagName == 'SELECT' || e.target.tagName == 'BUTTON') {
                return;
            }
            props.onClick();
        }
    }, [props.onClick]);
    return <DropTargetContainer onClick={onClick} ref={drop} className={classes.join(' ')}>
        {props.children}
    </DropTargetContainer>
}


const PipelineNodeVisualizationPage = () => {
    const inDraftMode = useIsInDraftMode();
    const { pipelineNodeId, visualizationId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const columnOptions = useMemo(() => {
        if (!pipelineNode.data) {
            return [];
        }
        return pipelineNode.data.fields.map(f => {
            return {
                label: f.label,
                value: f.id,
            }
        });
    }, [pipelineNode.dataUpdatedAt])

    const [viz, setViz] = useImmer<Visualization>({
        id: null,
        name: '',
        description: '',
        pipeline_node_id: pipelineNodeId as string,
        dimension_field_id: '',
        measures: [],
        chart_type: undefined,
    });


    const actualVizId = useMemo(() => {
        if (visualizationId == 'new') {
            return '';
        }
        return visualizationId as string;
    }, [visualizationId]);

    const theViz = useVisualization(actualVizId);

    useEffect(() => {
        if (theViz.data) {
            setViz(theViz.data);
        }
    }, [theViz.dataUpdatedAt]);

    const handleDrop = useCallback((sourceColumn: PipelineNodeField, targetId: string) => {
        setViz(draft => {
            if (targetId === 'dim1') {
                draft.dimension_field_id = sourceColumn.id;
            } else if (targetId === 'dim2') {
                draft.dimension2_field_id = sourceColumn.id;
            } else if (targetId === 'measures') {
                draft.measures.push({
                    field_id: sourceColumn.id,
                    aggregate_by: 'COUNT_DISTINCT',
                })
            }
        });
        
    }, []);

    const [deleting, setDeleting] = useState(false);
    const deleteViz = useCallback(async () => {
        const confirm = await requireConfirmation('Are you sure you want to delete this visualization?');
        if (confirm) {
            await deleteVisualization(visualizationId as string, pipelineNodeId as string);
            navigate(`/node/${pipelineNodeId}/visualizations`);
        }
    }, [visualizationId, pipelineNodeId]);

    const changeAggregateBy = useCallback((measureIdx: number, newVal: string) => {
        setViz(draft => {
            draft.measures[measureIdx].aggregate_by = newVal;
        })
    }, []);

    const changeSortDescendingDim1 = useCallback((newVal: boolean) => {
        setViz(draft => {
            draft.dimension_sort_descending = newVal;
        })
    }, []);

    const changeSortDescendingDim2 = useCallback((newVal: boolean) => {
        setViz(draft => {
            draft.dimension2_sort_descending = newVal;
        })
    }, []);

    const navigate = useNavigate();
    const saveViz = useCallback(async () => {
        setShowColumns(false);
        try {
            const result = await saveVisualization(viz);
            if (visualizationId == 'new') {
                navigate(`/node/${pipelineNodeId}/visualizations/${result.id}`);
            }

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [viz, pipelineNodeId, visualizationId]);

    useEffect(() => {
        if (viz.chart_type == 'pie') {
            setViz(draft => {
                draft.dimension2_field_id = undefined;
            })
        }
    }, [viz.chart_type]);

    const [showColumns, setShowColumns] = useState(false);

    const [filters, setFilters] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const [filtersForViz, setFiltersForViz] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const applyFilters = useCallback(() => {
        setFiltersForViz(filters);
    }, [filters]);

    

    if (!pipelineNode.data) {
        return <></>;
    }

    return <PageStructure
        pageTitle="Visualizations"
    >
        <Offcanvas show={showColumns} onHide={() => setShowColumns(false)} placement="end" scroll backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <h3>Column Options</h3>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            <p>Here are all the columns available in your node. Drag them over to the <strong>x-axis</strong>, <strong>categorization</strong>, or <strong>measures</strong> boxes on the left.</p>
                            <DataSourceDraggableColumnPicker
                                sourceNodeId={pipelineNodeId as string}
                                allowRemove={false}
                                alwaysExpanded
                                usedColumns={[]}
                                searchFilter=""
                                onDropSourceColumn={handleDrop}
                                title="Available Columns"
                            
                            />
                        </div>
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        <DndProvider backend={HTML5Backend}>
            
            <PageContent>
                <Pane>
                    <PipelineNodeSubnav
                        pipelineNodeId={pipelineNodeId as string}
                    >
                        <DraftOnly>
                            <SaveButton
                                onClick={saveViz}
                                text="Save & Update"
                                className="me-1"
                            />
                            {visualizationId !== 'new' && <>
                                <AsyncButton
                                    variant="danger"
                                    icon="mdi mdi-delete"
                                    className="me-1"
                                    text="Delete"
                                    onClick={deleteViz}
                                    loading={deleting}
                                />
                            </>}
                            <button className="btn btn-light" onClick={() => setShowColumns(true)}>Columns</button>
                        </DraftOnly>
                        
                        
                    </PipelineNodeSubnav>
                    <PageContentInner hasHeader>
                                
                            <div className="d-flex" style={{'height': '100%'}}>
                                
                                
                                    <div style={{width: '30%', 'background': 'white'}} className="border-right">
                                        <Pane>
                                            <PaneContent>
                                                <div className="p-3">
                                                    <div className="d-flex center-vertically mb-2">
                                                        <h2 className="mb-0 flex-1">Configure Visualization</h2>
                                                        <DraftOnly>
                                                            <SaveButton
                                                                onClick={saveViz}
                                                                className="btn-sm"
                                                                text="Update"
                                                            />
                                                        </DraftOnly>
                                                        
                                                    </div>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>
                                                            Visualization Name
                                                        </Form.Label>
                                                        <Form.Control disabled={!inDraftMode} type="text" value={viz.name} onChange={(e) => {
                                                            setViz(draft => {
                                                                draft.name = e.target.value;
                                                            })
                                                        }}/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>
                                                            Visualization Type
                                                        </Form.Label>
                                                        <Dropdown
                                                            disabled={!inDraftMode}
                                                            options={CHART_TYPES}
                                                            selected={viz.chart_type}
                                                            onChange={(newVal) => {
                                                                setViz(draft => {
                                                                    draft.chart_type = newVal as ChartType;
                                                                });
                                                            }}
                                                        />
                                                        
                                                    </Form.Group>
                                                    {['bar', 'area'].includes(viz.chart_type as string) && <>
                                                        <Form.Group className="mb-2">
                                                            <Form.Check
                                                                disabled={!inDraftMode}
                                                                type="switch"
                                                                checked={!!viz.stacked}
                                                                onChange={(e) => setViz(draft => {
                                                                    draft.stacked = !draft.stacked;
                                                                })}
                                                                label="Stack"
                                                            />
                                                        </Form.Group>
                                                    </>}
                                                    {!!viz.chart_type && <>
                                                        <DropTarget
                                                            targetId="dim1"
                                                            className="shadow-box"
                                                            onClick={() => setShowColumns(true)}
                                                        >
                                                            <div className="mb-2">
                                                                <h4 className="mb-0">X-Axis</h4>
                                                                <div style={{lineHeight: '.875em'}}><small>Drag a column to set the x-axis of your chart.</small></div>
                                                            </div>
                                                            {viz.dimension_field_id && <DroppedColumn
                                                                fieldId={viz.dimension_field_id}
                                                                onRemove={() => setViz(draft => {
                                                                    draft.dimension_field_id = '';
                                                                })}
                                                                node={pipelineNode.data}
                                                                sortDescending={!!viz.dimension_sort_descending}
                                                                onChangeSortDirection={changeSortDescendingDim1}
                                                            />}
                                                        </DropTarget>
                                                        {['line', 'bar', 'area'].includes(viz.chart_type) && <>
                                                            <DropTarget
                                                                targetId="dim2"
                                                                className="shadow-box"
                                                                onClick={() => setShowColumns(true)}
                                                            >
                                                                <div className="mb-2">
                                                                    <h4 className="mb-0">Categorization (Optional)</h4>
                                                                    <div style={{lineHeight: '.875em'}}><small>Drag a column break down your x-axis by category. This will add color legend to your chart.</small></div>
                                                                </div>
                                                                {viz.dimension2_field_id && <DroppedColumn
                                                                    fieldId={viz.dimension2_field_id}
                                                                    onRemove={() => setViz(draft => {
                                                                        draft.dimension2_field_id = '';
                                                                    })}
                                                                    node={pipelineNode.data}
                                                                    sortDescending={!!viz.dimension2_sort_descending}
                                                                    onChangeSortDirection={changeSortDescendingDim2}
                                                                />}
                                                            </DropTarget>
                                                        </>}
                                                        
                                                        <DropTarget
                                                            targetId="measures"
                                                            className="shadow-box"
                                                            onClick={() => setShowColumns(true)}
                                                        >
                                                            <div className="mb-2">
                                                                <h4 className="mb-0">Measures (Y-Axis)</h4>
                                                                <div style={{lineHeight: '.875em'}}><small>Drag one or more columns to configure what gets graphed on your chart. 
                                                                    {/* <Link to={`/node/${pipelineNodeId}/config`}>Set the field data type</Link> to <code>INTEGER</code>, <code>DECIMAL</code>, <code>DATE</code>, or <code>DATETIME</code> to get access to different aggregation options. */}
                                                                </small></div>
                                                            </div>
                                                            {viz.measures.length > 0 && <div>
                                                                {viz.measures.map((v, idx) => <DroppedColumn
                                                                    fieldId={v.field_id}
                                                                    key={idx}
                                                                    onRemove={() => setViz(draft => {
                                                                        draft.measures.splice(idx, 1);
                                                                    })}
                                                                    node={pipelineNode.data!}
                                                                    aggregateBy={v.aggregate_by}
                                                                    onChangeAggregateBy={(newVal: string) => {
                                                                        changeAggregateBy(idx, newVal);
                                                                    }}
                                                                />)}    
                                                            </div>}
                                                        </DropTarget>
                                                    </>}
                                                    
                                                </div>
                                            </PaneContent>
                                        </Pane>
                                    </div>
                                    
                                    <div style={{width: '70%', background: 'white'}}>
                                        <Pane>
                                            <PaneContent>
                                                <div className="p-3">
                                                    <div className="mb-2">
                                                        <FilterFormDropdown
                                                            onClickApply={applyFilters}
                                                            config={filters}
                                                            columnOptions={pipelineNode.data.fields}
                                                            onChange={setFilters}
                                                        />
                                                    </div>
                                                    
                                                    <VisualizationChart
                                                        visualizationId={actualVizId}
                                                        filter={filtersForViz}
                                                    />
                                                    
                                                </div>
                                            </PaneContent>
                                        </Pane>
                                    </div>
                            </div>
                                
                            
                        
                    </PageContentInner>
                </Pane>
            </PageContent>
        </DndProvider>
    </PageStructure>

}

export default PipelineNodeVisualizationPage;