import { useEffect, useRef, useCallback, useState, forwardRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TenantPicker from '@components/tenant/TenantPicker.component';
import AuthService, { useAuthState } from "../../services/auth/auth.service";
import logoLarge from '../../assets/images/pliable/logo_with_text_black.png';
import { PliableIcon } from '@components/icons/PliableIcons.component';
import { AuthorizedContent, AdminPortal, useEntitlements } from "@frontegg/react";
import { Role } from "../../constants/roles";
import TrackingService, {Events} from '@services/tracking.service';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import { pickTemplate } from '@services/alert/alert.service';
import { toggleThenaWidget } from "@stores/data.store";
import ApiService from '@services/api/api.service';
import { enterDraftMode, exitDraftMode, useDraftVersionId } from '@stores/data.store';
import { DraftOnly } from '@components/project/DraftModeRequired.component';

const Styles = styled.div`
    z-index: 10;
    border-right: solid 1px var(--ct-border-color);
    &.sidebar-compact {
        width: 60px;
        span.link-label {
            display: none;
        }
    }

    span.link-label {
        margin-left: .5rem;
    }

    text-align: left;
    width: 12rem;
    background-color: white;
    height: 100vh;

    .nav-item {
        font-size: 1em;
        text-align: center;
        font-family: "Poppins";
        
        button.bottom {
            border-radius: 100%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            text-align: center;
            color: white;
            font-size: 24px;
            margin: 0px auto;
        }
        .nav-link {
            color: var(--ct-body-color);
            &.active {
                background: white;
                color: var(--pliable-blue);

                &:hover {
                    color: var(--pliable-blue);
                }
            }
            &:hover {
                color: black;
                text-decoration: none;
            }
        }
    }

    .version {
        font-size: .8em;
        color: #666;
    }
`;

// @ts-ignore
const AuthToggle = forwardRef(({ children, onClick, disabled }, ref) => (
    <button
    //   @ts-ignore
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      style={{
        padding: '0px',
        background: 'none',
        border: 'none',
      }}
    >
      {children}
    </button>
  ));


function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLLIElement>(null);
    

    const handleClickOutside = (event: any) => {
        // console.log('Click outside:', event);
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

export interface NavParams {
    disableSidebar?: boolean;
}

const Nav = (params: NavParams = {}) => {
    const navigate = useNavigate();
    const {data: draftVersionId} = useDraftVersionId();
    const logout = () => {
        navigate('/logout');
    }

    const settings = () => {
        navigate('/account');
    }

    useHotkeys('ctrl+t', () => {
        pickTemplate({
            onTemplateSelected: (tmpl_id) => { navigate(`/template/${tmpl_id}?autouse=true`) }
        })
    }, []);

    useHotkeys('ctrl+a', () => {
        navigate(`/advanced/new`)
    }, []);

    useHotkeys('ctrl+d', () => {
        onToggleDraftMode('hotkey');
    }, [draftVersionId]);
    

    const location = useLocation();

    const authState = useAuthState();

    // const { sidebarExpanded, toggleSidebar} = useGlobalState((state: any) => state);

    const sidebarExpanded = true;

    let extraClass = '';
    if (params.disableSidebar) {
        extraClass = 'sidebar-disabled';
    } else if (!sidebarExpanded) {
        extraClass = 'sidebar-compact';
    }

    let toggleClass = '';
    if (params.disableSidebar) {
        toggleClass = 'invisible';
    }

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const username = AuthService.getInstance().authState?.user?.email;
    const tenant_name = AuthService.getInstance().getTenantName();

    const { isEntitled: isAssistantEnabled } = useEntitlements({
        featureKey: "tenant_assistant",
    });

    const onToggleDraftMode = useCallback(async (trigger: string) => {
        if(draftVersionId) {
            await exitDraftMode(trigger);
        }else{
            await enterDraftMode(trigger);
        }
    }, [draftVersionId]);

    const showAdminPortal = useCallback(() => {
        AdminPortal.show();
        TrackingService.track_event(Events.USER_ADMIN_CLK);
    }, []);

    const showAssistant = useCallback(() => {
        navigate('/data-assistant');
    }, []);

    const trackLogout = useCallback(() => {
        TrackingService.track_event(Events.USER_LOGOUT_CLICK);
    }, []);

    const runSchedulerCheck = useCallback(async () => {
        const service = ApiService.getInstance();
        const response = await fetch(service.hostname + '/system/scheduler/check', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + AuthService.getInstance().getAccessToken(),
            },
            mode: 'cors',
            credentials: 'include',
        });
    }, []);

    return (
    <Styles className={`d-flex flex-column flex-shrink-0 bg-body-tertiary  ${extraClass}`}>
        <ul className="nav nav-pills nav-flush flex-column text-start">
            <li className="nav-item text-start" >
                
                <Link className={`nav-link py-3`} to="/" title="Dashboard">
                    {!!sidebarExpanded && (
                        <img src={logoLarge} style={{height: '27px'}}/>
                    )}
                    {!sidebarExpanded && (
                        <PliableIcon height='50px' />
                    )}
                </Link>
            </li>
        </ul>
        <ul className="nav nav-pills  mb-auto">
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/" title="Dashboard">
                    <i className="mdi mdi-home"></i>
                    <span className="link-label">Home</span>
                </Link>
            </li>
            <DraftOnly>
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname === '/db' ? 'active' : ''}`} to="/db" title="Database">
                        <i className="mdi mdi-database"></i>
                        <span className="link-label">Database</span>
                    </Link>
                </li>
            </DraftOnly>
            
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname.startsWith('/model') ? 'active' : ''}`} to="/model/business-objects" title="Data Model">
                    <i className="mdi mdi-sitemap"></i>
                    <span className="link-label">Data Model</span>
                </Link>
                <ul className="nav">
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/model/sources' ? 'active' : ''}`} to="/model/sources" title="Data Model">
                            <span className="link-label">Data Sources</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/model/business-objects' ? 'active' : ''}`} to="/model/business-objects" title="Data Model">
                            <span className="link-label">Business Objects</span>
                        </Link>
                    </li>
                    
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/model/reporting' ? 'active' : ''}`} to="/model/reporting" title="Data Model">
                            <span className="link-label">Reporting</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/model/semantic-layer' ? 'active' : ''}`} to="/model/semantic-layer" title="Data Model">
                            <span className="link-label">Semantic Layer</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/model/dag' ? 'active' : ''}`} to="/model/dag" title="Full Dag">
                            <span className="link-label">Full Pipeline</span>
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="nav-item">
                <button onClick={showAdminPortal} className="nav-link" title="User Admin">
                    <i className="mdi mdi-account-group"></i>
                    <span className="link-label">Manage Users</span>
                </button>
            </li>
            <AuthorizedContent requiredRoles={[Role.Admin]} key="tenant-admin-wrap" >
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/account' ? 'active' : ''}`} to="/account" title="Account Settings">
                    <i className="mdi mdi-cog"></i>
                    <span className="link-label">Account Settings</span>
                </Link>
            </li>
            </AuthorizedContent>
            
        </ul>
        <div className="flex-column p-2">
            <div>
                <img src={authState?.user?.profilePictureUrl as string | undefined} alt="mdo" width="40" height="40" className="rounded-circle" /> {authState?.user?.name}
            </div>
            <div>
                <Link to="/logout">Logout</Link>
            </div>
            <hr />
            <div className="small">
                version {process.env.REACT_APP_VERSION}
            </div>
            <div className="small">
            <TenantPicker />

            </div>
        </div>

        

    </Styles>
    );

}


export default Nav;