import { PipelineNode, PipelineNodeExtraReportColumn, PipelineNodeMeasureJoinTree, useReportingPaths } from "@models/pipelineNode";
import { ReportBuilderDimension, ReportBuilderDimensionJoinTree, ReportBuilderDimensionORM, ReportBuilderMeasure, ReportBuilderMeasureORM, useDimensions, useMeasures } from "@models/reportBuilder";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Updater, useImmer } from "use-immer";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import PipelineNodeColumnSelector from "./PipelineNodeColumnSelector.component";
import { useIsInDraftMode, usePipelineNodes, useReportingTree } from "@stores/data.store";
import PipelineNodeName, { PipelineNodeFieldName } from "./PipelineNodeName.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { graphlib } from "dagre";
import Warning from "@components/statusIndicators/Warning.component";
import PipelineNodeJoinTreeLabel from "./mapping/PipelineNodeJoinTreeLabel.component";
import DataWhitelistForm from "./PipelineNodeDataWhitelist.component";
import DimensionForm from "@components/datamodel/DimensionForm.component";
import MeasureForm, { getConnectionsForAllGraphNodes } from "@components/datamodel/MeasureForm.component";
import PipelineNodeWhitelistConfiguration from "./configuration/PipelineNodeWhitelistConfiguration.component";
import PipelineNodeOutputConfiguration from "./configuration/PipelineNodeOutputConfiguration.component";



interface NodeConfigProps {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}
const PipelineNodeReportBuilder = (props: NodeConfigProps) => {

    const [editingDimension, setEditingDimension] = useState<ReportBuilderDimension | null>(null);
    const [editingMeasure, setEditingMeasure] = useState<ReportBuilderMeasure | null>(null);

    const dimensions = useDimensions();
    const measures = useMeasures();

    const saveDim = useCallback(async (updatedDim: ReportBuilderDimension) => {
        await ReportBuilderDimensionORM.save(updatedDim);
        dimensions.refetch();
        setEditingDimension(null);
    }, []);

    const saveMeasure = useCallback(async (updatedMeasure: ReportBuilderMeasure) => {
        await ReportBuilderMeasureORM.save(updatedMeasure);
        measures.refetch();
        setEditingMeasure(null);
    }, []);

    const tree = useReportingTree();

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    const allNodeConnections = useMemo(() => {
        return getConnectionsForAllGraphNodes(graph);
    }, [graph]);

    const dimensionsById = useMemo(() => {
        if (!dimensions.data) {
            return {};
        }
        return dimensions.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderDimension});
    }, [dimensions.dataUpdatedAt]);

    const measuresById = useMemo(() => {
        if (!measures.data) {
            return {};
        }
        return measures.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderMeasure});
    }, [measures.dataUpdatedAt]);

    const dimensionPipelineNodeIds = useMemo(() => {
        return dimensions.data?.map(d => d.pipeline_node_id) || [];
    }, [props.node.dimension_ids, dimensionsById]);

    const availableDimensionIds = useMemo(() => {
        /**
         * Dimensions are available if:
         *  (a) they are already selected
         *  (b) they are connected to all selected measures
         *  (c) there is not already a selected dimension from the same node
         */
        if (!dimensions.data) {
            return [];
        }

        if (!props.node.measure_ids || props.node.measure_ids.length == 0) {
            return [];
        }

        const currentlySelectedNodeIds = props.node.dimension_ids?.filter(did => dimensionsById.hasOwnProperty(did)).map(did => dimensionsById[did].pipeline_node_id) || [];

        
        let requiredDimensionIds: string[] = [];
        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        if (props.node.measure_ids) {
            props.node.measure_ids.forEach((mid, idx) => {
                const measure = measuresById[mid];
                if (!measure) {
                    return;
                }
                if (measure?.is_calculation) {
                    return;
                }
                
                // Possible edge case where the measure doesn't yet have a pipeline node ID set
                const startWith: string[] = [];
                if (measure.pipeline_node_id) {
                    startWith.push(measure.pipeline_node_id as string);
                }
                if (idx == 0) {
                    requiredDimensionIds = startWith.concat(measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || []);
                } else {
                    requiredDimensionIds = startWith.concat(requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d)));
                }
            });
        }

        console.log('Required dim ids', requiredDimensionIds);


        return dimensions.data.filter(d => {
            if (props.node.dimension_ids && props.node.dimension_ids.includes(d.id as string)) {
                return true;
            }

            if (!d.pipeline_node_id) {
                return false;
            }

            if (!requiredDimensionIds.includes(d.pipeline_node_id)) {
                return false;
            }
            
            if (currentlySelectedNodeIds.length == 0) {
                return true;
            }

            if (currentlySelectedNodeIds.includes(d.pipeline_node_id)) {
                return false;
            }
            

            // if (!allNodeConnections['PipelineNode:' + d.pipeline_node_id]) {
            //     return false;
            // }
            // if (!currentlySelectedNodeIds.map(n => 'PipelineNode:' + n).every(n => allNodeConnections['PipelineNode:' + d.pipeline_node_id].includes(n))) {
            //     return false;
            // }
            return true;
        }).map(d => d.id as string);

    }, [dimensions.dataUpdatedAt, props.node.dimension_ids, props.node.measure_ids, allNodeConnections, dimensionsById, measuresById]);

    const availableMeasureIds = useMemo(() => {
        /**
         * Measures are available if they share at least one connected dimension with all currently selected measures,
         * AND, if any dimensions are selected, if they are connected to all selected dimensions
         */

        if (!measures.data || !dimensions.data) {
            return [];
        }

        let requiredDimensionIds: string[] = [];
        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        if (props.node.measure_ids) {
            props.node.measure_ids.forEach((mid, idx) => {
                const measure = measuresById[mid];
                if (idx == 0) {
                    requiredDimensionIds = measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || [];
                } else {
                    requiredDimensionIds = requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d));
                }
            });
        }


        const requiredPipelineNodeIdsFromCurrentDimensions = props.node.dimension_ids?.filter(did => dimensionsById.hasOwnProperty(did)).map(did => dimensionsById[did].pipeline_node_id) || [];


        if (props.node.dimension_ids) {
            requiredDimensionIds = requiredDimensionIds?.filter(did => requiredPipelineNodeIdsFromCurrentDimensions.includes(did));
        }


        return measures.data.filter(m => {
            if (props.node.measure_ids && props.node.measure_ids.includes(m.id as string)) {
                return true;
            }
            if (m.is_calculation) {
                // Make sure we have all of the measures it depends on
                if (!m.parent_measure_ids) {
                    return true;
                }
                return m.parent_measure_ids.every(pmid => props.node.measure_ids?.includes(pmid));
            }
            if (!m.dimension_join_trees) {
                return false;
            }

            if (requiredDimensionIds.length == 0) {
                return true;
            }
            
            const allConnectedPipelineNodeIdsForThisMeasure = m.dimension_join_trees.map(d => d.pipeline_node_id);
            return requiredDimensionIds.every(r => allConnectedPipelineNodeIdsForThisMeasure.includes(r));
        }).map(m => m.id as string);

    }, [props.node.dimension_ids, measures.dataUpdatedAt, dimensions.dataUpdatedAt, dimensionsById, measuresById, props.node.measure_ids]);

    const addExtraData = useCallback(() => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                draft.extra_report_columns = [];
            }
            draft.extra_report_columns.push({
                pipeline_node_id: '',
                field_id: '',
                column_label: '',
            });
        });
    }, [props.onChange]);

    const removeExtraData = useCallback((idx: number) => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                return;
            }
            draft.extra_report_columns.splice(idx, 1);
        });
    }, [props.onChange]);

    const editExtraDataField = useCallback((idx: number, attribute: keyof PipelineNodeExtraReportColumn, value: string) => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                return;
            }
            draft.extra_report_columns[idx][attribute] = value;
        });
    }, [props.onChange]);


    return <div>
        <Offcanvas backdrop="static" placement="end" show={editingDimension !== null}>
            <Offcanvas.Header>
                <Offcanvas.Title>{(editingDimension && editingDimension.id) ? 'Edit Dimension' : 'Add Dimension'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="p-3">
                    <DimensionForm 
                        dimension={editingDimension} 
                        onSubmit={saveDim}
                        onCancel={() => setEditingDimension(null)}
                    />

                </div>
            </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas backdrop="static" placement="end" show={editingMeasure !== null}>
            <Offcanvas.Header>
                <Offcanvas.Title>{(editingMeasure && editingMeasure.id) ? 'Edit Measure' : 'Add Measure'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                    <div className="p-3">
                    {editingMeasure && 
                        <MeasureForm 
                            measure={editingMeasure} 
                            onSubmit={saveMeasure}
                            onCancel={() => setEditingMeasure(null)}
                        />
                    }

                </div>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>
                    <h2>
                        <i className="mdi mdi-information-outline"></i> Basic Information
                    </h2>
                    <Form.Group className="mb-3">
                        <Form.Label>Report Name</Form.Label>
                        <Form.Control className="w-100" value={props.node.name} onChange={(e) => {
                            props.onChange(draft => {
                                draft.name = e.target.value;
                            });
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" className="w-100" value={props.node.description} onChange={(e) => {
                            props.onChange(draft => {
                                draft.description = e.target.value;
                            });
                        }}/>
                    </Form.Group>
                    <hr />
                    <h2>
                        <i className="mdi mdi-table-pivot"></i> Table Configuration
                    </h2>
                    <div className="row">
                        <div className="col-6 pe-3">
                            <div className="d-flex center-vertically mb-1">
                                <Form.Label className="flex-1 mb-0">Measures</Form.Label>
                                <button className="icon-button" onClick={() => setEditingMeasure({
                                    id: null,
                                    name: '',
                                    dimension_join_trees: [],
                                    description: '',
                                    column_name: '',
                                })}>
                                    <i className="mdi mdi-plus-circle"></i> Add New
                                </button>
                            </div>
                            {measures.isLoading && <div>
                                <i className="mdi mdi-loading mdi-spin"></i> Loading
                            </div>}
                            {!measures.isLoading && measures.data && <div className="list-group user-select-none">
                                
                                {measures.data.map(d => {
                                    const enabled = availableMeasureIds.includes(d.id as string);
                                    const selected = props.node.measure_ids && props.node.measure_ids.includes(d.id as string);
                                    return <div key={d.id} className={`list-group-item list-group-item-action ${!enabled ? 'text-muted disabled': ''} ${!enabled && selected ? 'bg-danger-light': ''}`} onClick={() => {
                                        props.onChange(draft => {
                                            if (!draft.measure_ids) {
                                                draft.measure_ids = [];
                                            }
                                            if (!selected) {
                                                draft.measure_ids.push(d.id as string);
                                            } else {
                                                draft.measure_ids = draft.measure_ids.filter(id => id !== d.id);
                                            }
                                        });
                                    }}>
                                        <div className="d-flex center-vertically hover-control">
                                            <div className="me-3">
                                                <div className="me-3 text-18">
                                                    {!selected && enabled && <i className="mdi mdi-check-circle text-white"></i>}
                                                    {!selected && !enabled && <i className="mdi mdi-cancel text-muted"></i>}
                                                    {selected && <i className="mdi mdi-check-circle text-success"></i>}
                                                    
                                                </div>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className={`mb-0 ${!enabled ? 'text-muted' : ''}`}>{d.name}</h4>
                                                {/* <div className="small">
                                                    <PipelineNodeName pipelineNodeId={d.pipeline_node_id}/> - <PipelineNodeFieldName pipelineNodeId={d.pipeline_node_id} fieldId={d.field_id}/>
                                                </div> */}
                                            </div>
                                            <div>
                                                <button className="btn btn-sm btn-outline-secondary hover-only" onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditingMeasure(d)
                                                }}>
                                                    <i className="mdi mdi-pencil"></i> Edit
                                                </button>

                                            </div>
                                        </div>
                                        
                                    </div>
                                })}
                            </div>}
                            
                        </div>
                        <div className="col-6 ps-3">
                            <div className="d-flex center-vertically mb-1">
                                <Form.Label className="flex-1 mb-0">Dimensions</Form.Label>
                                <button className="icon-button" onClick={() => setEditingDimension({
                                    id: null,
                                    name: '',
                                    pipeline_node_id: '',
                                    field_id: '',
                                    description: '',
                                    column_name: '',
                                })}>
                                    <i className="mdi mdi-plus-circle"></i> Add New
                                </button>
                            </div>
                            
                            {dimensions.isLoading && <div>
                                <i className="mdi mdi-loading mdi-spin"></i> Loading
                            </div>}
                            {!dimensions.isLoading && dimensions.data && <div className="list-group user-select-none">

                            
                                {dimensions.data.map(d => {
                                    const enabled = availableDimensionIds.includes(d.id as string);
                                    const selected = props.node.dimension_ids && props.node.dimension_ids.includes(d.id as string);
                                    return <div key={d.id} className={`list-group-item list-group-item-action ${!enabled ? 'text-muted disabled': ''} ${!enabled && selected ? 'bg-danger-light': ''}`} onClick={() => {
                                        props.onChange(draft => {
                                            if (!draft.dimension_ids) {
                                                draft.dimension_ids = [];
                                            }
                                            
                                            if (!selected) {
                                                draft.dimension_ids.push(d.id as string);
                                            } else {
                                                draft.dimension_ids = draft.dimension_ids.filter(id => id !== d.id);
                                            }
                                        });
                                    }}>
                                        <div className="d-flex center-vertically hover-control">
                                            <div className="me-3 text-18">
                                                {!selected && enabled && <i className="mdi mdi-check-circle text-white"></i>}
                                                {!selected && !enabled && <i className="mdi mdi-cancel text-muted"></i>}
                                                {selected && <i className="mdi mdi-check-circle text-success"></i>}
                                                
                                            </div>
                                            <div className="flex-1">
                                                <h4 className={`mb-0 ${!enabled ? 'text-muted' : ''}`}>{d.name}</h4>
                                                <div className="small text-muted">
                                                    <PipelineNodeName pipelineNodeId={d.pipeline_node_id}/> - <PipelineNodeFieldName pipelineNodeId={d.pipeline_node_id} fieldId={d.field_id}/>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn btn-sm btn-outline-secondary hover-only" onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setEditingDimension(d)
                                                }}>
                                                    <i className="mdi mdi-pencil"></i> Edit
                                                </button>

                                            </div>
                                        </div>
                                        
                                    </div>
                                })}
                            </div>}
                            
                        </div>
                        
                    </div>
                    {false && <>
                        <hr />
                        <div className="d-flex center-vertically mb-1">
                            <h2 className="flex-1 mb-0">
                                <i className="mdi mdi-note-plus"></i> Extra Data
                            </h2>
                            <button className="icon-button" onClick={addExtraData}>
                                <i className="mdi mdi-plus-circle"></i> Add Column
                            </button>
                        </div>
                        <p className="small">Bring in additional data from any of your dimensions.</p>
                        <table className="table table-fixed">
                            <thead>
                                <tr>

                                    <th style={{width: '25%'}}>Column Label</th>
                                    <th style={{width: '40%'}}>Node</th>
                                    <th style={{width: '25%'}}>Field</th>
                                    <th style={{width: '10%'}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.node.extra_report_columns?.map((c, idx) => {
                                    return <tr key={idx}>
                                        <td>
                                            <Form.Control value={c.column_label} onChange={(e) => {
                                                editExtraDataField(idx, 'column_label', e.target.value);
                                            }}/>
                                        </td>
                                        <td>
                                            <PipelineNodeSelector
                                                selectedId={c.pipeline_node_id}
                                                onSelect={(node) => {
                                                    editExtraDataField(idx, 'pipeline_node_id', node ? node.id as string : '');
                                                }}
                                                optionFilter={pn => dimensionPipelineNodeIds.includes(pn.id as string)}
                                            />
                                        </td>
                                        <td>
                                            <PipelineNodeColumnSelector
                                                selectedId={c.field_id}
                                                disabled={!c.pipeline_node_id}
                                                onSelect={(fieldId) => {
                                                    editExtraDataField(idx, 'field_id', fieldId);
                                                }}
                                                pipelineNodeId={c.pipeline_node_id}
                                            />
                                        </td>
                                        
                                        <td>
                                            <button className="icon-button text-18 text-end" onClick={() => {
                                                removeExtraData(idx);
                                            }}>
                                                <i className="mdi mdi-delete"></i>
                                            </button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </>}
                    <hr />
                    <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
                    <hr />
                    <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>
                    <div className="mb-5"></div>
                    
                </div>;
              
}
export default PipelineNodeReportBuilder