import AsyncButton from "@components/button/AsyncButton.component";
import Dropdown from "@components/form/Dropdown.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeRelationshipEditor from "@components/pipelineNodes/PipelineNodeRelationshipEditor.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import { DraftModeRequired, DraftOnly } from "@components/project/DraftModeRequired.component";
import { PipelineNode, PipelineNodeRelationship, PipelineNodeRelationshipORM } from "@models/pipelineNode";
import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import toast from "@services/toast.service";
import { useIsInDraftMode, usePipelineNodeRelationships, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown as BootstrapDropdown, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";

interface RelationshipAsItPertainsToThisPipelineNode {
    relationshipId: string;
    relatedPipelineNodeId: string;
    relationshipType: 'HAS_MANY' | 'BELONGS_TO';
    foreignKeyName: string;
    relationship: PipelineNodeRelationship;
}

const PipelineNodeRelationshipPage = () => {
    const { pipelineNodeId, relationshipId } = useParams();

    const inDraftMode = useIsInDraftMode();
    const pipelineNodes = usePipelineNodes();
    const pipelineNode = useMemo(() => {
        if (pipelineNodes.data) {
            return pipelineNodes.data.find(bo => bo.id === pipelineNodeId);
        }
    }, [pipelineNodeId, pipelineNodes.dataUpdatedAt]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [relationships, setRelationships] = useState<PipelineNodeRelationship[]>([]);

    const [parsedRelationships, setParsedRelationships] = useImmer<RelationshipAsItPertainsToThisPipelineNode[]>([]);

    const [saving, setSaving] = useState(false);

    const existingRelationships = usePipelineNodeRelationships(pipelineNodeId);

    useEffect(() => {
        if (relationshipId) {
            if (relationshipId == 'new') {
                setActiveRelationship({
                    id: null,
                    child_node_id: pipelineNodeId as string,
                    parent_node_id: '',
                    child_foreign_key_name: '',
                    name: '',
                    description: '',
                    parent_lookup_logic_gate: 'AND',
                    parent_lookups: [],
                });
            } else {
                if (existingRelationships.data) {
                    setActiveRelationship(undefined);
                }
                setActiveRelationship(existingRelationships.data?.find(r => r.id === relationshipId));
            }
        } else {
            setActiveRelationship(undefined);
        }
    }, [existingRelationships.dataUpdatedAt, relationshipId, pipelineNodeId])

    useEffect(() => {
        if (existingRelationships.data) {
            const relationshipsAsItPertainsToThisBusinessObject = existingRelationships.data.map((rel): RelationshipAsItPertainsToThisPipelineNode => {
                let relatedObjectId: string;
                let relationshipType: 'HAS_MANY' | 'BELONGS_TO';
                if (rel.parent_node_id === pipelineNodeId) {
                    relatedObjectId = rel.child_node_id;
                    relationshipType = 'HAS_MANY';
                } else {
                    relatedObjectId = rel.parent_node_id;
                    relationshipType = 'BELONGS_TO';
                }
        
                return {
                    relationshipId: rel.id as string,
                    relatedPipelineNodeId: relatedObjectId,
                    relationshipType: relationshipType,
                    foreignKeyName: rel.child_foreign_key_name ? rel.child_foreign_key_name : 'related_record_uuid',
                    relationship: rel,
                };
            });

            setParsedRelationships(relationshipsAsItPertainsToThisBusinessObject);
        } else {
            setParsedRelationships([]);
        }
    }, [existingRelationships.dataUpdatedAt])
    

    

    const navigate = useNavigate();
    const navigateToRelationship = useCallback((relationship: PipelineNodeRelationship) => {
        navigate(`/node/${pipelineNodeId}/relationships/${relationship.id}`);
    }, [navigate, pipelineNodeId]);

    const [activeRelationship, setActiveRelationship] = useImmer<PipelineNodeRelationship|undefined>(undefined);

    const onSaveRelationship = useCallback((relationshipId: string) => {
        setTimeout(() => {
            // We want the toast to remain; it'll go away if we navigate right after showing it so
            // run a setTimeout so it runs on the next tick.
            toast('success', 'Success', 'Relationship saved');
        });
        navigate(`/node/${pipelineNodeId}/relationships/${relationshipId}`)
        
    }, [pipelineNodeId]);

    const onDeleteRelationship = useCallback(() => {
        setTimeout(() => {
            toast('success', 'Success', 'Relationship deleted');

        })
        navigate(`/node/${pipelineNodeId}/relationships`)
        
    }, [pipelineNodeId]);


    const innerContent = useMemo(() => {
        if (!pipelineNodes.data) {
            return <div>
    
                <i className="mdi mdi-loading mdi-spin"></i>
            </div>
        }
        return <div className="d-flex" style={{height: '100%'}}>
                <div style={{width: '75%'}}>
                    <Pane>
                        <PaneContent>
                            <div className="pe-3">
                            <div className="d-flex mb-3 center-vertically">
                                <h2 className="mb-0 flex-1">
                                    Relationships
                                </h2>
                            </div>
                            <p>Set up relationships between staging tables and/or business objects to automatically create foreign keys. Hint: Pliable will tell you if you have any referential integrity issues.</p>
                            <h4>Existing Relationships</h4>
                            {parsedRelationships.length == 0 && (
                                <div className="mb-2">
                                    <strong>No relationships.</strong>. 
                                    <DraftOnly><Link to={`/node/${pipelineNodeId}/relationships/new`}>Create one</Link></DraftOnly>
                                </div>
                            )}
                            {parsedRelationships.map(rel => {
                                const relatedBo = pipelineNodes.data!.find(bo => bo.id === rel.relatedPipelineNodeId);
                                const active = rel.relationshipId == relationshipId;
                                return <div key={rel.relationshipId} onClick={() => navigateToRelationship(rel.relationship)} className={`shadow-box mb-2 p-2 action ${active ? 'active' : ''}`}>
                                    <h4>
                                        {rel.relationship.name || 'Unnamed Relationship'} (<span>
                                            {rel.relationshipType === 'BELONGS_TO' && <span>Belongs to</span>}
                                            {rel.relationshipType === 'HAS_MANY' && <span>Has many</span>}
                                        </span>: <strong>{relatedBo!.name}</strong>)
                                    </h4>
                                    <div className="font-13">{rel.relationship.description}</div>
                                    <div className="font-poppins mt-2">
                                        <div>
                                            <i className="mdi mdi-help-circle"></i> Ambiguous Parents: {summarizeNumber(rel.relationship.issue_ambiguous_records || 0)}
                                    </div>
                                        <div>
                                            <i className="mdi mdi-emoticon-sad"></i> Orphan Children: {summarizeNumber(rel.relationship.issue_orphan_records || 0)}
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            })}
                            </div>
                            

                        </PaneContent>
                    </Pane>
                    
                </div>
                <div style={{width:'30%'}} className="bg-white border-left">
                    {activeRelationship && pipelineNode && <>
                        <PipelineNodeRelationshipEditor
                            pipelineNode={pipelineNode}
                            relationshipId={activeRelationship.id || undefined}
                            onSave={onSaveRelationship}
                            onDelete={onDeleteRelationship}
                        />
                    </>}
                    {!activeRelationship && <div className="p-2">
                        No relationship selected
                    </div>}
                </div>

            </div>
    }, [pipelineNodeId, parsedRelationships, pipelineNodes.dataUpdatedAt, navigateToRelationship, activeRelationship]);

    return <PageStructure>
        <PageContent>
            <PipelineNodeSubnav pipelineNodeId={pipelineNodeId as string}>
                <div>
                    <DraftOnly>
                        <Link to={`/node/${pipelineNodeId}/relationships/new`} className="btn btn-lg btn-outline-primary">
                            <i className="mdi mdi-plus-circle"></i> Add New Relationship
                        </Link>
                    </DraftOnly>
                    
                </div>
            </PipelineNodeSubnav>
            <PageContentInner hasHeader>
                {innerContent}
            </PageContentInner>
        </PageContent>
    </PageStructure>;
    
}

export default PipelineNodeRelationshipPage;