import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import ORM, { BaseModel } from "./orm";
import BackgroundService from '@services/bg.service';
import { parseDateString } from '@services/time.service';
import { useQuery } from 'react-query';
import { queryClient } from '@stores/data.store';
import { FilterConfig } from './pipelineNode';


export interface Measure {
    field_id: string;
    aggregate_by: string;
    type?: string;
    color?: string;
    stroke?: string;
    fill?: string;
    formatter?: string;
}

export type ChartType = "area" | "line" | "bar" | "pie" | 'single_metric' | undefined;
export interface Visualization extends BaseModel {
    name: string;
    description: string;
    pipeline_node_id: string;
    dimension_field_id: string;
    dimension2_field_id?: string;
    measures: Measure[];
    chart_type: ChartType;
    filterable_field_ids?: string[];
    stacked?: boolean;
    dimension_sort_descending?: boolean;
    dimension2_sort_descending?: boolean;
}


export const VisualizationsORM = new ORM<Visualization>('visualizations', []);

export const useVisualizationsForPipelineNode = (pipelineNodeId: string) => {
    return useQuery(['visualizations', pipelineNodeId], async () => {
        const result = await VisualizationsORM.find({
            'pipeline_node_id': pipelineNodeId,
        });
        return result.records;
    });
}

export const useVisualizations = () => {
    return useQuery(['visualizations'], async () => {
        const result = await VisualizationsORM.find({});
        return result.records;
    });
}

export interface ApexChartData {
    series: {
        name: string;
        data: number[];
    }[];
    x_axis_categories: string[];
}

export const useChartData = (visualizationId: string, filters?: FilterConfig) => {
    
    const jsonFilters = JSON.stringify(filters);
    return useQuery(['chart_data', visualizationId, jsonFilters], async () => {
        if (!visualizationId) {
            return null;
        }
        const results = await ApiService.getInstance().request('GET', `/visualizations/${visualizationId}/data`, {
            filters: !!filters ? jsonFilters : '',
        }) as SingleRecordResponse<ApexChartData>;
        return results.record;
    }, {
        retry: 1,
    });
}

export const saveVisualization = async (visualization: Visualization) => {
    const result = await VisualizationsORM.save(visualization);
    queryClient.invalidateQueries(['visualization', visualization.id]);
    queryClient.invalidateQueries(['visualizations', visualization.pipeline_node_id]);
    queryClient.invalidateQueries(['chart_data', result.id]);
    return result;
}

export const deleteVisualization = async (vizId: string, pipelineNodeId: string) => {
    const result = await VisualizationsORM.deleteById(vizId);
    queryClient.invalidateQueries(['visualization', vizId]);
    queryClient.invalidateQueries(['visualizations', pipelineNodeId]);

}

export const useVisualization = (visualizationId: string) => {
    
    return useQuery(['visualization', visualizationId], async () => {
        if (!visualizationId) {
            return null;
        }
        const result = await VisualizationsORM.findById(visualizationId);
        return result;
    })
}