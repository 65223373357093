import { PageContentHeader } from "@pages/PageStructure.component";
import { ReactNode, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

interface Props {
    children?: ReactNode;
}

const DataModelSubnav = (props: Props) => {
    const location = useLocation();
    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/business-objects')) {
            return 'business-objects';
        } else if (path.endsWith('/sources')) {
            return 'sources';
        } else if (path.indexOf('/reporting') >= 0) {
            return 'reporting';
        }  else if (path.indexOf('/semantic-layer') >= 0) {
            return 'semantic-layer';
        }
        else if (path.indexOf('/dag') >= 0) {
            return 'dag';
        }

        return 'data';
    }, [location]);


    return <PageContentHeader>
        <div className="top">
            <div className="d-flex center-vertically mb-2">
                <div className="flex-1">
                    <h1 className="mb-0">
                        Data Model
                    </h1>
                </div>
                <div>{props.children}</div>
            </div>
        </div>
        <nav className="subnav">
            <Link to={`/model/sources`} className={`nav-link ${activePage == 'sources' ? 'active' : ''}`}>
                <i className="mdi mdi-database"></i> Data Sources
            </Link>
            <Link to={`/model/business-objects`} className={`nav-link ${activePage == 'business-objects' ? 'active' : ''}`}>
                <i className="mdi mdi-cube"></i> Business Objects
            </Link>
           
            <Link to={`/model/reporting`} className={`nav-link ${activePage == 'reporting' ? 'active' : ''}`}>
                <i className="mdi mdi-chart-line"></i> Reporting
            </Link>
            <Link to={`/model/semantic-layer`} className={`nav-link ${activePage == 'semantic-layer' ? 'active' : ''}`}>
                <i className="mdi mdi-layers"></i> Semantic Layer
            </Link>
            <Link to={`/model/dag`} className={`nav-link ${activePage == 'dag' ? 'active' : ''}`}>
                <i className="mdi mdi-pipe"></i> Full Pipeline
            </Link>
            
        </nav>
    </PageContentHeader>
}

export default DataModelSubnav;