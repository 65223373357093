import { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SingleValue } from "react-select";
import Select from "react-select";
export interface PromptModalProps {
    onConfirm: (value: string) => void;
    onClose: () => void;
    promptText: string;
    promptDefaultValue?: string;
    dropdownOptions?: { value: string, label: string }[]; 
    show?: boolean;
    header: string;
    useTextArea?: boolean;
}

const PromptModal = (props: PromptModalProps) => {
    const [val, setVal] = useState<string | { value: string, label: string }>('');
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        if (props.promptDefaultValue) {
            setVal(props.promptDefaultValue);
        } else if (props.dropdownOptions && props.dropdownOptions.length > 0) {
            setVal(props.dropdownOptions[0]);
        }
    }, [props.promptDefaultValue, props.dropdownOptions]);

    const close = useCallback(() => {
        setVal(props.promptDefaultValue ?? '');
        props.onClose();
    }, [props.onClose]);

    const confirm = useCallback(() => {
        if (props.dropdownOptions) {
            props.onConfirm((val as { value: string, label: string }).value);
        } else {
            props.onConfirm(val as string);
        }
    }, [val, props.onConfirm])

    const watchForEnterPress = useCallback((event: any) => {
        if (event.code == "Enter" && !props.dropdownOptions) {
            confirm()
        }
    }, [val, props.onConfirm])


    
    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton onClick={() => close()}>
                <Modal.Title>{props.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{props.promptText}</Form.Label>
                    {props.dropdownOptions ? (
                        <Select 
                            value={val as { value: string, label: string }} 
                            onChange={(selectedOption: SingleValue<{ value: string, label: string }>) => setVal(selectedOption!)} 
                            options={props.dropdownOptions} 
                            inputValue={inputValue}
                            onInputChange={(value) => setInputValue(value)}
                            onMenuOpen={() => {}}
                            onMenuClose={() => {}} 
                            autoFocus 
                        />
                    ) : (
                        <Form.Control 
                            value={val as string} 
                            as={props.useTextArea ? 'textarea' : 'input'} 
                            autoFocus 
                            onChange={(e) => setVal(e.target.value)} 
                            onKeyDown={watchForEnterPress} 
                        />
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Close</button>
                <button className="btn btn-pliable" onClick={() => confirm()}>Go</button>
            </Modal.Footer>
        </Modal>

    )
}

export default PromptModal;