import AsyncButton from "@components/button/AsyncButton.component";
import { loadSeed, regenerateSeed, saveSeed, useSeed } from "@models/seed";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { JobEnqueueResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { CellChange, ChangeSource } from "handsontable/common";
import { usePipelineNode } from "@stores/data.store";
import toast from "@services/toast.service";
import { useImmer } from "use-immer";
import produce from "immer";
import { getErrorMessage } from "@services/errors.service";
import SaveButton from "@components/button/SaveButton.component";


registerAllModules();

interface Props {
    seedId: string;
    seedData: string[][];
    seedHeaders: string[];
    onChangeData: (data: string[][]) => void;
}
const SeedEditor = ( props: Props) => {

    const tableDataChanged = useCallback((changes: CellChange[] | null, source: ChangeSource) => {
        if (changes) {
            // Create a copy
            const copied = props.seedData.map(row => row.map(cell => cell));
            changes.forEach(([rowIdx, colIdx, oldVal, newVal]) => {
                copied[rowIdx][colIdx as number] = newVal;
            });
            props.onChangeData(copied);
            
            
        }
    }, [props.seedData, props.onChangeData]);

    return <div>
        <HotTable
            data={props.seedData}
            stretchH="all"
            colHeaders={props.seedHeaders}
            height={220}
            afterChange={tableDataChanged}
            licenseKey="non-commercial-and-evaluation"
        />
        

    </div>
}

export default SeedEditor;

