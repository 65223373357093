import Dropdown, { Option } from "@components/form/Dropdown.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import { Visualization, useVisualizations } from "@models/visualization";
import { getErrorMessage } from "@services/errors.service";
import { getNodeColor, getNodeTypeConfig } from "@services/modeling.service";
import toast from "@services/toast.service";
import { usePipelineNodes } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props {
    selectedId: string;
    disabled?: boolean;
    onSelect: (visualization: Visualization|undefined) => void;
    blacklist?: string[];
    optionFilter?: (pn: Visualization) => boolean;
}

const VisualizationSelector = (props: Props) => {
    const visualizations = useVisualizations();
    const pipelineNodes = usePipelineNodes();
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    const filterfunc = useCallback((v: Visualization) => {
        if (props.blacklist && props.blacklist.includes(v.id as string)) {
            return false;
        }
        if (props.optionFilter && !props.optionFilter(v)) {
            return false;
        }

        return true;
    }, [props.optionFilter, props.blacklist]);

    const options = useMemo(() => {
        if (pipelineNodes.data && visualizations.data) {
            const pipelineNodesById: {
                [id: string]: string
            } = {};
            pipelineNodes.data.forEach(pn => {
                pipelineNodesById[pn.id as string] = pn.name;
            })
            const tmpOptions: Option[] = [];
            visualizations.data.filter(filterfunc).forEach(v => {
                const pnName = pipelineNodesById[v.pipeline_node_id];
                if (pnName) {
                    tmpOptions.push({
                        value: v.id as string,
                        label: v.name,
                        description: pnName,
                    });
                }
                
            });
            return tmpOptions;
        }
        
        return [];
    }, [pipelineNodes.dataUpdatedAt, visualizations.dataUpdatedAt, props.blacklist, filterfunc]);



    const onSelect = useCallback(async (opt: string) => {
        if (!opt) {
            props.onSelect(undefined);
        } else {
            props.onSelect(visualizations.data?.find(b => b.id === opt));
        }
    }, [visualizations.dataUpdatedAt]);

    let wrapperClass = '';

    

    return <div className={wrapperClass}>
        {(visualizations.isLoading || pipelineNodes.isLoading) && (
            <Spinner size="sm"/>
        )}
        {!visualizations.isLoading && !pipelineNodes.isLoading && (
            <>
                <Dropdown
                    disabled={!!props.disabled}
                    placeholder="Select visualization"
                    options={options}
                    selected={props.selectedId}
                    onChange={onSelect}
                />
            </>

            
            
        )}

    </div>
}

export default VisualizationSelector;