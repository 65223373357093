import { Option } from "@components/form/Dropdown.component";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineOrchestration from "@components/orchestration/PipelineOrchestration.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import { DataWhitelist, FilterConfig, PipelineNodeField, PipelineNodeORM } from "@models/pipelineNode";
import { ColumnSort } from "@models/pipelineNode";
import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import FilterConfigForm from "@pages/SourceRecordType/FilterConfigForm.component";
import DefaultSortForm from "@pages/SourceRecordType/DefaultSortForm.component";
import { requireConfirmation } from "@services/alert/alert.service";
import { ApiError } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import toast from "@services/toast.service";
import { invalidateEverything, useIsInDraftMode, useOutputTableForNode, usePipelineNode } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import { useCallback, useEffect, useMemo, useState, KeyboardEvent } from "react";
import { ButtonGroup, Form, Dropdown as BSDropdown, Button, Offcanvas, OffcanvasBody, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PipelineNodeColumnOrder from "@components/pipelineNodes/PipelineNodeColumnOrder.component";
import { DraftModeRequired } from "@components/project/DraftModeRequired.component";
import DataWhitelistForm from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import PipelineNodeExtrasMenu from "@components/pipelineNodes/PipelineNodeExtrasMenu.component";
import LoadingCard from "@components/card/LoadingCard.component";


const PipelineNodeDataPage = () => {
    const { pipelineNodeId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get('q') || '';

    const [searchInput, setSearchInput] = useState(query);

    const [whitelist, setWhitelist] = useState<DataWhitelist>({
        entries: [],
        logic_gate: 'AND',
    })
    
    const [includeInSnapshots, setIncludeInSnapshots] = useState(false);
    const [incrementalBuild, setIncrementalBuild] = useState(false);
    const [incrementalTimestampFieldId, setIncrementalTimestampFieldId] = useState('');
    const [alternateIdentifierId, setAlternateIdentifierId] = useState('');
    const [associatedSourceId, setAssociatedSourceId] = useState('');

    const { pageDirty, setPageDirty } = useGlobalState();

    const [compositeKeySelection, setCompositeKeySelection] = useState<string[]>([]);
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);
    const [lastModifiedColumnSelection, setLastModifiedColumnSelection] = useState<string>('');
    const [fields, setFields] = useState<PipelineNodeField[]>([]);
    const [showColumn, setShowColumn] = useState<boolean>(false);

    useEffect(() => {
        if (pipelineNode.data && pipelineNode.data.data_whitelist) {
            setWhitelist(pipelineNode.data.data_whitelist);
        } else {
            setWhitelist({
                entries: [],
                logic_gate: 'AND',
            })
        }

        if (pipelineNode.data && pipelineNode.data.default_sort) {
            setDefaultSorts(pipelineNode.data.default_sort);
        }else{
            setDefaultSorts([]);
        }

        if (pipelineNode.data && pipelineNode.data.fields) {
            setFields(pipelineNode.data.fields);
        }else{
            setFields([]);
        }


        if (pipelineNode.data) {
            setIncludeInSnapshots(!!pipelineNode.data.include_in_snapshots);
            setAlternateIdentifierId(pipelineNode.data.alternate_identifier_field_id || '');
            
            if (pipelineNode.data.shape) {
                setCompositeKeyOptions(pipelineNode.data.shape.columns.map(c => {
                    return {
                        value: c.key,
                        label: c.key,
                        // description: tc.type,
                    }
                }));
            }

            if (['SOURCE', 'SOURCE_FILE'].includes(pipelineNode.data.node_type)) {
                setAssociatedSourceId(pipelineNode.data.source_id || '');
            }
            setIncrementalBuild(!!pipelineNode.data.incremental_build);

            if (pipelineNode.data.incremental_timestamp_field_id) {
                setIncrementalTimestampFieldId(pipelineNode.data.incremental_timestamp_field_id);
            } else {
                setIncrementalTimestampFieldId('');
            }
    
            if (pipelineNode.data.composite_key) {
                setCompositeKeySelection(pipelineNode.data.composite_key);
            }
    
            if (pipelineNode.data.last_modified_column) {
                setLastModifiedColumnSelection(pipelineNode.data.last_modified_column);
            }
        }
        
    }, [pipelineNode.dataUpdatedAt]);

    const navigate = useNavigate();

    const [building, setBuilding] = useState(false);
    
    const [applyingFilters, setApplyingFilters] = useState(false);

    const onChangeWhitelist = useCallback((newWhitelist: DataWhitelist) => {
        setWhitelist(newWhitelist);
    }, []);

    const [defaultSorts, setDefaultSorts] = useState<ColumnSort[]>([]);
    
    useEffect(() => {
        setSearchInput(query);
    }, [query]);

    const onColumnToggle = useCallback((field: PipelineNodeField) => {
        if (!pipelineNode.data) return;
        const updatedShowColumn = field.show_column ?? false;
        setShowColumn(updatedShowColumn);
    }, [pipelineNode.data]);
    
    const onNodeFieldChange = useCallback((newFields: PipelineNodeField[]) => {
        if (!pipelineNode.data) return;
        setFields(newFields);
    }, [pipelineNode.data]);
    
    const saveColumnView = useCallback(async (newFields: PipelineNodeField[]) => {
        try {
            setFields(newFields);
            const selectedField = newFields.find(field => field.id === pipelineNodeId);
            if (selectedField) {
                setShowColumn(selectedField.show_column ?? false);
            }
            await PipelineNodeORM.patch(pipelineNodeId as string, {
                fields: newFields,
            });
            await pipelineNode.refetch();
            setShowColumnOrder(false)
            setPageDirty(false);
        } catch (error) {
            toast('error', 'Error', 'Failed to save column view.');
        }
    }, [pipelineNodeId, pipelineNode, fields]);

    const applyDefaultSorts = useCallback(async () => {
        try {
            await PipelineNodeORM.patch(pipelineNodeId as string, {
                'default_sort': defaultSorts,
            });
            await pipelineNode.refetch();
            setPageDirty(false);
        } catch (error) {
            toast('error', 'Error', 'Failed to apply sorts.');
        }
    }, [pipelineNodeId, defaultSorts, pipelineNode]);
    

    const onToggleIncludeInSnapshots = useCallback(async () => {
        const newValue = !includeInSnapshots;
        setIncludeInSnapshots(newValue);

        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'include_in_snapshots': newValue,
        })
    }, [pipelineNodeId, includeInSnapshots]);

    const onChangeAlternateIdentifier = useCallback(async (fieldId: string|undefined) => {
        setAlternateIdentifierId(fieldId || '');
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'alternate_identifier_field_id': fieldId || ''
        })
    }, [pipelineNodeId]);

    const onChangeAssociatedSource = useCallback(async (sourceId: string|undefined) => {
        setAssociatedSourceId(sourceId || '');
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'source_id': sourceId || ''
        })
    }, [pipelineNodeId]);

    const onChangeIncrementalBuild = useCallback(async (newVal: boolean) => {
        setIncrementalBuild(newVal);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'incremental_build': newVal,
        });
    }, [pipelineNodeId]);

    const onChangeIncrementalTimestampFieldId = useCallback(async (newVal: string) => {
        setIncrementalTimestampFieldId(newVal);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'incremental_timestamp_field_id': newVal,
        });
    }, [pipelineNodeId]);

    const applyWhitelist = useCallback(async () => {
        setApplyingFilters(true);
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            'data_whitelist': whitelist, 
        });
        setPageDirty(false);

        const shouldRebuild = await requireConfirmation('Pliable needs to rebuild this node to see the results of your whitelist. Do you want to rebuild now?', 'Rebuild Required');

        if (shouldRebuild) {
            await runBuild('THIS');
        }

        pipelineNode.refetch();
        setApplyingFilters(false);
    }, [pipelineNodeId, whitelist]);

    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>();

    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        const newStatuses: {
            [key: string]: BuildExecution
        } = {};
    
        Object.keys(activeOrchestration.build_executions).map(k => {
            const be = activeOrchestration.build_executions[k];
            newStatuses[`${be.object_type}:${be.object_id}`] = be;
        });

        
        if (!['ERROR', 'COMPLETE', 'IN_REVIEW'].includes(activeOrchestration.status)) {
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setActiveOrchestration(undefined);
            toast('success', 'Success', 'Models have been successfully updated.');
            invalidateEverything();
            setBuilding(false);
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
            setBuilding(false);
        }

    }, [activeOrchestration]);

    const runBuild = useCallback(async (selector: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL', force: boolean = false) => {
        setActiveOrchestration(undefined)
        
        if (!pipelineNode.data) {
            return;
        }
        try {
            setBuilding(true);
            let selectorValue: string = '';
            switch (selector) {
                case 'THIS':
                    selectorValue = pipelineNode.data.name;
                    break;
                case 'UPSTREAM':
                    selectorValue = '+' + pipelineNode.data.name;
                    break;
                case 'DOWNSTREAM':
                    selectorValue = pipelineNode.data.name + '+';
                    break;
                case 'ALL':
                    selectorValue = '+' + pipelineNode.data.name + '+'
                    break;
            }
            const orchestration = await BuildOrchestrationORM.buildWithSelector(selectorValue, false, force);

            if (orchestration.status == 'COMPLETE') {
                toast('info', 'Everything up to date', 'No need to rebuild.');
                setBuilding(false);
              
            } else {
                setActiveOrchestration(orchestration);
            }

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [pipelineNodeId, pipelineNode.dataUpdatedAt, fields]);

    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);

    const isInDraft = useIsInDraftMode();

    const nodeTypeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return;
        }

        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const outputTable = useOutputTableForNode(pipelineNodeId);

    const inDraftMode = useIsInDraftMode();

    const [showConfig, setShowConfig] = useState(false);
    const [showColumnOrder, setShowColumnOrder] = useState(false);

    const checkForSearchEnterKey = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode == 13) {
            setSearchParams({q: searchInput});
        }
    }, [searchInput]);

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadLocation, setDownloadLocation] = useState('');
    const [downloadError, setDownloadError] = useState('');

    const downloadNodeData = useCallback(async () => {
        if (!pipelineNode.data) {
            return;
        }
        setDownloadError('');
        setShowDownloadModal(true);
        setLoadingDownload(true);
        setDownloadLocation('');

        try {
            const download = await PipelineNodeORM.queueDownloadJob(pipelineNodeId as string, searchInput || '');
            setDownloadLocation(download.url);
            
        } catch(err_msg) {
            setDownloadError(getErrorMessage(err_msg));
            console.log(err_msg)
        } finally {
            setLoadingDownload(false);
        }
        
    }, [pipelineNode.data, pipelineNodeId, searchInput])

    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }
    return <PageStructure>
        <PageContent>
            <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Download</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {loadingDownload && <div>
                            <LoadingCard action='Preparing your download' />
                        </div>}
                    {!loadingDownload && downloadError && (
                        <div className="alert alert-danger">{downloadError}</div>
                    )}
                    {!loadingDownload && downloadLocation && !downloadError && (
                        <div>
                            <span style={{fontSize: 55}}><i className="mdi mdi mdi-download"></i></span><br />
                            <a target="_blank" className="btn btn-pliable" href={downloadLocation} >Click here to download your file.</a>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            <PipelineNodeSubnav
                pipelineNodeId={pipelineNodeId as string}
            >
                <div>
                    <input type="text" className="input-lg form-control d-inline-block me-3 input-rounded" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} onKeyDown={checkForSearchEnterKey} style={{width: '500px'}}/>
                    <button className="me-1 btn btn-lg btn-outline-primary" onClick={downloadNodeData}>
                        <i className="mdi mdi-download"></i> Download
                    </button>
                    <button className="btn btn-lg btn-pliable" onClick={() => {
                        runBuild('THIS');
                    }}>
                        <i className="mdi mdi-hammer"></i> Build
                    </button>
                    <PipelineNodeExtrasMenu 
                        pipelineNodeId={pipelineNodeId as string}
                        onForceBuild={() => runBuild('THIS', true)}
                    />

                </div>
                
            </PipelineNodeSubnav>
            <PageContentInner hasHeader>
                <Offcanvas show={showConfig} onHide={() => setShowConfig(false)} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Output Settings</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Pane>
                            <PaneContent>
                                    {nodeTypeConfig && <>
                                        <div className="p-3">
                                            <div>Data for this node is available in<br /><code>{outputTable.data ? outputTable.data : ''}</code></div>
                                            
                                            
                                        </div>
                                        {pipelineNode.data && (
                                            <>
                                                <h2 className="sidebar-section-header">Output Configuration</h2>

                                                <div className="p-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            disabled={!inDraftMode}
                                                            label="Record daily snapshots of this node"
                                                            checked={!!includeInSnapshots}
                                                            reverse
                                                            onChange={onToggleIncludeInSnapshots}
                                                        />
                                                        <Form.Text className="text-muted">
                                                            Snapshots are updated after each <Link to="/account/build-schedule">scheduled build <i className="mdi mdi-information-outline"></i></Link>
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <h4>Default Filters</h4>
                                                    <div className="mb-3">
                                                        <DataWhitelistForm
                                                            config={whitelist}
                                                            nodeIds={[pipelineNodeId as string]}
                                                            onChange={onChangeWhitelist}

                                                        
                                                        />
                                                        <button disabled={!inDraftMode} onClick={applyWhitelist} className="btn btn-outline-secondary mt-2">Apply</button>
                                                    </div>
                                                    <h4>Default Sort</h4>
                                                    <div className="mb-3">
                                                        <DefaultSortForm
                                                            fields={pipelineNode.data.fields}
                                                            currentSort={defaultSorts}
                                                            onChange={setDefaultSorts}
                                                        />
                                                        <button onClick={applyDefaultSorts} className="btn btn-outline-secondary">Apply Sort</button>    
                                                    </div>
                                                </div>
                                                <h2 className="sidebar-section-header">Report Configuration</h2>
                                                <div className="p-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Record Identifier</Form.Label>
                                                        <PipelineNodeColumnSelector
                                                            pipelineNodeId={pipelineNodeId as string}
                                                            selectedId={alternateIdentifierId}
                                                            onSelect={onChangeAlternateIdentifier}
                                                            disabled={!inDraftMode}
                                                        />
                                                        <Form.Text>Choose a column Pliable will use to identify this record (for example in reports). If this isn't set, we will default to the internal <code>Record ID</code> column</Form.Text>
                                                    </Form.Group>
                                                </div>
                                                <h2 className="sidebar-section-header">Other Configuration</h2>
                                                {pipelineNode.data.node_type == 'SOURCE' && <>
                                                    <div className="p-3">
                                                        <Form.Group>
                                                            <Form.Label>Associated Data Source</Form.Label>
                                                            <SourceSelector
                                                                onSelect={onChangeAssociatedSource}
                                                                selectedId={associatedSourceId}
                                                                disabled={!inDraftMode}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </>}
                                                {['SOURCE', 'SOURCE_FILE', 'STACK', 'MERGE', 'DIMENSION'].includes(pipelineNode.data.node_type) && <div className="p-3">
                                                    <Form.Group className="mb-2">
                                                        <Form.Check
                                                            type="switch"
                                                            checked={incrementalBuild}
                                                            onChange={(e) => onChangeIncrementalBuild(e.target.checked)}
                                                            label="Incremental Builds"
                                                            disabled={!inDraftMode}
                                                        />
                                                        <Form.Text>
                                                            When enabled, Pliable will only update records that have changed since the last build.
                                                        </Form.Text>

                                                    </Form.Group>
                                                    {incrementalBuild && ['STACK', 'MERGE', 'DIMENSION'].includes(pipelineNode.data.node_type) && <Form.Group className="mb-2">
                                                        <Form.Label>Incremental Timestamp Field</Form.Label>
                                                        <PipelineNodeColumnSelector
                                                            pipelineNodeId={pipelineNodeId as string}
                                                            selectedId={incrementalTimestampFieldId}
                                                            onSelect={onChangeIncrementalTimestampFieldId}
                                                            disabled={!inDraftMode}
                                                        />
                                                        <Form.Text>Select the column to use for the "last updated" value for incremental builds. Any record for which this column is later than the current maximum value will be processed with each build.</Form.Text>
                                                    </Form.Group>}
                                                </div>}
                                                
                                            </>
                                            
                                        )}
                                    </>}
                                    
                                    
                            </PaneContent>
                        </Pane>
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={showColumnOrder} onHide={() => setShowColumnOrder(false)} placement="end">
                    <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Order and Visibility</Offcanvas.Title>
                    </Offcanvas.Header>
                    <OffcanvasBody>
                        <Pane>
                            <PaneContent>
                                <PipelineNodeColumnOrder
                                    fields={fields}
                                    shape={pipelineNode.data?.shape as any}
                                    onClickColumn={onColumnToggle}
                                    onChangeNodeField={onNodeFieldChange}
                                    onSave={saveColumnView}
                                />    
                            </PaneContent>
                        </Pane>                
                    </OffcanvasBody>
                </Offcanvas>
                            
            <Pane>
                <PaneContent noScroll>
                    {(building || (activeOrchestration?.status == 'ERROR')) && ( 
                        <div className="p-3">
                            {activeOrchestration?.status == 'ERROR' && (
                                <button className="btn btn-primary" id="btn-close-orc" onClick={() => setActiveOrchestration(undefined)}>Go Back</button>
                            )}
                            {activeOrchestration && <PipelineOrchestration orchestration={activeOrchestration}/>}
                        </div>
                    )}

                    {!building && activeOrchestration?.status !== 'ERROR' && (
                        <PipelineNodeDataTable
                            defaultSorts={defaultSorts}
                            pipelineNodeId={pipelineNodeId as string}
                            showLineageButton
                            searchQuery={query}
                        />
                    )}
                </PaneContent>
            </Pane>
            </PageContentInner>
        </PageContent>
    </PageStructure>

}

export default PipelineNodeDataPage;