import { useQuery } from "react-query";
import ORM, { BaseModel } from "./orm";
import { DataWhitelist, PipelineNodeMeasureJoinTree } from "./pipelineNode";

export interface ReportBuilderDimension extends BaseModel {
    name: string;
    pipeline_node_id: string;
    field_id: string;
    description: string;
    column_name: string;
}

export const ReportBuilderDimensionORM = new ORM<ReportBuilderDimension>('dimensions', []);

export const useDimensions = () => {
    return useQuery(['dimensions'], async () => {
        const result = await ReportBuilderDimensionORM.find({});
        return result.records;
    });
}

export interface ReportBuilderDimensionJoinTree {
    pipeline_node_id: string;
    join_tree: PipelineNodeMeasureJoinTree|null;
    label?: string;

}

export interface ReportBuilderMeasure extends BaseModel {
    name: string;
    description: string;
    column_name: string;

    is_calculation?: boolean;
    pipeline_node_id?: string;
    field_id?: string;
    aggregator?: string;
    formatter?: string;
    data_whitelist?: DataWhitelist;
    dimension_join_trees?: ReportBuilderDimensionJoinTree[];

    formula?: string;
    parent_measure_ids?: string[];
}

export const ReportBuilderMeasureORM = new ORM<ReportBuilderMeasure>('measures', []);

export const useMeasures = () => {
    return useQuery(['measures'], async () => {
        const result = await ReportBuilderMeasureORM.find({});
        return result.records;
    });
}