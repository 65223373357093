import { getErrorMessage } from '@services/errors.service';
import TenantService from '@services/tenant/tenant.service';
import TenantConfigService from '@services/tenantConfig.service';
import AsyncButton from '@components/button/AsyncButton.component';
import produce from 'immer';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Collapse, Modal, Offcanvas, ProgressBar, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import { Link, useNavigate, useParams } from 'react-router-dom';
import BackgroundService from '@services/bg.service';
import useGlobalState from '@stores/global.state';
import pliablemark from '@assets/images/pliable/brand_mark_orange.png';
import snowflakemark from '@assets/images/snowflake_logo.png';
import dbtmark from '@assets/images/dbt.png';
import styled, { css } from 'styled-components';
import ApiService from '@services/api/api.service';
import toast from '@services/toast.service';
import PageStructure, { Main, PageContent, PageContentNoSidebar, PageSidebar, Pane, PaneContent, PaneContentSidebar } from '@pages/PageStructure.component';
import { useHotkeys } from 'react-hotkeys-hook';
import { enterDraftMode, invalidateEverything, queryClient, toggleThenaWidget } from '@stores/data.store';
import ConfigService, { SurveyAnswers, SurveyDataSources } from '@services/config/config.service';
import { Updater, useImmer } from 'use-immer';
import SnowflakeMenuScreen from '@assets/images/onboarding/snowflake_menu.png';
import SnowflakeMenuScreen2 from '@assets/images/onboarding/snowflake_menu2.png';
import SnowflakeMenuScreen3 from '@assets/images/onboarding/snowflake_menu3.png';

import HubspotLogo from '@assets/images/connectors/hubspot.png';
import PipedriveLogo from '@assets/images/connectors/pipedrive.png';
import SalesforceLogo from '@assets/images/connectors/salesforce.png';
import MetaLogo from '@assets/images/connectors/meta.png';
import GoogleAdsLogo from '@assets/images/connectors/google_ads.png';
import BingAdsLogo from '@assets/images/connectors/bing-ads.png';
import LinkedInLogo from '@assets/images/connectors/linkedin.png';
import BrazeLogo from '@assets/images/connectors/braze.png';
import IterableLogo from '@assets/images/connectors/iterable.png';
import KlaviyoLogo from '@assets/images/connectors/klaviyo.png';
import MailchimpLogo from '@assets/images/connectors/mailchimp.png';
import SendgridLogo from '@assets/images/connectors/sendgrid.png';
import TwilioLogo from '@assets/images/connectors/twilio.png';
import ShopifyLogo from '@assets/images/connectors/shopify.png';
import EtsyLogo from '@assets/images/connectors/etsy.png';
import AmazonLogo from '@assets/images/connectors/amazon.png';
import RipplingLogo from '@assets/images/connectors/rippling.png';
import GustoLogo from '@assets/images/connectors/gusto.png';
import NetsuiteLogo from '@assets/images/connectors/netsuite.png';
import SapLogo from '@assets/images/connectors/sap.png';
import GoogleAnalyticsLogo from '@assets/images/connectors/google-analytics.png';
import PendoLogo from '@assets/images/connectors/pendo.png';
import RudderstackLogo from '@assets/images/connectors/rudderstack.png';
import PliableLogo from '@assets/images/pliable/logo_with_text_black.png';
import Danger from '@components/statusIndicators/Danger.component';
import SuccessAlert from '@components/statusIndicators/SuccessAlert.component';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useAuthState } from '@services/auth/auth.service';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from 'react-query';
import moment from 'moment';
import PliableLoader from '@components/loaders/PliableLoader.component';
import Dropdown from '@components/form/Dropdown.component';
import InfoAlert from '@components/statusIndicators/InfoAlert.component';
import BrandMark from '@assets/images/pliable/brand_mark_orange.svg';
import TrackingService from '@services/tracking.service';
import TenantPicker from '@components/tenant/TenantPicker.component';
export const ButtonGrid = styled.div`
    button {
        
        display: inline-block;
        margin: .5rem;
        background-color: white;
        font-weight: 300;
        font-family: "Poppins";
        padding: 0px 1rem;

        border: 1px solid #D9D9D9;
        border-radius: 10px;
        box-shadow: 0px 2px 3px 0px #0000001A;
        height: 50px;
        line-height: 50px;

        .inner {
            display: flex;
            align-items: center;
        }

        
        img {
            max-width: 100px;
            max-height: 90%;
        }

        &.active {
            color: white;
            background-color: var(--ct-primary);

            i {
                color: white !important;
            }

            img {
                filter: brightness(0) invert(1);
            }
            &:hover {
                background-color: var(--ct-primary) !important;
            }
        }

        &:hover {
            background-color: #eee;
        }

    }
`


interface CalendlyEventInfoData {
    resource: {
        name: string;
        start_time: string;
        end_time: string;
        location: {
            join_url: string;
        }
    }
}

interface CalendlyEventInfoProps {
    eventUuid: string;
}

const useCalendlyEventInfo = (eventUuid: string) => {
    return useQuery(['calendly_onboarding_event', eventUuid], async () => {
        const info = await ApiService.getInstance().request('GET', '/calendly-event') as CalendlyEventInfoData;
        console.log('Event info:', info);
        return info;

    });
}

const CalendlyEventInfo = ({ eventUuid } : CalendlyEventInfoProps) => {
    const eventInfo = useCalendlyEventInfo(eventUuid);

    if (eventInfo.data) {
        return <>
            <h4>{moment(eventInfo.data?.resource.start_time).format('MMMM Do, YYYY')} at {moment(eventInfo.data?.resource.start_time).format('h:mm a')}</h4>
            <a href={eventInfo.data?.resource.location.join_url}>Join Zoom Meeting</a>
        </>
    }
    
    return <PliableLoader/>
}

const HeaderImage = styled.img`
max-height: 50px;
`
interface SnowflakeDetails {
    host: string;
    username: string;
    password: string;
    database: string;
    query_warehouse: string;
    loading_warehouse: string;
    build_warehouse: string;
    role: string;
    loader_user?: string;
    loader_password?: string;
    loader_database?: string;
    loader_role?: string;
}

const NavBar = styled.div`
    height: 50px;
    border-bottom: solid 1px var(--ct-border-color);
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    img {
        max-height: 30px;
    }
`

const ProgressBarWrapper = styled.div`
height: 10px;
width: 100%;

div.progress {
    border-radius: 0px;
    height: 10px;
}
`

const StepHeaderStyles = styled.div<{active?: boolean;}>`
display: flex;

margin-bottom: 20px;

h2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
}

h3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.step-number {
    width: 25px;
    height: 25px;
    border: 2px solid #00A1E0;
    border-radius: 13px;
    color: #00A1E0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;


}

&.active {
    .step-number {
        color: #fff;
        background-color: #00A1E0;
    }
}

&.complete {
    .step-number {
        color: #fff;
        background-color: var(--ct-success);
    }
}
`

const AnswerReview = styled.div`
border: solid 1px var(--ct-border-color);
font-size: 18px;
border-radius: 5px;
padding: 1rem;
`

interface DbtIconProps {
    className?: string;
}


const DbtIcon = (props: DbtIconProps) => {
    return <img className={props.className} style={{maxWidth: '15px'}} src={dbtmark}/>
}

const DBTInfo = (props: PropsWithChildren) => {
    return <div className="mb-1 font-13">
        <span className="me-1"><DbtIcon/></span> {props.children}
    </div>
}

const HelpStyles = styled.div`
img {
    max-width: 100%;
}
`;





function isValidSnowflakeURL(url: string): boolean {
    if(!!url){
        // The URL should contain the account name followed by ".snowflakecomputing.com/"
        const regex = /^([^.]+\.)?([^.]+\.)([^.]+\.)?snowflakecomputing\.com$/;
        const match = url.match(regex);
        if (match !== null) {
        return true;
        }
    }
    return false;
  }

interface SnowflakeFormProps {
    snowflakeDetails: SnowflakeDetails;
    tenantConfigId: string;
    showPliableHostedSnowflakeCode: boolean;
    onChange: (attr: keyof SnowflakeDetails, value: any) => any;
    onCredentialsAreValid: () => any;
}

const SnowflakeSetupForm = ({ snowflakeDetails, onChange, tenantConfigId, onCredentialsAreValid, showPliableHostedSnowflakeCode }: SnowflakeFormProps) => {
    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const [savingConfig, setSavingConfig] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [showScriptModal, setShowScriptModal] = useState(false);
    const [activationCode, setActivationCode] = useState('');
    const [creatingHostedSnowflake, setCreatingHostedSnowflake] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');

    const generateScript = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().showSetupScript(tenantConfigId);
            setSetupSql(snowflake_setup_sql);
            setShowScriptModal(true);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const saveSnowflakeDetails = useCallback(async (doGenerateScript: boolean = false) => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            if (generateScript) {
                await generateScript();
            }
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const createPliableHostedSnowflake = useCallback(async () => {
        setCreatingHostedSnowflake(true);

        try {
            await TenantConfigService.getInstance().createHostedSnowflake(tenantConfigId, activationCode);
            ConfigService.getInstance().loadConfig(); // need to flush tenant config
            setCredentialsAreValid(true);
            onCredentialsAreValid();
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingHostedSnowflake(false);
        }
    }, [activationCode, tenantConfigId]);


    const [useDBT, setUseDBT] = useState<boolean|undefined>(undefined);
    
    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        setCredentialsAreValid(false);
        setCredentialsError('');
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            const {job_id} : any = await TenantConfigService.getInstance().initInfrustructure(tenantConfigId);
            const {infra} : any = await BackgroundService.getInstance().waitForJob(job_id);
            if (infra.is_ready) {
                setCredentialsAreValid(true);
                onCredentialsAreValid();
            } else {
                throw new Error('Error setting up infrastructure.');
            }
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            setCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);
    const [showAll, setShowAll] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    return <div style={{textAlign: 'left'}}>
        <Modal show={showScriptModal} size="lg" onHide={() => setShowScriptModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Script</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Log into your Snowflake account and run the following script in a new worksheet. Make sure "All Queries" is checked. When you're done, close this window and click on "Validate Connection".
                </p>
                <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                    <CopyBlock
                        text={setupSql}
                        language="sql"
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
        <Offcanvas show={showHelp} onHide={() => setShowHelp(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Help</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <HelpStyles className="p-3">
                            <p>To get your Snowflake host name:</p>
                            <h4>1. Open the account menu at the bottom left-hand side of the screen</h4>
                            <img src={SnowflakeMenuScreen} className="mb-3"/>
                            <h4>2. Hover over "Account", and then hover over the currently active account in the pop-out menu.</h4>
                            <p>You may have to scroll down if you have several Snowflake accounts.</p>
                            <img src={SnowflakeMenuScreen2} className="mb-3"/>
                            <h4>3. Click on the "Copy Account URL" button and paste it into Pliable</h4>
                            <img src={SnowflakeMenuScreen3} className="mb-3"/>
                            
                        </HelpStyles>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>

        {showPliableHostedSnowflakeCode && <>
            <h4>Let Pliable create a Snowflake account for me.</h4>
            <p className="text-muted font-13">Your Pliable account will incure additional charges based off your usage of snowflake.</p>

            <Form.Group className="mb-3">
                <Form.Label className="small">Activation Code</Form.Label>
                <Form.Control
                    name="activation_code"
                    type="password"
                    placeholder=""
                    value={activationCode} 
                    onChange={(e) => setActivationCode(e.target.value)}
                />
                <Form.Text></Form.Text>
            </Form.Group>
            <hr />

            {!credentialsAreValid && 
                <AsyncButton
                    variant="pliable"
                    icon='mdi mdi-database-edit-outline'
                    disabled={!activationCode}
                    loading={creatingHostedSnowflake}
                    onClick={() => createPliableHostedSnowflake()}
                    text="Create Snowflake Account"
                />
            }

            {creatingHostedSnowflake && (
                <div>
                    <Form.Text>
                        Setting up new Snowflake account for you. This may take up to 5 minutes.
                    </Form.Text>
                </div>

            )}

        </>}

        {!showPliableHostedSnowflakeCode && <>
            <p className="text-muted">We'll use these details to generate a script for you to run on your Snowflake account, which will create all of the resources Pliable needs to operate. The only required field is the <strong>host</strong>.</p>

            <Form.Group className="mb-3">
                <Form.Label>Host</Form.Label>
                <Form.Control
                    name="host"
                    type="text"
                    placeholder="your-account.snowflakecomputing.com"
                    value={snowflakeDetails.host}
                    isValid={validHost} 

                    onChange={(e) => onChange('host', e.target.value)}
                />
                <Form.Text>
                    <a role="button" href="#" onClick={() => setShowHelp(true)}>Where do I find this?</a>
                </Form.Text>
            </Form.Group>
            <div className="mb-2">

                <button className="btn btn-light" onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide' : 'Show'} Advanced Config
                </button>
            </div>
            
            <Collapse in={showAll}>
                <div>
                <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    
                    <Form.Control
                        name="username"
                        type="text"
                        placeholder=""
                        value={snowflakeDetails.username}

                        onChange={(e) => onChange('username', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        name="password"
                        type="password"
                        placeholder=""
                        value={snowflakeDetails.password}

                        onChange={(e) => onChange('password', e.target.value)}
                    />
                    <Form.Text>Leave blank and we will automatically generate one for you.</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Database</Form.Label>
                    <div></div>
                    <Form.Control
                        name="database"
                        type="text"
                        placeholder=""
                        value={snowflakeDetails.database}

                        onChange={(e) => onChange('database', e.target.value)}
                    />
                    <Form.Text>
                        Enter the name of the database where Pliable should store its data
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Warehouse (Building)</Form.Label>
                    
                    <Form.Control
                        name="build_warehouse"
                        type="text"
                        placeholder=""
                        value={snowflakeDetails.build_warehouse}

                        onChange={(e) => onChange('build_warehouse', e.target.value)}
                    />
                    <Form.Text>
                        Enter the name of the warehouse Pliable should use for building your models.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Warehouse (Querying)</Form.Label>
                    <Form.Control
                        name="query_warehouse"
                        type="text"
                        placeholder=""
                        value={snowflakeDetails.query_warehouse}

                        onChange={(e) => onChange('query_warehouse', e.target.value)}
                    />
                    <Form.Text>
                        Enter the name of the warehouse Pliable should use for querying your models. It's best practice to keep these separate so you don't compete for resources with the scheduled build processes.
                    </Form.Text>
                </Form.Group>
                
                <Form.Group className="mb-3">
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                        name="role"
                        type="text"
                        placeholder=""
                        value={snowflakeDetails.role}

                        onChange={(e) => onChange('role', e.target.value)}
                    />
                    <Form.Text>
                        Enter the name of the role Pliable should use when interacting with Snowflake. 
                    </Form.Text>
                </Form.Group>
                <div className="shadow-box p-3 mb-3">
                    <h5>Data Ingestion (Optional)</h5>
                    <Form.Group className="mb-3">
                        <Form.Label>User</Form.Label>
                        <Form.Control
                            name="loader_user"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_user}

                            onChange={(e) => onChange('loader_user', e.target.value)}
                        />
                        <Form.Text>
                            Enter a username to use for loading data into your Snowflake
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="loader_password"
                            type="password"
                            placeholder=""
                            value={snowflakeDetails.loader_password}

                            onChange={(e) => onChange('loader_password', e.target.value)}
                        />
                        <Form.Text>Leave blank and we will automatically generate one for you.</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Database</Form.Label>
                        <Form.Control
                            name="loader_database"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_database}

                            onChange={(e) => onChange('loader_database', e.target.value)}
                        />
                        <Form.Text>
                            Enter the name of the database for housing raw data ingested from your data sources.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            name="loader_role"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_role}

                            onChange={(e) => onChange('loader_role', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Warehouse (Loading)</Form.Label>
                        <Form.Control
                            name="loading_warehouse"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loading_warehouse}

                            onChange={(e) => onChange('loading_warehouse', e.target.value)}
                        />
                        <Form.Text>
                            Enter the name of the warehouse Pliable should use for loading in data.
                        </Form.Text>
                    </Form.Group>
                </div>
                </div>
            </Collapse>
            <p>Once you're ready, generate and run the setup script to connect Pliable to your Snowflake.</p>     
            <AsyncButton
                variant="pliable"
                icon='mdi mdi-database-edit-outline'
                disabled={!formIsValid}
                loading={savingConfig}
                onClick={() => saveSnowflakeDetails(true)}
                text="Generate Setup Script"
            />
            <hr />
            <p>Once you've run the script, we just need to validate the connection.</p>                       
            
            <AsyncButton
                variant="primary"
                icon='mdi mdi-database-edit-outline'
                disabled={!formIsValid}
                loading={testingCredentials}
                onClick={() => testCredentials()}
                text="Validate Connection"
            />
            {testingCredentials && (
                <div>
                    <Form.Text>
                        We're running a few different checks, which may take up to 30 seconds.
                    </Form.Text>
                </div>
                
            )}
            {credentialsError && (
                <>
                    <p>
                        <strong className="text-danger">Oops!</strong> We were unable to connect to your Snowflake. Please check your credentials and make sure the setup script ran successfully.
                    </p>
                    <Danger>
                        {credentialsError}
                    </Danger>
                </>
            )}
            {!testingCredentials && credentialsAreValid && <div className="mt-2">
                <SuccessAlert>Connection successful!</SuccessAlert>

            </div>
            }
        </>}
        
                    
    </div>
}




const INDUSTRY_OPTIONS = [
    'Construction',
    'Consulting',
    'E-Commerce',
    'Finance',
    'Government',
    'Healthcare',
    'Hospitality',
    'iGaming',
    'Insurance',
    'Lending',
    'Manufacturing',
    'Real Estate',
    'Software',
    'Other',
];

const USE_CASE_OPTIONS = [
    'Affiliate Marketing',
    'Asset Performance',
    'Customer Satisfaction',
    'Financial Health',
    'Employee Performance',
    'Marketing Funnel',
    'Product Usage',
    'Team Productivity',
    'Sales Funnel',
    'SEO',
    'Supply Chain',
    'Other',
];

interface ToolGroup {
    name: string;
    prompt: string;
    otherValue: string;
    otherKey: keyof SurveyDataSources;
    toolOptions: string[];
}

const TOOL_GROUPS: {
    [key: string]: ToolGroup;
} = {
    'AD_PLATFORMS': {
        name: 'Ad Platforms',
        prompt: 'Connect to your advertising platform sso Pliable can pull in information about your ad campaigns and their performance.',
        toolOptions: [
            'Meta Ads',
            'Google Ads',
            'LinkedIn Ads',
            'Bing Ads',
            'Other Ad Platform',
        ],
        otherKey: 'otherAdPlatform',
        otherValue: 'Other Ad Platform'
    },
    'AFFILIATE_MARKETING': {
        name: 'Affiliate Marketing Platforms',
        prompt: 'Connect to your affiliate marketing platform so Pliable can pull in information about your commissions.',
        otherValue: 'Other Affiliate Platform',
        otherKey: 'otherAffiliatePlatform',
        toolOptions: [
            'Affiliate Window',
            'Commission Junction',
            'Impact Radius',
            'Link Connector',
            'Partnerize',
            'Pepperjam',
            'Rakuten',
            'Skimlinks',
            'Other Affiliate Platform',
        ]
    },
    'ERPS': {
        name: 'ERP',
        prompt: 'Connect to your ERP so Pliable can pull in information about projects, inventory, vendors, and accounting.',
        otherValue: 'Other ERP',
        otherKey: 'otherERP',
        toolOptions: [
            'Netsuite',
            'SAP',
            'Viewpoint Spectrum',
            'Other ERP',
        ]
    },
    'HR': {
        name: 'HR Platforms',
        prompt: 'Connect to your HR platform so Pliable can pull in information about your employees, salaries, and performance.',
        toolOptions: [
            'Rippling',
            'Gusto',
            'Other HR Platform',
        ],
        otherKey: 'otherHRPlatform',
        otherValue: 'Other HR Platform',
    },
    'SELLING_PLATFORMS': {
        name: 'Selling Platforms',
        prompt: 'Connect to your selling platforms so Pliable can pull in information about your sales, performance by keyword, etc.',
        toolOptions: [
            'Amazon',
            'Etsy',
            'Shopify',
            'Other Selling Platform',
        ],
        otherKey: 'otherSellingPlatform',
        otherValue: 'Other Selling Platform',
    },
    'CRMS': {
        name: 'CRMs',
        prompt: 'Connect your CRM(s) to bring in information about your customers, sales & marketing activities, and conversions.',
        toolOptions: [
            'Hubspot',
            'Pipedrive',
            'Salesforce',
            'Other CRM',
        ],
        otherKey: 'otherCRM',
        otherValue: 'Other CRM',
    },
    'PRODUCT_DATABASES': {
        name: 'Application Databases',
        prompt: "Connect to your application database (preferably a read-replica) so Pliable can pull in information about your product usage.",
        toolOptions: [
            'DynamoDB',
            'MongoDB',
            'MySQL',
            'PostgreSQL',
            'Other Database',
        ],
        otherKey: 'otherProductDatabase',
        otherValue: 'Other Database',
    },
    'ACCOUNTING': {
        name: 'Accounting',
        prompt: 'Connect to your accounting system to Pliable can pull in information about your revenue and expenses.',
        toolOptions: [
            'Bill.com',
            'Quickbooks',
            'Stripe',
            'Xero',
            'Other Accounting System',
        ],
        otherKey: 'otherAccountingTool',
        otherValue: 'Other Accounting System',
    },
    'SPORTSBOOK': {
        name: 'Sports Book',
        prompt: 'Connect your sports book to pull in information about players, bets, and odds.',
        toolOptions: [
            'Kambi',
            'Other Sportsbook'
        ],
        otherKey: 'otherSportsbook',
        otherValue: 'Other Sportsbook',
    }, 
    'SEO': {
        name: 'SEO',
        prompt: 'Connect to your SEO tools to report on your website performance on search engines.',
        toolOptions: [
            'Google Search Console',
            'Google Analytics',
            'Segment',
            'Rudderstack',
            'A HREFS',
            'Other SEO Tool',
        ],
        otherKey: 'otherSEO',
        otherValue: 'Other SEO Tool',
    },
    'PAM': {
        name: 'iGaming PAM',
        prompt: 'Connect your PAM to pull in player and balance information.',
        toolOptions: [
            'White Hat Gaming',
            'Other PAM',
        ],
        otherKey: 'otherPAM',
        otherValue: 'Other PAM',
    },
    'LMS': {
        name: 'Loan Management System',
        prompt: 'Loans performance, payment history, default rate, etc.',
        toolOptions: [
            'Nortridge',
            'Totality',
            'Other LMS',
        ],
        otherKey: 'otherLMS',
        otherValue: 'Other LMS',
    },
    'OUTREACH': {
        name: 'Customer Outreach Tools',
        prompt: 'Opens, clicks, connections, etc for outbound (email, phone, direct mail, in-app).',
        toolOptions: [
            'Braze',
            'Iterable',
            'Klaviyo',
            'Mailchimp',
            'Sendgrid',
            'Twilio',
            'Other Outreach Tool',
        ],
        otherKey: 'otherOutreachTool',
        otherValue: 'Other Outreach Tool',

    },
    'ANALYTICS': {
        name: 'Analytics Tools',
        prompt: 'Product analytics, like logins, feature usage, etc.',
        toolOptions: [
            'AppsFlyer',
            'FullStory',
            'Google Analytics',
            'Pendo',
            'Rudderstack',
            'Segment',
            'Other Analytics Tool',
        ],
        otherKey: 'otherAnalyticsTool',
        otherValue: 'Other Analytics Tool'
    }, 
    'PRODUCTIVITY': {
        name: 'Productivity & Project Management Tools',
        prompt: 'Project efficiency, workload, resource-level performance',
        toolOptions: [
            'Airtable',
            'Asana',
            'ClickUp',
            'Gitlab',
            'GitHub',
            'JIRA',
            'Monday.com',
            'Other Productivity Tool',
        ],
        otherKey: 'otherProductivityTool',
        otherValue: 'Other Productivity Tool',
    }, 
    'SUPPORT': {
        name: 'Support Tools',
        prompt: 'Customer satisfaction, open tickets, call center volume, etc.',
        toolOptions: [
            'Aircall',
            'Open Phone',
            'Zendesk',
            'Zoom Contact Center',
            'Other Support Tool',
        ],
        otherKey: 'otherSupportTool',
        otherValue: 'Other Support Tool',
    }

}


const OnboardingStep = styled.div`
text-align: center;
width: 1000px;
margin: 100px auto;
opacity: 1.0;

h1, h1 {
    color: #0F0E31;
}
h1 {
    font-weight: 400;
    line-height: 30px;
}

h2 {
    font-weight: 200;
}

&.faded {
    opacity: 0.0;
}

input.custom-value {
    width: 200px;
    margin: 0px auto;
    border-radius: 10px;
}

textarea.custom-value {
    margin: 0px auto;
    width: 300px;
    border-radius: 10px;
}

transition: opacity 0.15s ease-in-out;
-webkit-transition: opacity 0.15s ease-in-out;
`

const AIOrbStyles = styled.div`
width: 100px;
height: 100px;
img {
    width: 100%;
    height: 100%;
}


margin: 0px auto 25px auto;
`

const AIOrb = () => {
    return <AIOrbStyles>
        <img src={BrandMark}/>
    </AIOrbStyles>
}


const NextPreviousButton = styled.button`
border: none;
background: none;
color: var(--ct-primary);
font-weight: bold;
font-family: "Poppins";
font-size: 26px;
&:hover {
    text-decoration: underline;
}

&:disabled {
    color: #ccc;
    &:hover {
        text-decoration: none;
    }
}
`

export const humanReadableList = function(l: string[]): string {
    const uniqueList = l.filter((a: string, idx: number, arr: string[]) => {
        return arr.indexOf(a) === idx;
    })
    if (uniqueList.length == 0) {
        return '_______';
    }
    if (uniqueList.length == 1) {
        return l[0];
    }

    if (uniqueList.length == 2) {
        return l[0] + ' and ' + l[1];
    }

    return uniqueList.slice(0, -1).join(', ') + ', and ' + uniqueList[uniqueList.length - 1];
}

const SummaryLink = styled.a`
color: var(--pliable-yellow);
font-weight: bold;
&:hover {
    text-decoration: underline;
}
`

const OnboardingWizardPage = () => {
    const navigate = useNavigate();
    const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    setDataLibraryEnabled(false);
    const [snowflakeDetails, setSnowflakeDetails] = useState<SnowflakeDetails>({
        host: '',
        username: 'PLIABLE_USER',
        password: '',
        database: 'PLIABLE_DATABASE',
        role: 'PLIABLE_ROLE',
        query_warehouse: 'PLIABLE_QUERY_WAREHOUSE',
        loading_warehouse: 'PLIABLE_LOADING_WAREHOUSE',
        build_warehouse: 'PLIABLE_BUILD_WAREHOUSE',
        loader_user: 'PLIABLE_LOADER_USER',
        loader_database: 'PLIABLE_LOADER_DATABASE',
        loader_role: 'PLIABLE_LOADER_ROLE',
    
    });

    const [answers, setAnswers] = useImmer<SurveyAnswers>({
        database: {
            hasEtl: false,
            warehouse: '',
            etl: '',
        },
        businessType: {
            products: false,
            referrals: false,
            services: false,
            assets: false,
        },
        industry: {
            selection: '',
            otherValue: '',
        },
        customers: {
            smbs: false,
            enterprises: false,
            consumers: false,
        },
        useCases: {
            selections: [],
            otherValue: '',
        },
        dataSources: {
            selections: [],
            otherAccountingTool: '',
            otherAdPlatform: '',
            otherAnalyticsTool: '',
            otherCRM: '',
            otherERP: '',
            otherLMS: '',
            otherOutreachTool: '',
            otherProductDatabase: '',
            otherProductivityTool: '',
            otherSellingPlatform: '',
            otherHRPlatform: '',
            otherAffiliatePlatform: '',
            otherSupportTool: '',
            otherPAM: '',
            otherSEO: '',
            otherSportsbook: '',
        },
        personal: {
            isTechnical: undefined
        }
    })

    const [hasSnowflake, setHasSnowflake] = useState<undefined|boolean>(undefined);
    const [snowflakeScriptPreference, setSnowflakeScriptPreference] = useState<undefined|'AUTO'|'MANUAL'>(undefined);
    const [tenantConfigId, setTenantConfigId] = useState('');

    const [tenantInfraIsReady, setTenantInfraIsReady] = useState(false);
    const [showPliableHostedSnowflakeCode, setShowPliableHostedSnowflakeCode] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const getLogo = useCallback((tool: string) => {
        switch (tool) {
            case 'Hubspot':
                return <img src={HubspotLogo}/>;
            case 'Pipedrive':
                return <img src={PipedriveLogo}/>;
            case 'Salesforce':
                return <img src={SalesforceLogo}/>;
            case 'Meta Ads':
                return <img src={MetaLogo}/>;
            case 'Google Ads':
                return <img src={GoogleAdsLogo}/>;
            case 'Bing Ads':
                return <img src={BingAdsLogo}/>;
            case 'LinkedIn Ads':
                return <img src={LinkedInLogo}/>;
            case 'Braze':
                return <img src={BrazeLogo}/>;
            case 'Iterable':
                return <img src={IterableLogo}/>;
            case 'Klaviyo':
                return <img src={KlaviyoLogo}/>;
            case 'Mailchimp':
                return <img src={MailchimpLogo}/>;
            case 'Sendgrid':
                return <img src={SendgridLogo}/>;
            case 'Twilio':
                return <img src={TwilioLogo}/>;
            case 'Shopify':
                return <img src={ShopifyLogo}/>;
            case 'Etsy':
                return <img src={EtsyLogo}/>;
            case 'Amazon':
                return <img src={AmazonLogo}/>;
            case 'Rippling':
                return <img src={RipplingLogo}/>;
            case 'Gusto':
                return <img src={GustoLogo}/>;
            case 'Netsuite':
                return <img src={NetsuiteLogo}/>;
            case 'SAP':
                return <img src={SapLogo}/>;
            case 'Google Analytics':
                return <img src={GoogleAnalyticsLogo}/>;
            case 'Pendo':
                return <img src={PendoLogo}/>;
            case 'Rudderstack':
                return <img src={RudderstackLogo}/>;
        }
        return <>{tool}</>
    }, [])

    useHotkeys('ctrl+h', () => {
        if(!tenantInfraIsReady){
            setShowPliableHostedSnowflakeCode(!showPliableHostedSnowflakeCode);
        }
    }, [tenantInfraIsReady, showPliableHostedSnowflakeCode]);

    const loadData = async () => {
        setLoading(true);
        try {
            const {
                tenantRegistration,
                tenant_config,
                connection_creds,
                loader_creds
            } : any = await TenantService.getInstance().getTenantRegistration();
    
            setTenantConfigId(tenant_config.id);
            if (tenant_config.onboarding_info) {
                setAnswers(tenant_config.onboarding_info);
                console.log('ANSWERS:', tenant_config.onboarding_info);
            }
            setTenantInfraIsReady(tenant_config.infra.is_ready);
            setChosenDatabase(tenant_config.database);
            setUseTemplate(tenant_config.last_used_template);
    
            setSnowflakeDetails(produce(snowflakeDetails, draft => {
                return {
                    host: connection_creds.host,
                    username: connection_creds.username,
                    password: connection_creds.password,
                    database: connection_creds.database,
                    role: connection_creds.role,
                    query_warehouse: connection_creds.query_warehouse,
                    loading_warehouse: loader_creds.loading_warehouse,
                    build_warehouse: connection_creds.build_warehouse,
                    loader_role: loader_creds.role,
                    loader_user: loader_creds.username,
                    loader_password: loader_creds.password,
                    loader_database: loader_creds.database,
                };
            }));
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, []);

    const [savingDatabaseChoice, setSavingDatabaseChoice] = useState(false);
    const chooseDatabase = useCallback(async (db: string) => {
        setSavingDatabaseChoice(true);
        
        try {
            const newConfig = await TenantConfigService.getInstance().saveDatabaseChoice(tenantConfigId, db);
            setChosenDatabase(db);

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSavingDatabaseChoice(false);
        }
    }, [tenantConfigId]);

    const [advanced, setAdvanced] = useState(false);

    const [onboardingMethod, setOnboardingMethod] = useState('');
    const [chosenDatabase, setChosenDatabase] = useState('');
    const [useTemplate, setUseTemplate] = useState('');
    const [savingConfig, setSavingConfig] = useState(false);
    const [configSaved, setConfigSaved] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [skipReveal, setSkipReveal] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);

    const { step } = useParams();

    useEffect(() => {
        if (step) {
            try {
                const i = parseInt(step, 10);
                setOnboardingStep(i);
            } catch (err) {
                navigate('/onboarding');
            }
        }
    }, [step]);

    const [saving, setSaving] = useState(false);

    const saveData = useCallback(async () => {
        setSaving(true);
        try {
            await TenantConfigService.getInstance().updateOnboardingInfo(tenantConfigId, answers);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [answers, tenantConfigId]);

    const debouncedSave = useDebouncedCallback(saveData, 1000);

    useEffect(() => {
        if(!loading) {
            debouncedSave();
        }
    }, [answers, loading])
    
    const moveToStep = useCallback((step: number) => {
        setOnboardingStep(step);
    }, [saveData]);
    const saveAndContinue = useCallback(async () => {
        navigate('/onboarding/database');
    }, []);
    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        console.log('is valid:', isValid);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const setSnowflakeValue = (key: keyof SnowflakeDetails, value: string) => {
        setSnowflakeDetails(produce(snowflakeDetails, draft => {
            draft[key] = value;
        }));
    }

    const saveSnowflakeDetails = useCallback(async () => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            setConfigSaved(true);
            setOnboardingStep(1);
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const [showScriptModal, setShowScriptModal] = useState(false);

    const generateScript = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().showSetupScript(tenantConfigId);
            setSetupSql(snowflake_setup_sql);
            setShowScriptModal(true);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const onSetupComplete = useCallback(() => {
        if(useTemplate) {
            navigate(`/template/${useTemplate}?autouse=true`);
        }

    }, [useTemplate]);

    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        setCredentialsAreValid(false);
        setCredentialsError('');
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            const {job_id} : any = await TenantConfigService.getInstance().initInfrustructure(tenantConfigId);
            const {infra} : any = await BackgroundService.getInstance().waitForJob(job_id);
            if (infra.is_ready) {
                setCredentialsAreValid(true);
                setOnboardingStep(2);
            } else {
                throw new Error('Error setting up infrastructure.');
            }
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            setCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);

    const [creatingPliableHostedGit, setCreatingPliableHostedGit] = useState(false);

    const savePliableGitSource = useCallback(async () => {
        setCreatingPliableHostedGit(true);

        try {
            const response = await TenantConfigService.getInstance().saveRepo(tenantConfigId, 'PLIABLE', '');
            await enterDraftMode('onboarding', false);

            // Reload the config so it doesn't redirect them to onboarding
            await ConfigService.getInstance().loadConfig();
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingPliableHostedGit(false);
        }
    }, [tenantConfigId]);

    const [stepStatuses, setStepStatuses] = useImmer<{[key: string]: string}>({
        'snowflake': '',
    });

    const [onboardingStep, setOnboardingStep] = useState(0);

    const [snowflakeAdminUsername, setSnowflakeAdminUsername] = useState('');
    const [snowflakeAdminPassword, setSnowflakeAdminPassword] = useState('');

    const [runningAutoSetup, setRunningAutoSetup] = useState(false);
    const autoSetup = useCallback(async () => {
        await saveSnowflakeDetails();
        console.log('Saved details');
        const res = await TenantConfigService.getInstance().autoSetupSnowflake(tenantConfigId, snowflakeAdminUsername, snowflakeAdminPassword);

        setOnboardingStep(1);
    }, [snowflakeDetails, tenantConfigId, snowflakeAdminUsername, snowflakeAdminPassword, saveSnowflakeDetails]);

    const summaryInfo = useMemo(() => {
        let industry: string = '';
        if (answers.industry.selection === 'Other') {
            if (answers.industry.otherValue) {
                industry = ' ' + answers.industry.otherValue;
            }
        } else if (answers.industry.selection) {
            industry = ' ' + answers.industry.selection;
        }

        let useCases = answers.useCases.selections.filter(s => s !== 'Other');
        if (answers.useCases.selections.includes('Other') && !!answers.useCases.otherValue) {
            useCases.push(answers.useCases.otherValue);
        }

        let makesMoneyComponents: string[] = [];
        if (answers.businessType.products) {
            makesMoneyComponents.push('selling products');
        }
        if (answers.businessType.services) {
            makesMoneyComponents.push('selling services');
        }
        if (answers.businessType.assets) {
            makesMoneyComponents.push('managing assets');
        }
        if (answers.businessType.referrals) {
            makesMoneyComponents.push('getting commission');
        }

        let customerComponents: string[] = [];
        if (answers.customers.smbs) {
            customerComponents.push('SMBs');
        }
        if (answers.customers.enterprises) {
            customerComponents.push('enterprises');
        }
        if (answers.customers.consumers) {
            customerComponents.push('consumers');
        }

        let tools: string[] = answers.dataSources.selections.filter(s => !s.startsWith('Other '));
        Object.keys(answers.dataSources).filter(k => k.startsWith('other')).forEach(k => {
            // @ts-ignore
            if (answers.dataSources[k]) {
                // @ts-ignore
                tools.push(answers.dataSources[k]);
            }
        })
        return <>
            <p>
                Your<SummaryLink role="button" onClick={() => setOnboardingStep(1)}>{industry}</SummaryLink> business makes money by <SummaryLink role="button" onClick={() => setOnboardingStep(2)}>{humanReadableList(makesMoneyComponents)}</SummaryLink>.
            </p>
            <p>
                Your customers are <SummaryLink role="button" onClick={() => setOnboardingStep(3)}>{humanReadableList(customerComponents)}</SummaryLink>.
            </p>
            <p>
                With your data warehouse, you'd like to measure your <SummaryLink role="button" onClick={() => setOnboardingStep(4)}>{humanReadableList(useCases)}</SummaryLink>.
            </p>
            <p>
                In order to set up your account, Pliable will need to connect to the following tools: <SummaryLink role="button" onClick={() => setOnboardingStep(5)}>{humanReadableList(tools)}</SummaryLink>
            </p>
        </>
    }, [answers]);

    const [showAllTools, setShowAllTools] = useState(false);
    
    const toolTypes = useMemo(() => {
        if (showAllTools) {
            return Object.keys(TOOL_GROUPS);
        }

        const toolTypes: string[] = [];

        if (answers.useCases.selections.includes('Customer Satisfaction')) {
            toolTypes.push('SUPPORT');
            toolTypes.push('PRODUCT_DATABASES');
        }

        if (answers.useCases.selections.includes('Affiliate Marketing')) {
            toolTypes.push('AFFILIATE_MARKETING');
        }

        if (answers.useCases.selections.includes('Finances')) {
            toolTypes.push('ACCOUNTING');
            toolTypes.push('ERPS');
        }
        if (answers.useCases.selections.includes('Employee Performance')) {
            toolTypes.push('ERPS');
            toolTypes.push('HR');
        }
        if (answers.useCases.selections.includes('Marketing Funnel')) {
            toolTypes.push('OUTREACH');
            toolTypes.push('AD_PLATFORMS');
            toolTypes.push('CRMS');

            if (answers.industry.selection == 'Software') {
                toolTypes.push('ANALYTICS');
            }
        } 
        if (answers.useCases.selections.includes('Sales Funnel')) {
            toolTypes.push('OUTREACH');
            toolTypes.push('CRMS');
            if (answers.industry.selection == 'E-Commerce') {
                toolTypes.push('SELLING_PLATFORMS');
            }
        }
        if (answers.useCases.selections.includes('Team Productivity')) {
            toolTypes.push('PRODUCTIVITY');
        }
        if (answers.useCases.selections.includes('Supply Chain')) {
            toolTypes.push('ERPS');
        }
        if (answers.useCases.selections.includes('Product Usage')) {
            toolTypes.push('PRODUCT_DATABASES');
            toolTypes.push('ANALYTICS');
            toolTypes.push('SUPPORT');

            if (answers.industry.selection == 'iGaming') {
                toolTypes.push('SPORTSBOOK');
                toolTypes.push('PAM');
            }
        }
        if (answers.useCases.selections.includes('SEO')) {
            toolTypes.push('SEO');
        }
        if (answers.useCases.selections.includes('Asset Performance')) {
            if (['Lending', 'Finance'].includes(answers.industry.selection)) {
                toolTypes.push('LMS');
            }
        }
        if (answers.useCases.selections.includes('Customer Satisfaction')) {
            toolTypes.push('SUPPORT');
        }

        return toolTypes;
    }, [answers, showAllTools]);
    const [faded, setFaded] = useState(false);

    const changeStep = useCallback((step: number) => {
        TrackingService.track_event('onboarding_step', {
            step: step,
            answers: answers
        });
        setFaded(true);
        setTimeout(() => {
            setOnboardingStep(step);
            setFaded(false);

            if (step == 10) {
                savePliableGitSource();
            }
        }, 500);
    }, [answers, saveData, savePliableGitSource]);

    const [snowflakeConnectionIsValid, setSnowflakeConnectionIsValid] = useState(false);

    const canGoNext = useMemo(() => {
        switch (onboardingStep) {
            case 9:
                return snowflakeConnectionIsValid;
            default:
                return true;
        }
    }, [snowflakeConnectionIsValid, onboardingStep, answers]);

    const onEventScheduled = useCallback((e: EventScheduledEvent) => {
        setAnswers(draft => {
            draft.database.onboardingEventLink = e.data.payload.event.uri;
            draft.database.onboardingInviteeLink = e.data.payload.invitee.uri;
            queryClient.invalidateQueries(['calendly_onboarding_event'])
        });



    }, []);
    useCalendlyEventListener({
       onEventScheduled: onEventScheduled,
    });

    const authState = useAuthState();
    const innerContent = useMemo(() => {
        if (answers.database.onboardingEventLink) {
            return <>
                <h1 className="mb-2">Your onboarding call is scheduled.</h1>
                <div className="shadow-box p-3">
                    <CalendlyEventInfo eventUuid={answers.database.onboardingEventLink}/>
                </div>
                
            </>;
        }
        return <>
            {onboardingStep == 0 && <>
                <h1>Welcome to Pliable.</h1>
                <h2>We need to ask you some questions to personalize your experience.</h2>
                
            </>}
            {onboardingStep == 1 && <>
            
                <h1>Which industry best describes your business?</h1>
                <h2>This doesn't need to be exact. Just choose whatever is closest!.</h2>
                <ButtonGrid>
                    {INDUSTRY_OPTIONS.map(i => {
                        return <button onClick={() => {
                            setAnswers(draft => {
                                draft.industry.selection = i;
                            });
                        }} className={answers.industry.selection == i ? 'active' : ''}>
                            {i}
                        </button>
                    })}
                </ButtonGrid>
                {answers.industry.selection == 'Other' && <div className="mt-2">
                    <Form.Control className="custom-value"  placeholder="Enter your industry here" type="text" value={answers.industry.otherValue} onChange={(e) => setAnswers(draft => {
                        draft.industry.otherValue = e.target.value;
                    })}/>
                </div>}
                
            </>}
            {onboardingStep == 2 && <>
            
                <h1>How do you make money?</h1>
                <h2>Check all that apply.</h2>
                <ButtonGrid>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.products = !draft.businessType.products;
                        })
                    }} className={answers.businessType.products ? 'active' : ''}>
                        We sell products
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.services = !draft.businessType.services;
                        })
                    }} className={answers.businessType.services ? 'active' : ''}>
                        We sell services
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.assets = !draft.businessType.assets;
                        })
                    }} className={answers.businessType.assets ? 'active' : ''}>
                        We manage assets
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.referrals = !draft.businessType.referrals;
                        })
                    }} className={answers.businessType.referrals ? 'active' : ''}>
                        We get commission
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 3 && <>
                
                <h1>Who are your customers?</h1>
                <h2>Check all that apply.</h2>
                <ButtonGrid>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.smbs = !draft.customers.smbs;
                        })
                    }} className={answers.customers.smbs ? 'active' : ''}>
                        SMBs
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.enterprises = !draft.customers.enterprises;
                        })
                    }} className={answers.customers.enterprises ? 'active' : ''}>
                        Enterprise
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.consumers = !draft.customers.consumers;
                        })
                    }} className={answers.customers.consumers ? 'active' : ''}>
                        Consumers
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 4 && <>
                <h1>What are you interested in measuring?</h1>
                <h2>Based on your answers, we've made a few guesses, but feel free to change it or add more.</h2>
                <ButtonGrid>
                    {USE_CASE_OPTIONS.map(i => {
                        return <button onClick={() => {
                            setAnswers(draft => {
                                const index = draft.useCases.selections.indexOf(i);
                                if (index >= 0) {
                                    draft.useCases.selections.splice(index, 1);
                                } else {
                                    draft.useCases.selections.push(i);
                                }
                                
                            });
                        }} className={answers.useCases.selections.includes(i) ? 'active' : ''}>
                            {i}
                        </button>
                    })}
                </ButtonGrid>
                {answers.useCases.selections.includes('Other') && <div className="mt-2">
                    <Form.Control className="custom-value"  as="textarea" placeholder="Enter your use case here" type="text" value={answers.useCases.otherValue} onChange={(e) => setAnswers(draft => {
                        draft.useCases.otherValue = e.target.value;
                    })}/>
                </div>}
            </>}
            {onboardingStep == 5 && <>
                <h1>Where's your data?</h1>
                <button onClick={() => {
                    setAnswers(draft => {
                        draft.database.hasWarehouse = true;
                    });
                    changeStep(8);
                }}>I already have data in my warehouse</button>
                <h2>Based on your previous answers, I've determined that I need data from the following types of tools.</h2>
                <div style={{width: '200px', margin: '0px auto'}}>
                    <Form.Check
                        type="switch"
                        checked={showAllTools}
                        onChange={(e) => setShowAllTools(!showAllTools)}
                        label="Show all tool categories"
                    />
                </div>
                
                
                {Object.keys(TOOL_GROUPS).filter(k => toolTypes.includes(k)).map(k => {
                    return <div className="shadow-box p-3 mt-3">
                        <h4>{TOOL_GROUPS[k].name}</h4>
                        <p>{TOOL_GROUPS[k].prompt}</p>
                        <ButtonGrid>
                        {TOOL_GROUPS[k].toolOptions.map(i => {
                            return <button onClick={() => {
                                setAnswers(draft => {
                                    const index = draft.dataSources.selections.indexOf(i);
                                    if (index >= 0) {
                                        draft.dataSources.selections.splice(index, 1);
                                    } else {
                                        draft.dataSources.selections.push(i);
                                    }
                                    
                                });
                            }} className={answers.dataSources.selections.includes(i) ? 'active' : ''}>
                                {getLogo(i)}
                            </button>
                        })}
                    </ButtonGrid>
                    {answers.dataSources.selections.includes(TOOL_GROUPS[k].otherValue) && <div className="mt-2">
                        <Form.Control className="custom-value" placeholder="Enter tool here" type="text" value={answers.dataSources[TOOL_GROUPS[k].otherKey]} onChange={(e) => setAnswers(draft => {
                            // @ts-ignore
                            draft.dataSources[TOOL_GROUPS[k].otherKey] = e.target.value;
                        })}/>
                    </div>}
                    </div>
                })}
                
                
            </>}
            {onboardingStep == 6 && <>
                <h1>Great! That's all we need to know about your use case.</h1>   
                <h2>Please review your answers below.</h2>
                <AnswerReview>
                    {summaryInfo}
                </AnswerReview>    
            </>}
            {onboardingStep == 7 && <>
                <h1>Let's move on to your data infrastructure.</h1>
                <h2>Do you already have a data warehouse? If you aren't sure, just pick "no".</h2>
                <ButtonGrid>
                    <button onClick={() => setAnswers(draft => {
                        draft.database.hasWarehouse = true;
                    })} className={answers.database.hasWarehouse === true ? 'active' : ''}>
                        <i className="mdi mdi-check-circle text-success"></i> Yes
                        
                    </button>
                    <button onClick={() => setAnswers(draft => {
                        draft.database.hasWarehouse = false;
                    })} className={answers.database.hasWarehouse === false ? 'active' : ''}>
                        <i className="mdi mdi-close-thick text-danger"></i> No
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 8 && <>
                {answers.database.hasWarehouse && <>
                    <h1>Great, you already have a warehouse.</h1>
                    <h2>What kind of warehouse do you have?</h2>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'snowflake';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'snowflake' ? 'active' : ''}>
                            Snowflake
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'databricks';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'databricks' ? 'active' : ''}>
                            Databricks
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'redshift';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'redshift' ? 'active' : ''}>
                            Redshift
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'bigquery';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'bigquery' ? 'active' : ''}>
                            Big Query
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'other';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'other' ? 'active' : ''}>
                            Other
                        </button>
                        {answers.database.warehouse == 'other' && <>
                            <Form.Control className="custom-value" placeholder="Enter warehouse name" type="text" value={answers.database.otherWarehouse} onChange={(e) => setAnswers(draft => {
                                // @ts-ignore
                                draft.database.otherWarehouse = e.target.value;
                            })}/>
                        </>}
                        
                    </ButtonGrid>
                </>}
                {!answers.database.hasWarehouse && <>
                    <h1>No warehouse? No problem.</h1>
                    <h2>If you're technical, we can give you instructions for signing up for Snowflake. Otherwise, our team can set up and manage one for you.</h2>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.selfServicePreference = 'self'
                            draft.database.warehouse = 'snowflake';
                        })} className={answers.database.selfServicePreference === 'self' ? 'active' : ''}>
                            I'll set it up myself
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.selfServicePreference = 'managed'
                            draft.database.warehouse = '';
                        })} className={answers.database.selfServicePreference === 'managed' ? 'active' : ''}>
                            Please set it up for me
                        </button>
                    </ButtonGrid>
                </>}
            </>} 
            {onboardingStep == 9 && <>
                {answers.database.selfServicePreference == 'managed' && <>
                    <h1>Let's book some time with one of our specialists.</h1>
                    <h2>We'll get your data warehouse set up, connect your data sources, and learn more about your use case so we can get you up and running quickly.</h2>
                        <InlineWidget
                            styles={{height: '800px', width: '100%'}}
                            url="https://calendly.com/d/ckc3-gd4-2wh/pliable-onboarding" 
                            prefill={{
                                email: authState?.user?.email,
                                name: authState?.user?.name,
                            }}
                            
                        />
                </>}
                {answers.database.selfServicePreference != 'managed' && answers.database.warehouse == 'snowflake' && <>
                    <h1>Let's connect to your Snowflake.</h1>
                    <h2>Need to sign up for Snowflake? <a href="https://signup.snowflake.com/?utm_source=pliable" target="_blank">Click here</a> and come back in a few minutes once your account is created.</h2>
                    <div className="shadow-box p-3">
                        <SnowflakeSetupForm
                            snowflakeDetails={snowflakeDetails}
                            onChange={setSnowflakeValue}
                            tenantConfigId={tenantConfigId}
                            showPliableHostedSnowflakeCode={showPliableHostedSnowflakeCode}
                            onCredentialsAreValid={() => {
                                setSnowflakeConnectionIsValid(true);
                            }}
                        />
                    </div>
                </>}
                {answers.database.selfServicePreference != 'managed' && answers.database.warehouse && answers.database.warehouse !== 'snowflake' && <>
                    <h1>Pliable currently only supports Snowflake</h1>
                    <h2>If you'd like to sign up for a Snowflake account, we can help you replicate data from your existing warehouse into Snowflake so you can use Pliable.</h2>
                    <p>Otherwise, we'll reach out once we support your data warehouse.</p>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'snowflake'
                        })}>
                            I'll try Snowflake
                        </button>
                        <button onClick={() => window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}>
                            Screw you guys, I'm going home
                        </button>
                    </ButtonGrid>
                </>}
            </>}
            {onboardingStep == 10 && <>
                <h1>That's it!</h1>
                {creatingPliableHostedGit && <>
                    <h2>
                        <i className="mdi mdi-loading mdi-spin"></i>
                        We're just running some final setup...
                    </h2>
                </>}
                {saveConfigError && <Danger>
                    {saveConfigError}    
                </Danger>}
                {!creatingPliableHostedGit && !saveConfigError && <>
                    <h2>You're all set!</h2>
                    <Link to="/node/new?sourceOnly=true">Add your first data source.</Link>
                </>}
                
            </>}
            {!creatingPliableHostedGit && <>
                <div className="d-flex center-vertically mt-3" >
                    {onboardingStep > 0 && <NextPreviousButton onClick={() => changeStep(onboardingStep - 1)}>&larr; Go Back</NextPreviousButton>}
                    <div className="flex-1"></div>
                    {onboardingStep < 10 &&  <NextPreviousButton disabled={!canGoNext} onClick={() => changeStep(onboardingStep + 1)}>Next &rarr;</NextPreviousButton>}
                    
                </div>
            </>}
            
            {onboardingStep < 7 && <div>
                <a role="button" href="#" onClick={(e) => {
                    e.preventDefault();
                    changeStep(7)
                }}>Skip this and go right to setup</a>    
            </div>}
            
        </>
    }, [onboardingStep, answers, setAnswers, snowflakeDetails, setSnowflakeValue, tenantConfigId, setSnowflakeConnectionIsValid, toolTypes, showAllTools, saveConfigError, creatingPliableHostedGit]);

    const logout = () => {
        window.location.href = '/logout';
    }

    if (loading) {
        return <>
            <Spinner/>
        </>
    }
    
    return <Main className="d-flex flex-nowrap">

        <div className="content-wrapper">
            <div className="page-content" style={{width: '100%'}}>
                <NavBar>
                    <div className="flex-1">
                        <img src={PliableLogo}/>
                    </div>
                    <div className="buttons">
                        <TenantPicker/>
                        <button onClick={toggleThenaWidget} className="start-thena-chat btn btn-primary me-1">
                            <i className="mdi mdi-chat-question"></i>Get Help
                        </button>
                            
                        <button onClick={logout} className="btn btn-dark">
                            <i className="mdi mdi-logout"></i>Logout
                        </button>
                    </div>
                </NavBar>
                <ProgressBarWrapper>
                    <ProgressBar variant="pliable" now={100 * (onboardingStep / 9)}/>
                </ProgressBarWrapper>
                <div className="page-content-inner" style={{height: 'calc(100vh - 60px)'}}>
                    
                <PageContent style={{height: 'calc(100vh - 60px)', width: '100%'}}>
            <Pane>
                <PaneContent>
                    <div className="p-onboarding">
                        <OnboardingStep className={faded ? 'faded' : ''}>
                            <AIOrb/>
                            {innerContent}

                            
                            
                        </OnboardingStep>
                        
                    
                        
                    </div>
                    
                </PaneContent>
            </Pane>
        </PageContent>
                </div>

            </div>
        </div>
    </Main>
        
        

       
}

export default OnboardingWizardPage;