import { PipelineNodeMeasureJoinTree } from "@models/pipelineNode";
import { ReactNode } from "react";
import PipelineNodeName, { PipelineNodeRelationshipName } from "../PipelineNodeName.component";


const SingleComponentLabel = (props: {includeArrow: boolean, joinTree: PipelineNodeMeasureJoinTree}) => {
    return <>
        {props.includeArrow && <>&nbsp;&rarr;&nbsp;</>}
        <PipelineNodeName pipelineNodeId={props.joinTree.node_id}/>
        {props.joinTree.relationship_id && <>
            &nbsp;(<PipelineNodeRelationshipName relationshipId={props.joinTree.relationship_id}/>)
        </>}
    </>;
}

interface Props {
    joinTree: PipelineNodeMeasureJoinTree;
    singlePath?: boolean;
}

const PipelineNodeJoinTreeLabel = (props: Props) => {
    if (props.singlePath) {
        const flattened = [];
        let current = props.joinTree;
        while (current) {
            flattened.push(current);
            current = current.downstream[0];
        }
        return <>
            {flattened.map((dt, idx) => {
            return <SingleComponentLabel includeArrow={idx > 0} key={idx} joinTree={dt}/>
            })}
        </>
    }
    return <ul>
        <li>
            <SingleComponentLabel includeArrow={false} joinTree={props.joinTree}/>

            {props.joinTree.downstream.map((dt, idx) => {
                return <PipelineNodeJoinTreeLabel key={idx} joinTree={dt}/>
            })}
        </li>
    </ul>
}

export default PipelineNodeJoinTreeLabel;